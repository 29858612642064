import * as React from "react"
import { IconType } from "./types"

const ReportsIcon = (props: IconType) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={props.size} 
    height={props.size}  
    viewBox="0 0 16 16" 
    fill="none"
  >
    <g clip-path="url(#clip0_546_686)">
      <path d="M2.27793 7.19453C2.6405 7.19453 2.96867 7.04906 3.21117 6.81546L4.5415 7.48056C4.53483 7.53475 4.525 7.588 4.525 7.64395C4.525 8.38735 5.12984 8.99219 5.87324 8.99219C6.61665 8.99219 7.22148 8.38735 7.22148 7.64395C7.22148 7.43644 7.17046 7.24216 7.08631 7.06638L8.89099 5.2617C9.06677 5.34585 9.26105 5.39687 9.46855 5.39687C10.212 5.39687 10.8168 4.79204 10.8168 4.04863C10.8168 3.90866 10.7893 3.77629 10.7495 3.64931L12.3175 2.47358C12.5315 2.61648 12.7879 2.70039 13.0639 2.70039C13.8073 2.70039 14.4121 2.09555 14.4121 1.35215C14.4121 0.608743 13.8073 0.00390625 13.0639 0.00390625C12.3205 0.00390625 11.7156 0.608743 11.7156 1.35215C11.7156 1.49212 11.7431 1.62449 11.7829 1.75147L10.2149 2.9272C10.0009 2.7843 9.74452 2.70039 9.46855 2.70039C8.72515 2.70039 8.12031 3.30523 8.12031 4.04863C8.12031 4.25614 8.17134 4.45041 8.25549 4.6262L6.45081 6.43088C6.27502 6.34673 6.08075 6.2957 5.87324 6.2957C5.51067 6.2957 5.1825 6.44118 4.94001 6.67478L3.60967 6.00967C3.61634 5.95548 3.62617 5.90223 3.62617 5.84629C3.62617 5.10288 3.02134 4.49805 2.27793 4.49805C1.53452 4.49805 0.929688 5.10288 0.929688 5.84629C0.929688 6.58969 1.53452 7.19453 2.27793 7.19453Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
      <path d="M14.8916 14.447H14.4122V4.94941C14.4122 4.70107 14.2111 4.5 13.9628 4.5H12.1651C11.9168 4.5 11.7157 4.70107 11.7157 4.94941V14.447H10.8169V7.6459C10.8169 7.39755 10.6158 7.19648 10.3675 7.19648H8.56981C8.32146 7.19648 8.12039 7.39755 8.12039 7.6459V14.447H7.22156V11.2412C7.22156 10.9929 7.0205 10.7918 6.77215 10.7918H4.97449C4.72614 10.7918 4.52508 10.9929 4.52508 11.2412V14.447H3.62625V9.44355C3.62625 9.19521 3.42518 8.99414 3.17684 8.99414H1.37918C1.13083 8.99414 0.929766 9.19521 0.929766 9.44355V14.447H0.450391C0.202043 14.447 0.000976562 14.6481 0.000976562 14.8964C0.000976562 15.1448 0.202043 15.3459 0.450391 15.3459H14.8916C15.1399 15.3459 15.341 15.1448 15.341 14.8964C15.341 14.6481 15.1399 14.447 14.8916 14.447Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
    </g>
    <defs>
      <clipPath id="clip0_546_686">
        <rect width="15.34" height="15.34" fill="white" transform="translate(0.000976562 0.00390625)"/>
      </clipPath>
    </defs>
</svg>
)

export default ReportsIcon
