import * as React from "react"
import { IconType } from "./types"

const NotificationIcon = (props: IconType) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={props.size}
    height={props.size}
    viewBox="0 0 20 20" 
    fill="none"
  >
    <g clip-path="url(#clip0_546_737)">
      <path d="M17.8157 14.0981C16.5557 13.0331 15.8332 11.4764 15.8332 9.82724V7.50391C15.8332 4.57141 13.6548 2.14391 10.8332 1.73724V0.83724C10.8332 0.376406 10.4598 0.00390625 9.99984 0.00390625C9.53984 0.00390625 9.1665 0.376406 9.1665 0.83724V1.73724C6.344 2.14391 4.1665 4.57141 4.1665 7.50391V9.82724C4.1665 11.4764 3.444 13.0331 2.1765 14.1047C1.85234 14.3822 1.6665 14.7856 1.6665 15.2122C1.6665 16.0164 2.32067 16.6706 3.12484 16.6706H16.8748C17.679 16.6706 18.3332 16.0164 18.3332 15.2122C18.3332 14.7856 18.1473 14.3822 17.8157 14.0981Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
      <path d="M9.99965 20.0039C11.5088 20.0039 12.7713 18.9281 13.0613 17.5039H6.93799C7.22799 18.9281 8.49049 20.0039 9.99965 20.0039Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
    </g>
    <defs>
      <clipPath id="clip0_546_737">
        <rect width="20" height="20" fill="white" transform="translate(0 0.00390625)"/>
      </clipPath>
    </defs>
  </svg>
)

export default NotificationIcon
