import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import Modal from 'react-modal';
import { X } from "phosphor-react";
import OtpInput from 'react-otp-input';

import './PinModal.css'

import getErrorMessage from "../../utils/getErrorMessage";
import PINImage from '../../assets/svg/pin.svg'
import { DefaultStateType } from '../../redux/types';
import { initiate_otp_challenge, set_pin } from '../../api/auth';
import { storeUserData } from '../../redux/userData';
import { removeData, saveSecureData } from '../../utils/handleStorage';

type DisplayType = 'verify-pin' | 'set-pin' | 'verify-otp' | 'set-pin-success'

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};

const PinModal =  forwardRef((props: any, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [isOtpResendable, setIsOtpResendable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tmpID, setTempID] = useState("");
  const [displayType, setDisplayType] = useState<DisplayType>("set-pin")
  const [onClose, setOnClose] = useState<((pin?:string, status?: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);
  const [inputValues, setInputValues] = useState({
    setPin: '',
    setPinAgain: '',
    pin: '',
    otp: '',
  })
  const [errMsg, setErrMsg] = useState({
    setPin: '',
    setPinAgain: '',
    pin: '',
    otp: '',
  })

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const dispatch = useDispatch()

  const show = useCallback(( cb?: (pin?:string, status?: 'success' | 'failed' | 'canceled') => void, displayTypeParams: DisplayType ='set-pin') => {    
    if (cb) {
      setOnClose(() => cb);
    }

    setInputValues((prevState: any) => ({
      ...prevState,
      setPin: '',
      setPinAgain: '',
      pin: '',
      otp: '',
    }))
    
    if (userData.user_details?.has_pin) {
      setDisplayType("verify-pin")
    } else {
      setDisplayType(displayTypeParams)
    }
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      setPin: '',
      setPinAgain: '',
      pin: '',
      otp: '',
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const handleVerifyPin = async () => {
    try {
      if (inputValues.pin.length < 6) {
        setErrMsg((prevState: any) => ({
          ...prevState,
          pin: "PIN Incomplete"
        }))
        return
      }
      setIsModalOpen(false)
      if (Boolean(onClose)) {
        // @ts-ignore
        onClose(inputValues.pin, 'success')
      } 
      
    } catch (error) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        pin: getErrorMessage(error)
      }))
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
    
    // setErrMsg({
    //   ...errMsg,
    //   [name]: ""
    // });
    setErrMsg((prevState: any) => ({
      ...prevState,
      [name]: ""
    }))

  }

  const validatePins = async () => {
    try {
      if (inputValues.setPin.length < 6) {
        setErrMsg((prevState: any) => ({
          ...prevState,
          setPin: "PIN Incomplete"
        }))
        return
      }
      else if (inputValues.setPinAgain.length < 6) {
        setErrMsg((prevState: any) => ({
          ...prevState,
          setPinAgain: "PIN Incomplete"
        }))
        return
      }
      else if (inputValues.setPinAgain != inputValues.setPin) {
        setErrMsg((prevState: any) => ({
          ...prevState,
          setPinAgain: "PIN Mismatch"
        }))
        return
      }

      setIsSubmitting(true)

      const res = await initiate_otp_challenge(1)
      const response = await res.json()
      if (res.ok) {
        setDisplayType("verify-otp")
        setTempID(response.data.temp_id)
      } else {
        setErrMsg((prevState: any) => ({
          ...prevState,
          setPinAgain: response.message
        }))
      }

      setIsSubmitting(false)
    } catch (error) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        setPinAgain: getErrorMessage(error)
      }))
      setIsSubmitting(false)
    }
  }

  const handleSetPin = async () => {
    try {
      if (inputValues.otp.length < 6) {
        setErrMsg((prevState: any) => ({
          ...prevState,
          setPin: "OTP Incomplete"
        }))
        return
      }

      setIsSubmitting(true)

      var params = {
        pin: inputValues.setPin,
        pin_2: inputValues.setPinAgain,
        otp: inputValues.otp,
        temp_id: tmpID,
      }

      const res = await set_pin(params)
      const response = await res.json()
      if (res.ok) {
        removeData("token")
        saveSecureData("token", response.data.token || "")       
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))  
        setDisplayType("set-pin-success")
      } else {
        setErrMsg((prevState: any) => ({
          ...prevState,
          setPinAgain: response.message
        }))
      }

      setIsSubmitting(false)
    } catch (error) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        setPinAgain: getErrorMessage(error)
      }))
      setIsSubmitting(false)
    }
  }


  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Transaction PIN"
      contentElement={() => {
        return (
          <div className='anyjob-pin-modal'>
            <div className="anyjob-pin-modal-container">
              <div className="anyjob-pin-modal-close-btn" onClick={hide}>
                <X 
                  className="anyjob-pin-modal-close-icon"
                />
              </div>

              {
                displayType === 'verify-pin' && (
                  <div className='anyjob-pin-modal-contents'>
                    <img 
                      src={PINImage}
                      className='anyjob-pin-modal-img-0'
                      alt='PIN illustration'
                    />
                    <p className='anyjob-pin-modal-title'>
                      Transaction Pin
                    </p>
                    <p className='anyjob-pin-modal-sub-title'>
                      Input your transaction PIN to securely authorize your transaction.
                    </p>

                    <div className='anyjob-pin-modal-input-container'>
                      <p className='anyjob-pin-modal-input-label'>
                        Pin
                      </p>
                      <OtpInput
                        value={inputValues.pin}
                        // value="****"
                        onChange={(value: string) => {
                          setInputValues((prevState: any) => ({
                            ...prevState,
                            pin: value
                          }))
                          setErrMsg((prevState: any) => ({
                            ...prevState,
                            pin: ""
                          }))
                        }}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="anyjob-pin-modal-otp-input"
                        containerStyle="anyjob-pin-modal-otp-input-container"
                        shouldAutoFocus
                        skipDefaultStyles
                        placeholder="000000"
                      />
                      <p className='anyjob-pin-modal-err'>{errMsg.pin}</p>
                    </div>
                    <button className='anyjob-pin-modal-btn' onClick={handleVerifyPin}>
                      Confirm Transaction
                    </button>
                  </div>
                )
              }

              {
                displayType === 'set-pin' && (
                  <div className='anyjob-pin-modal-contents'>
                    <img 
                      src={PINImage}
                      className='anyjob-pin-modal-img-0'
                      alt='PIN illustration'
                    />
                    <p className='anyjob-pin-modal-title'>
                      Create New Transaction Pin
                    </p>
                    <p className='anyjob-pin-modal-sub-title'>
                      Create a transaction PIN to securely authorize your transactions.
                    </p>

                    <div className='anyjob-pin-modal-input-container'>
                      <p className='anyjob-pin-modal-input-label'>
                        New Pin
                      </p>
                      <OtpInput
                        value={inputValues.setPin}
                        // value="****"
                        onChange={(value: string) => {
                          setInputValues((prevState: any) => ({
                            ...prevState,
                            setPin: value
                          }))
                          setErrMsg((prevState: any) => ({
                            ...prevState,
                            setPin: ""
                          }))
                        }}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="anyjob-pin-modal-otp-input"
                        containerStyle="anyjob-pin-modal-otp-input-container"
                        shouldAutoFocus
                        skipDefaultStyles
                        placeholder="000000"
                      />
                      <p className='anyjob-pin-modal-err'>{errMsg.setPin}</p>
                    </div>

                    <div className='anyjob-pin-modal-input-container'>
                      <p className='anyjob-pin-modal-input-label'>
                        Confirm Pin
                      </p>
                      <OtpInput
                        value={inputValues.setPinAgain}
                        // value="****"
                        onChange={(value: string) => {
                          setInputValues((prevState: any) => ({
                            ...prevState,
                            setPinAgain: value
                          }))
                          setErrMsg((prevState: any) => ({
                            ...prevState,
                            setPinAgain: ""
                          }))
                        }}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="anyjob-pin-modal-otp-input"
                        containerStyle="anyjob-pin-modal-otp-input-container"
                        shouldAutoFocus
                        skipDefaultStyles
                        placeholder="000000"
                      />
                      <p className='anyjob-pin-modal-err'>{errMsg.setPinAgain}</p>
                    </div>
                    <button className='anyjob-pin-modal-btn' onClick={validatePins} disabled={isSubmitting}>
                      { isSubmitting ? "Validating..." : "Next"}
                    </button>
                  </div>
                )
              }
              {
                displayType === "verify-otp" && (
                  <div className='anyjob-pin-modal-contents'>
                    <img 
                      src={PINImage}
                      className='anyjob-pin-modal-img-0'
                      alt='PIN illustration'
                    />
                    <p className='anyjob-pin-modal-title'>
                      Verify OTP Code
                    </p>
                    <p className='anyjob-pin-modal-sub-title'>
                      Please provide the OTP code sent to your registered email address to complete PIN set up process
                    </p>

                    <div className='anyjob-pin-modal-input-container'>
                      <p className='anyjob-pin-modal-input-label'>
                        OTP Code
                      </p>
                      <OtpInput
                        value={inputValues.otp}
                        // value="****"
                        onChange={(value: string) => {
                          setInputValues((prevState: any) => ({
                            ...prevState,
                            otp: value
                          }))
                        }}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="anyjob-pin-modal-otp-input"
                        containerStyle="anyjob-pin-modal-otp-input-container"
                        shouldAutoFocus
                        skipDefaultStyles
                        placeholder="000000"
                      />
                      <p className='anyjob-pin-modal-err'>{errMsg.otp}</p>
                    </div>
                    <button className='anyjob-pin-modal-btn' onClick={handleSetPin} disabled={isSubmitting}>
                    { isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                    <span className='anyjob-pin-modal-resend-otp-container'>
                      <button 
                        className={isOtpResendable ? 'anyjob-pin-modal-btn-1' : 'anyjob-pin-modal-btn-1-disabled'}
                        disabled={!isOtpResendable}
                      >
                        Resend OTP Code
                      </button>
                      <p>4:36</p>
                    </span>
                  </div>
                )
              }
              {
                displayType === "set-pin-success" && (
                  <div className='anyjob-pin-modal-contents'>
                    <img 
                      src={PINImage}
                      className='anyjob-pin-modal-img-1'
                      alt='PIN illustration'
                    />
                    <p className='anyjob-pin-modal-title'>
                      Transaction Pin Created
                    </p>
                    <p className='anyjob-pin-modal-sub-title'>
                      Your transaction PIN has been created successfully.
                    </p>
                    <button className='anyjob-pin-modal-btn' onClick={() => setDisplayType("verify-pin")}>
                      Continue
                    </button>
                  </div>
                )
              }
            </div>
          </div>
        )
      }}
    >
      <></>
    </Modal>
    </>
  )
})

export default PinModal