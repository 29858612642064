import React, { useRef } from 'react'

import './Header.css'
import NotificationIcon from '../Drawer/NotificationIcon'

import Logo from '../../assets/svg/Logo.svg'
import MenuIcon from '../../assets/svg/vuesax/linear/menu.svg'
import { toggleDrawer } from '../Drawer/Drawer'
import NotificationModal from '../NotificationModal/NotificationModal'

type HeaderProps = {
  title?: string
  // toggleDrawer: () => void;
}

export default function Header(props: HeaderProps) {
  const notificationModalRef = useRef<any>()
  return (
    <>
      <div className='anyjob-page-header-container'>
        <p
          className='anyjob-page-header-title'
        >{props.title}</p>

        <div 
          className='anyjob-page-header-notification-btn'
          onClick={() => notificationModalRef.current.show()}
        >
          <NotificationIcon 
            size={20}
            isActive={false}
          />
        </div>
      </div>
      <div className='anyjob-page-mobile-header-container'>
        {/* <p
          className='anyjob-page-header-title'
        >{props.title}</p> */}
        <div className='anyjob-mobile-header-menu-logo-section'>
          <div
            // onClick={() => props.toggleDrawer()}
            className='anyjob-mobile-nav-bar-menu-icon-container'
          >
            <img
              src={MenuIcon}
              alt='Menu'
              className='anyjob-mobile-nav-bar-menu-icon'
              onClick={toggleDrawer}
            />
          </div>
          <img 
            src={Logo}
            alt="Anyjob"
            className='anyjob-header-logo'
          />
        </div>
        

        <div 
          className='anyjob-page-header-notification-btn'
          onClick={() => notificationModalRef.current.show()}
        >
          <NotificationIcon 
            size={20}
            isActive={false}
          />
        </div>
      </div>
      <NotificationModal 
        ref={notificationModalRef}
      />
    </>
  )
}

Header.defaultProps = {
  title: ""
}