import React, { forwardRef, useImperativeHandle, useState } from 'react'
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';
import { useLocation, useNavigate } from 'react-router-dom';

import './SideBar.css'

import Logo from '../../assets/svg/Logo.svg'
import JobsIcon from './JobsIcon';
import ReportsIcon from './ReportsIcon';
import ProposalIcon from './ProposalIcon';
import ContractIcon from './ContractIcon';
import NotificationIcon from './NotificationIcon';
import { DrawerProps, DrawerState } from './types';
import DrawerManager from './DrawerManager';
import { CaretRight, User, X } from 'phosphor-react';
import { DefaultStateType } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../env';
import WalletIcon from './WalletIcon';
import MessageIcon from './MessageIcon';
import { switchAppMode } from '../../redux/userData';
import ProfileIcon from './ProfileIcon';

function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

export function toggleDrawer() {  
  if (!!DrawerManager._enabled) {
    const ref = DrawerManager.getCurrent();
    // @ts-ignore
    if (!!ref) ref.toggleDrawer();
  }
}

export default class Drawer extends React.Component <DrawerProps, DrawerState> {
  constructor(props: any) {
    super(props);
    // @ts-ignore
    if (!this._id) this._id = srid();

    this.state = {
    }
  }

  drawerContent: any

  componentDidMount() {
    if (this.props.canRegisterAsDefault !== false) {
      DrawerManager.register(this);
    }
  }
  componentWillUnmount() {
    if (this.props.canRegisterAsDefault !== false) {
      DrawerManager.unregister(this);
    }
  }

  toggleDrawer = () => {
    this.drawerContent.toggleDrawer()
  }

  render() {
    return (
      <DrawerContent 
        ref={c=>this.drawerContent=c}
      />
    )
  }

}

const DrawerContent = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation()
  const userData = useSelector((state: DefaultStateType) => state.userData);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    console.log('`${BASE_URL}${userData.user_profile.profile_picture}`', `${BASE_URL}${userData.user_profile.profile_picture}`);
    
    
  };

  useImperativeHandle(ref, () => ({
    toggleDrawer,
  }))

  const closeMenu = () => {
    setIsOpen(false)
  }

  const switchToClient = () => {
    if (!userData.user_details.is_client) {
      const isConfirmed = window.confirm('You need to upgrade your account to a client user to use this mode, although you would still be able to view other jobs all you need to do is to switch back to freelancer. \n\nAre you ready to upgrade your account?');

      if (isConfirmed) {
        // Perform the logout logic here
        console.log('Run upgrade process');
        // Add your logout logic (e.g., clearing user session, redirecting, etc.)
      } else {
        // User clicked "Cancel" in the confirmation dialog
        console.log('Cancelled');
        return 
      }
    } else {
      dispatch(switchAppMode({mode: 'client'}))
      navigate('/dashboard')
    }
    
  }

  const switchToFreelancer = () => {
    if (userData.user_details.is_freelancer) {
      dispatch(switchAppMode({mode: 'freelancer'}))
      navigate('/jobs')
    } else {
      const isConfirmed = window.confirm('You need to configure your account to a freelancer user to use this mode, although you would still be able to view operate as a client all you need to do is to switch back to client. \n\nAre you ready to upgrade your account?');

      if (isConfirmed) {
        // Perform the logout logic here
        console.log('Run upgrade process');
        // Add your logout logic (e.g., clearing user session, redirecting, etc.)
      } else {
        // User clicked "Cancel" in the confirmation dialog
        console.log('Cancelled');
        return 
      }
    }
    
  }

  return (
    <div className={`anyjob-sidebar-container ${isOpen ? 'anyjob-sidebar-open' : ''}`}>
      <div className='anyjob-sidebar-logo-close-section'>
        <img 
          src={Logo}
          alt="Anyjob"
          className='anyjob-sidebar-logo'
        />
        <X 
          className='anyjob-sidebar-close-btn'
          onClick={toggleDrawer}
        />
      </div>
      

      <div className='anyjob-sidebar-nav-section'>
        <p className='anyjob-sidebar-nav-section-title'>
          General
        </p>
        <NavHashLink 
          exact to={'/jobs'} 
          smooth
          className={`${location.pathname}${location.hash}` === '/jobs' ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <JobsIcon 
            isActive={`${location.pathname}${location.hash}` === '/jobs'}
            size={20}
            
          />
          <p>Jobs</p>
          
        </NavHashLink>
        <NavHashLink 
          exact to={'/dashboard'} 
          smooth
          className={`${location.pathname}${location.hash}` === '/dashboard' ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <ReportsIcon 
            isActive={`${location.pathname}${location.hash}` === '/dashboard'}
            size={20}
            
          />
          <p>Dashboard</p>
        </NavHashLink>
        <NavHashLink 
          exact to={'/tasks'} 
          smooth
          className={`${location.pathname}${location.hash}` === '/tasks' ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <ProposalIcon 
            isActive={`${location.pathname}${location.hash}` === '/tasks'}
            size={20}
            
          />
          <p>Tasks</p>
        </NavHashLink>
        {/* <NavHashLink 
          exact to={'/message'} 
          smooth
          className={`${location.pathname}${location.hash}` === '/message' ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <MessageIcon 
            isActive={`${location.pathname}${location.hash}` === '/message'}
            size={20}
            
          />
          <p>Messages</p>
        </NavHashLink> */}
        <NavHashLink 
          exact to={'/wallet'} 
          smooth
          className={`${location.pathname}${location.hash}` === '/wallet' ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <WalletIcon 
            isActive={`${location.pathname}${location.hash}` === '/wallet'}
            size={20}
            
          />
          <p>Wallet</p>
        </NavHashLink>
        <NavHashLink 
          to={`/profile/${userData?.user_details?.public_unique_id}`}
          smooth
          className={`${location.pathname}${location.hash}`.startsWith('/profile') ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <ProfileIcon 
            isActive={`${location.pathname}${location.hash}`.startsWith('/profile')}
            size={20}
            
          />
          <p>Profile</p>
        </NavHashLink>
        {/* <NavHashLink 
          exact to={'/contracts'} 
          smooth
          className={`${location.pathname}${location.hash}` === '/contracts' ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <ContractIcon 
            isActive={`${location.pathname}${location.hash}` === '/contracts'}
            size={20}
            
          />
          <p>Contracts</p>
        </NavHashLink> */}
        <NavHashLink 
          exact to={'/notifications'} 
          smooth
          className={`${location.pathname}${location.hash}` === '/notifications' ? "anyjob-sidebar-link-active" : "anyjob-sidebar-link"}
          onClick={closeMenu}            
        >
          <div />
          <NotificationIcon 
            isActive={`${location.pathname}${location.hash}` === '/notifications'}
            size={20}
            
          />
          <p>Notifications</p>
        </NavHashLink>

      </div>

      <div 
        className='anyjob-sidebar-bottom-contents'
      >

      
      <NavHashLink 
        className='anyjob-sidebar-profile-section' 
        to={`/profile/${userData?.user_details?.public_unique_id}`}
      >
        {
          Boolean(userData?.user_profile.profile_picture_url.length) ? (
            <img 
              src={userData.user_profile.profile_picture_url}
              // src={"https://anyjob-core.onrender.com/media/profile_pictures/1633594379497_rLlk5yS.jpeg"}
              alt='Profile Picture'
              className='anyjob-sidebar-profile-picture'
            />
          ): (
            <div 
              className='anyjob-sidebar-profile-default-icon-container'
              // onClick={handleButtonClick}
            >
              <User
                className="anyjob-sidebar-profile-default-icon"
              />
            </div>
          )
        }
       
        {/* <img src="https://anyjob-core.onrender.com/media/profile_pictures/1633594379497_rLlk5yS.jpeg" alt="Profile Picture"></img> */}
        <div  className='anyjob-sidebar-profile-user-data'>
          <p className='anyjob-sidebar-profile-full-name'>
            {userData.user_details?.first_name} {userData.user_details?.last_name}
          </p>
          <p className='anyjob-sidebar-profile-is-freelancer'>
            {userData.user_details?.is_freelancer ? "Freelancer" : "Client"}
          </p>
        </div>
        <CaretRight />
      </NavHashLink>
      {/* {
        userData.appMode === 'freelancer' ? (
          <p
            className='anyjob-switch-mode'
            onClick={switchToClient}
          >
            SWITCH TO CLIENT
          </p>
        ) : (
          <p
            className='anyjob-switch-mode'
            onClick={switchToFreelancer}
          >
            SWITCH TO FREELANCER
          </p>
        )
      } */}
      </div>
    </div>
  )
})

// @ts-ignore
Drawer.defaultProps = {
  canRegisterAsDefault: true,
}