import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';

import './NotificationModal.css'
import NotificationIcon from '../Drawer/NotificationIcon'
import { notifications_list } from "../../api/notifications";
import moment from "moment";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)',
    zIndex: 1,
  },
};

type NotificationModalProps = {

}

const NotificationModal = forwardRef((props: NotificationModalProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([])

  useEffect(() => {
    fetchNotifications()
  }, [])

  const fetchNotifications = async () => {
    try {
      var params = {
        page: 1,
        notification_type: 'GENERAL',
      }
      const res = await notifications_list(params)
      if(res.ok) {
        const response = await res.json()   
        setNotifications(response.results)
      }
    } catch (error) {
      
    }
    
  }

  const show = () => {
    setIsModalOpen(true)
  }

  const hide = () => {
    setIsModalOpen(false)    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))


  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={hide}
      style={customStyles}
      contentLabel="Notifications Modal"
      contentElement={() => {
        return (
          <div className="anyjob-notifications-modal">
            <div className="anyjob-notifications-modal-contents">
              <div className="anyjob-notifications-modal-header">
                <p className="anyjob-notifications-modal-header-title">
                  NOTIFICATIONS
                </p>
                <p className="anyjob-notifications-modal-header-cta">
                  Mark all as read
                </p>
              </div>
              <div className="anyjob-notifications-modal-content-container">
                {
                  Boolean(notifications?.length)? (
                    
                    notifications.map((item: any, index: number) => {
                      return (
                        <div className="anyjob-notifications-modal-notification-item">
                          <NotificationIcon 
                            size={24}
                            isActive={false}                            
                          />                          
                          <div className="anyjob-notifications-modal-notification-item-content">
                            <div className="anyjob-notifications-modal-notification-item-heading">
                              <p className="anyjob-notifications-modal-notification-item-title">
                                {item.title}
                              </p>
                              <p className="anyjob-notifications-modal-notification-item-message">
                                {moment(item.date_created).fromNow()}
                              </p>
                            </div>                           
                            <p className="anyjob-notifications-modal-notification-item-message">
                              {item.message}
                            </p>
                          </div>
                          
                        </div>
                      )
                    })
                  ) : (
                    <div className="anyjob-notifications-modal-content-empty-state">
                      <NotificationIcon 
                        size={60}
                        isActive={false}
                      />
                      <p>
                        Nothing to see here
                      </p>
                    </div>
                  )
                }
              </div>
            </div>            
          </div>
        )
      }}
    >
      <></>
    </Modal>
  )
})

export default NotificationModal