import * as React from "react"
import { IconType } from "./types"

const ProposalIcon = (props: IconType) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={props.size}
    height={props.size}
    viewBox="0 0 20 20" 
    fill="none"
  >
    <g clip-path="url(#clip0_546_696)">
      <path d="M1.99316 19.2505C1.9041 19.2505 1.83188 19.1782 1.83188 19.0892V2.20312H1.07715V20.0052H14.7671V19.2505H1.99316Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
      <path d="M15.8443 18.9271V18.129H5.07883C3.90633 18.129 2.95238 17.175 2.95238 16.0025V1.125H2.1543V18.927H15.8443V18.9271Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
      <path d="M3.2749 16.002C3.2749 16.9968 4.08412 17.806 5.07877 17.806C6.07342 17.806 6.88264 16.9968 6.88264 16.002C6.88264 15.913 6.95486 15.8407 7.04393 15.8407H16.9649V0.00390625H3.2749V16.002ZM13.266 3.14426C13.266 3.23332 13.1938 3.30555 13.1047 3.30555H8.8283C8.73924 3.30555 8.66701 3.23332 8.66701 3.14426C8.66701 3.05519 8.73924 2.98297 8.8283 2.98297H13.1047C13.1938 2.98297 13.266 3.05516 13.266 3.14426ZM8.8283 2.06504C8.73924 2.06504 8.66701 1.99281 8.66701 1.90375C8.66701 1.81469 8.73924 1.74246 8.8283 1.74246H15.1287C15.2178 1.74246 15.29 1.81469 15.29 1.90375C15.29 1.99281 15.2178 2.06504 15.1287 2.06504H8.8283ZM4.09678 1.34879C4.09678 1.25973 4.169 1.1875 4.25807 1.1875H6.70639C6.79545 1.1875 6.86768 1.25973 6.86768 1.34879V3.79715C6.86768 3.88621 6.79545 3.95844 6.70639 3.95844H4.25807C4.169 3.95844 4.09678 3.88621 4.09678 3.79715V1.34879ZM16.0055 4.92687H4.25807C4.169 4.92687 4.09678 4.85465 4.09678 4.76559C4.09678 4.67652 4.169 4.6043 4.25807 4.6043H16.0055C16.0946 4.6043 16.1668 4.67652 16.1668 4.76559C16.1668 4.85465 16.0946 4.92687 16.0055 4.92687Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
      <path d="M17.1263 16.1641H7.19922C7.14707 16.8566 6.76145 17.4575 6.20312 17.8067H17.1263C18.0666 17.8067 18.8412 17.0835 18.923 16.1641H17.1263Z" fill={props.isActive ? "#1A1A1A" : "#ACACAC"}/>
    </g>
    <defs>
      <clipPath id="clip0_546_696">
        <rect width="20" height="20" fill="white" transform="translate(0 0.00390625)"/>
      </clipPath>
    </defs>
  </svg>
)

export default ProposalIcon
