/**
 * Utility class to handle the "default" Drawer instance to global use
 */
 class DrawerManager {
  _preservedDrawers = [];
  _currentDrawer = null;
  _enabled = true;
  get isEnabled() {
    return !!this._enabled;
  }
  setDisabled(disable = true) {
    this._enabled = !disable;
  }
  hold(_tempInstance) {
    if ("_id" in _tempInstance) {
      let wasHeld = false;
      if (this._preservedDrawers.length > 0) {
        wasHeld = this._preservedDrawers[this._preservedDrawers.length - 1]._id === _tempInstance._id;
      }

      if (!wasHeld) {
        this._preservedDrawers.push(this._currentDrawer);
        this._currentDrawer = null;

        this.register(_tempInstance);
      }
    }
  }
  unhold() {
    if (this._preservedDrawers.length > 0) {
      // here the current instance is the one that's held momentarily...
      if (!!this._currentDrawer) {
        this._currentDrawer.hideMessage();
        this._currentDrawer = null;
      }

      this.register(this._preservedDrawers.pop());
    }
  }
  register(_instance) {
    if (!this._currentDrawer && "_id" in _instance) {
      this._currentDrawer = _instance;
    }
  }
  unregister(_instance) {
    if (!!this._currentDrawer && this._currentDrawer._id === _instance._id) {
      this._currentDrawer = null;
    }
  }
  getCurrent() {
    return this._currentDrawer;
  }
}

export default new DrawerManager();