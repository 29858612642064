import React, { useEffect, useRef, useState } from 'react'

import './JobsDetails.css'
import Header from '../../components/Header/Header'
import { fetch_jobs, get_job } from '../../api/jobs'
import LoadingModal from '../../components/LoadingModal'
import { Link, useParams } from 'react-router-dom'
import { formatCurrency } from '../../utils/strings'
import NotificationBanner from '../../components/NotificationBanner/NotificationBanner'

export default function JobsDetails(props: any) {
  const [data, setData] = useState<any>(null)

  const { job } = useParams();

  const loadingModalRef = useRef<any>()

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchJob()
    loadingModalRef.current?. hideLoadingModal()
  }

  const fetchJob = async () => {    
    try {
      console.log('job id', job );
      if (Boolean(job?.length)) {
        const res = await get_job(job || '')
        const response = await res.json()     
        console.log('response', response);
        if (res.ok) {
          setData(response.data.job)    
        } else {

        }
      }                   
    } catch (error) {
      
    }
  }



  return (
    <div className='anyjob-job-details-page-container'>
      <Header title={"Job Details"} />
      <div className='anyjob-job-details-page-content'>
      
        <div className='anyjob-job-details-page-content-details'>
          <div className='anyjob-job-details-page-content-hero'>
            <div className='anyjob-job-details-page-content-heading'>
              <p className='anyjob-job-details-page-content-title'>{data?.title}</p>
              <span className='anyjob-job-details-page-subheading'>
                <p>{data?.client.business_name}</p>
                <span></span>
                <p>{data?.location}</p>
              </span>
            </div>
            <div className='anyjob-job-details-page-location-type-n-pay'>
              <p className='anyjob-job-details-page-location-type'>
                {data?.location_type}
              </p>
              <span/>
              <p className='anyjob-job-details-page-compensation'>
                {data?.compensation_currency.symbol} {formatCurrency(data?.compensation_amount)}
              </p>
            </div>
          </div>
          <div 
            dangerouslySetInnerHTML={{ __html: data?.instructions }} 
            className="anyjob-jobs-details-page-job-description" 
          />
        </div>
        <div className='anyjob-job-details-page-content-meta'>
          {
            data?.requires_proposal_approval ? (
              <Link 
                className='anyjob-job-details-page-cta'
                to={`/submit-proposal/${data?.unique_id}`}
                
              >
                Apply Now
              </Link>
            ) : (
              <Link 
                className='anyjob-job-details-page-cta'
                to={`/submit-task/${data?.unique_id}`}
                
              >
                Accept & Work 
              </Link>
            )
          }
          {
            Boolean(data?.required_skills?.length) && (
              <div className='anyjob-job-details-page-meta-data-sub-2'>
                <p className='anyjob-job-details-page-meta-data-sub-title'>
                  Skill:
                </p>
                {
                  data?.required_skills.map((item: any, index: number) => {
                    return (
                      <div className='anyjob-job-details-page-meta-data-sub-skill'>
                        {item?.title}
                      </div>
                    )
                  })
                }
                
              </div>
            )
          }
         
        </div>
      </div>
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}