import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { toast } from "react-toastify";

import './SubmitTask.css'

import CameraIcon from '../../assets/svg/camera.svg'
import FileIcon from '../../assets/svg/File_dock_fill.svg'
import { cameraCallBackParamType } from '../../components/CameraComponent/CameraComponent';
import { X } from 'phosphor-react';
import moment from 'moment';

type AttachementButtonProps = {
  attachment_type: 'any' | 'audio-upload' | 'doc-upload' | 'image-upload' | 'video-upload',
  max_no_of_attachments: number,
  showCamera: (action: 'image-capture' | 'video-record', cb?: (params: cameraCallBackParamType) => void) => void,
  isEditing?: boolean,
  attachments: [],
}

const AttachementButton = forwardRef((props:AttachementButtonProps, ref) => {
  const [attachments, setAttachments] = useState<any[]>([])

  const fileInputRef = useRef<any>(null);

  useEffect(() => {
    setAttachments((prevState: any) => ([
      ...props.attachments,
    ]))
  }, [props.attachments,])

  useImperativeHandle(ref, () => ({
    getAttachments: getAttachments,
  }))

  const getAttachments = () => {
    return attachments
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    // setAttachmentFile(file)

    console.log('file', file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        // setAttachment(reader.result);
        var x = {
          base64: reader.result,
          fileName: file.name,
        }
        setAttachments((prevState: any) => ([
          ...attachments, x
        ]))
        // @ts-ignore
        const base64String = reader.result.split(',')[1];
        
        // setAttachmentBase64Str(base64String)
      };

      reader.readAsDataURL(file);

      
    }
  };

  const handleCameraCallBack = (params: cameraCallBackParamType) => {
    console.log('params', params);
    if (params.status === 'success') {
      var x = {
        base64: params?.capturedImage || params?.recordedVideo,
        fileName: moment().toString()
      }
      setAttachments((prevState: any) => ([
        ...attachments, x
      ]))
    }
    
  }

  const handleButtonClick = () => {
    if (props.attachment_type === 'any' || props.attachment_type === 'doc-upload' || props.attachment_type === 'audio-upload') {
      fileInputRef.current.click();
    } else if (props.attachment_type === 'image-upload'){
      props.showCamera('image-capture', handleCameraCallBack)
    } else if (props.attachment_type === 'video-upload'){
      props.showCamera('video-record', handleCameraCallBack)
    }
    
  };

  const getAllowedFileType = () => {
    switch (props.attachment_type) {
      case 'audio-upload':
        return ".mp3, .wav, .ogg, .aac, .m4a"
      case 'doc-upload':
        return ".pdf, .doc, .docx, .txt"
      case 'image-upload':
        return ".jpg, .jpeg, .png, .gif, .bmp"
      case 'video-upload':
        return ".mp4, .avi, .mkv, .mov, .wmv"
    
      default:
        return ''
    }
  }

  const removeAttachment = (i: number) => {
    const newAttachments = attachments.filter((item: any, index:number) =>(index !== i && item))
    setAttachments(newAttachments)
  }

  if (attachments.length === 0) {
    return (
      <div 
        onDrop={handleButtonClick}
        onDragOver={(e) => e.preventDefault()}
        onClick={handleButtonClick}
        className='anyjob-submit-task-file-upload'
      >
        {
          (props.attachment_type === 'any' || props.attachment_type === 'doc-upload' || props.attachment_type === 'audio-upload')  && (
            <>
              <p className='anyjob-submit-task-file-upload-label'>Drag or  <span className='anyjob-submit-task-file-upload-label-highlight'>upload</span> project files</p>
              <input
                type="file"
                accept={getAllowedFileType()}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple={false}
                required={false}
              />
            </>
          )
        }

        {
          (props.attachment_type === 'image-upload' || props.attachment_type === 'video-upload') && (
            <div className='anyjob-submit-task-file-upload-contents'>
              <img 
                alt={props.attachment_type === 'image-upload' ? "Capture Image" : "Record Video"}
                src={CameraIcon}
              />
              <p className='anyjob-submit-task-file-upload-label'>
                {props.attachment_type === 'image-upload' && "Capture Image"}
                {props.attachment_type === 'video-upload' && "Record Video"}
                
              </p>
            </div>
          )
        }
      </div>
    )
  } else {
    return (
      <div 
        className='anyjob-submit-task-upload-container'
      >
        {
          attachments.map((item: any, index: number) => {
            if (".jpg, .jpeg, .png, .gif, .bmp, .svg".includes(item?.fileName ? item?.fileName.slice(-4) : item?.url.slice(-4)) || props.attachment_type === 'image-upload') {
              return (
                <div 
                  className='anyjob-submit-task-attachment-img-container'
                  key={index}
                >
                  {
                    props.isEditing && (
                      <div className='anyjob-submit-task-remove-attachment-2' onClick={() => removeAttachment(index)}>
                        <X  
                          weight="fill" 
                          size={24} 
                          onClick={() => removeAttachment(index)} 
                          className='anyjob-submit-task-remove-attachment-icon'
                        />
                      </div>
                    )
                  }
                  
                  <img 
                    src={item.base64 || item.url}
                    alt={item.fileName || ''}
                  />
                 
                </div>
              )
            } else if (".mp4, .avi, .mkv, .mov, .wmv".includes(item?.fileName ? item?.fileName.slice(-4) : item?.url.slice(-4)) || props.attachment_type === 'video-upload') {
              return (
                <div 
                  className='anyjob-submit-task-attachment-video-container'
                  key={index}
                >
                  {
                    props.isEditing && (
                      <div className='anyjob-submit-task-remove-attachment-2' onClick={() => removeAttachment(index)}>
                        <X  
                          weight="fill" 
                          size={24} 
                          onClick={() => removeAttachment(index)} 
                          className='anyjob-submit-task-remove-attachment-icon'
                        />
                      </div>
                    )
                  }
                 
                  <video 
                    src={item.base64 || item.url}
                    controls
                  />
                 
                </div>
              )
            } else {
              return (
                <div 
                  className='anyjob-submit-task-attachment-container'
                  key={index}
                >
                  {
                    props.isEditing && (
                      <div className='anyjob-submit-task-remove-attachment' onClick={() => removeAttachment(index)}>
                        <X  weight="fill" size={24} onClick={() => removeAttachment(index)} />
                      </div>
                    )
                  }
                  
                  <img 
                    src={FileIcon}
                    alt={item?.fileName || item?.url}
                  />
                   <p>
                    {item.fileName}
                  </p>
                </div>
              )
            }
          })
        }

        {
          (attachments.length < props.max_no_of_attachments && props.isEditing)  && (
            <div 
              className='anyjob-submit-task-upload-btn'
              onDrop={handleButtonClick}
              onDragOver={(e) => e.preventDefault()}
              onClick={handleButtonClick}
            >
              {
                (props.attachment_type === 'image-upload' || props.attachment_type === 'video-upload') && (
                  <>
                    <img 
                      alt={props.attachment_type === 'image-upload' ? "Capture Image" : "Record Video"}
                      src={CameraIcon}
                    />
                    <p className='anyjob-submit-task-file-upload-label'>
                      {props.attachment_type === 'image-upload' && "Capture Image"}
                      {props.attachment_type === 'video-upload' && "Record Video"}
                      
                    </p>
                  </>
                )
              }
              {
                (props.attachment_type === 'any' || props.attachment_type === 'doc-upload' || props.attachment_type === 'audio-upload')  && (
                  <>
                    <p className='anyjob-submit-task-file-upload-label'>Drag or  <span className='anyjob-submit-task-file-upload-label-highlight'>upload</span> project files</p>
                    <input
                      type="file"
                      accept={getAllowedFileType()}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      multiple={false}
                      required={false}
                    />
                  </>
                )
              }
            </div>
          )
        }
        
      </div>
    )
  }


 

  
})

export default AttachementButton

AttachementButton.defaultProps = { 
  isEditing: false,
  attachments: [],
}