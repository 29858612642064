import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';
import { PhoneInput } from 'react-international-phone';
// @ts-ignore
import { PhoneNumberUtil } from 'google-libphonenumber';
import OtpInput from 'react-otp-input';

import './VerifyAccount.css'

import { User, X } from "phosphor-react";
import LoadingModal from "../LoadingModal";
import getErrorMessage from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DefaultStateType } from "../../redux/types";
import { edit_user_profile, get_supported_kyc_ids, process_kyc_v1 } from "../../api/profile";
import { get_user_details, initiate_phone_verification, verify_email_or_phone } from "../../api/auth";
import { storeUserData } from "../../redux/userData";
import { BASE_URL } from "../../env";
import { setActiveWallet } from "../../redux/wallet";
import PINImage from '../../assets/svg/pin.svg'

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};

type VerifyAccountProps = {

}
const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const VerifyAccount = forwardRef((props: VerifyAccountProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [errMsg, setErrMsg] = useState({
    idType: '',
    idNumber: '',
    phoneNumber: '',
    otp: '',
  })
  const [displayType, setDisplayType] = useState<'phone-input' | 'otp-input'>('phone-input');
  const [phone, setPhone] = useState('');
  const [tempID, setTempID] = useState('')
  const [otp, setOtp] = useState('')
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false)
  const [isOtpResendable, setIsOtpResendable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [onClose, setOnClose] = useState<((status: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);

  const loadingModalRef = useRef<any>()

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const dispatch = useDispatch()

  const isValid = isPhoneValid(phone);



  const show = useCallback(( cb?: (status: 'success' | 'failed' | 'canceled') => void, ) => {
    
    if (cb) {
      setOnClose(() => cb);
    }
    setDisplayType('phone-input')
    setPhone(userData.user_details.phone_number)
    
    setIsModalOpen(true)
    // fetchSupportedIDs() 
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setDisplayType('phone-input')
    setOtp('')
    setPhone("")
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))


  const updateUserData = async () => {
    try {
      const res = await get_user_details()
      if (res.ok) {
        const response = await res.json()
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))
        dispatch(setActiveWallet(response.data.user_data.wallet))
      } else {
        // const response = await res.json()
        // console.log('response', response);
        // dispatch(logOut({}))
        
      }
    } catch (error) {
      
    }
  }

  const verifyPhoneNumber = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!isValid) {
        
        setErrMsg((prevState: any) => ({
          ...prevState,
          phoneNumber: "Invalid phone number"
        }))
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        phone_number: phone
      }
      

      const res = await initiate_phone_verification(params)
      const response = await res.json()
      
      if (res.ok) {
        setTempID(response.data.temp_id)
        setIsPhoneNumberVerified(true)
        loadingModalRef.current?. hideLoadingModal()
        setDisplayType('otp-input')
      } else {
        setErrMsg((prevState:any) => ({
          ...prevState,
          phoneNumber: response.message
        }))
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const verifyOTP = async () => {
    try {
      if (otp.length < 6) {
        setErrMsg((prevState: any) => ({
          ...prevState,
          otp: "OTP Incomplete"
        }))
        return
      }

      setIsSubmitting(true)

      var params = {
        code: otp,
        temp_id: tempID,
        channel: 'phone-number'
      }
      // @ts-ignore
      const res = await verify_email_or_phone(params)
      const response = await res.json()
      
      if (res.ok) {
        await updateUserData()
        setIsSubmitting(false)
        hide()
        if (Boolean(onClose)) {
          // @ts-ignore
          onClose('success')
        } 

      } else {
        setErrMsg((prevState: any) => ({
          ...prevState,
          otp: response.message
        }))
      }

      setIsSubmitting(false)
    } catch (error) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        setPinAgain: getErrorMessage(error)
      }))
      setIsSubmitting(false)
    }
  }


  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Add Education"
      contentElement={() => {
        return (
          <div className="anyjob-verify-acct-modal">
            <div className="anyjob-verify-acct-modal-contents">
              <div className="anyjob-verify-acct-modal-contents-header">
                <p>Account Verification</p>
                <div className="anyjob-verify-acct-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-verify-acct-modal-close-icon"
                  />
                </div>
                
              </div>

              <div className="anyjob-verify-acct-modal-contents-sub-form-section">

                {
                  displayType === 'phone-input' && (
                    <form className="anyjob-verify-acct-modal-form" onSubmit={verifyPhoneNumber}>
                  
                      <PhoneInput
                        defaultCountry="ng"
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        className="anyjob-auth-page-phone-input"
                        required
                        // countrySelectorStyleProps={{
                        //   className: "anyjob-auth-page-input"
                          
                        // }}
                        inputStyle={{
                          height: 58,
                          width: '100%',
                          borderTopRightRadius: 30,
                          borderBottom: 30,
                          borderWidth: 0,
                        }}
                        style={{
                          height: 60,
                          background: 'transparent',
                          marginTop: 40,
                        }}
                        inputClassName="anyjob-auth-page-phone-inner-input"
                        countrySelectorStyleProps={{
                          buttonClassName: "anyjob-auth-page-phone-input-selector-btn",
                          buttonStyle: {
                            height: 58,
                            width: 100,
                            borderTopLeftRadius: 50,
                            borderBottomLeftRadius: 50,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRightWidth: 1,
                          },
                          flagStyle: {
                            flex: 1,
                          }
                        }}
                      />

                      <span className="anyjob-verify-acct-modal-form-actions">
                        <div className="anyjob-verify-acct-modal-form-actions-cancel" onClick={hide}>
                          Close
                        </div>
                        <input 
                          value="Next"
                          type="submit"
                          className="anyjob-verify-acct-modal-form-actions-save"
                        />
                      </span>
                    </form>
                  )
                }

                {
                  displayType === 'otp-input'&& (
                    <div className='anyjob-verify-acct-modal-otp-contents'>
                      <img 
                        src={PINImage}
                        className='anyjob-verify-acct-modal-img-0'
                        alt='PIN illustration'
                      />
                      <p className='anyjob-verify-acct-modal-title'>
                        Verify OTP Code
                      </p>
                      <p className='anyjob-verify-acct-modal-sub-title'>
                        Please provide the OTP code sent to your registered email address to complete PIN set up process
                      </p>

                      <div className='anyjob-verify-acct-modal-input-container'>
                        <p className='anyjob-verify-acct-modal-input-label'>
                          OTP Code
                        </p>
                        <OtpInput
                          value={otp}
                          // value="****"
                          onChange={(value: string) => {
                            setOtp(value)
                          }}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} />}
                          inputStyle="anyjob-verify-acct-modal-otp-input"
                          containerStyle="anyjob-verify-acct-modal-otp-input-container"
                          shouldAutoFocus
                          skipDefaultStyles
                          placeholder="000000"
                        />
                        <p className='anyjob-verify-acct-modal-err'>{errMsg.otp}</p>
                      </div>
                      <button className='anyjob-verify-acct-modal-btn' onClick={verifyOTP} disabled={isSubmitting}>
                        Submit
                      </button>
                      <span className='anyjob-verify-acct-modal-resend-otp-container'>
                        <button 
                          className={isOtpResendable ? 'anyjob-verify-acct-modal-btn-1' : 'anyjob-verify-acct-modal-btn-1-disabled'}
                          disabled={!isOtpResendable}
                        >
                          Resend OTP Code
                        </button>
                        <p>4:36</p>
                      </span>
                    </div>
                  )
                }

                
              </div>

            </div>
          </div>
        )
      }}
    >
      <></>
    </Modal>
    <LoadingModal ref={loadingModalRef} />
    </>
  )
})

export default VerifyAccount