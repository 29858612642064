import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";

import './Jobs.css'
import Header from '../../components/Header/Header'
import SearchIcon from '../../assets/svg/search-normal.svg'
import { FetchJobsActionType } from './types'
import { fetch_jobs } from '../../api/jobs'
import LoadingModal from '../../components/LoadingModal'
import { CaretLeft, CaretRight } from 'phosphor-react'
import { formatCurrency } from '../../utils/strings';
import { useSelector } from 'react-redux';
import { DefaultStateType } from '../../redux/types';
import NotificationBanner from '../../components/NotificationBanner/NotificationBanner';
import Briefcase from '../../assets/svg/briefcase.svg'

export default function Jobs(props: any) {
  const [jobs, setJobs] = useState<any>([])
  const [searchQuery, setSearchQuery] = useState("")
  const [action, setAction] = useState<FetchJobsActionType>("default")
  const [pageNo, setPageNo] = useState(1);
  const [maxPageNo, setMaxPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const userData = useSelector((state: DefaultStateType) => state.userData);

  const loadingModalRef = useRef<any>()
  const navigate = useNavigate()

  useEffect(() => {
    setUp()
  }, [])

  useEffect(() => {
    
    
    if (userData.isAuthenticated) {
      initiateUserOnboarding()
    } 
    
  }, [userData.isAuthenticated,])

  const setUp = async () => {
    if (userData.isAuthenticated) {
      initiateUserOnboarding()
    } 
    loadingModalRef.current.showLoadingModal()
    await fetchJobs()
    loadingModalRef.current?. hideLoadingModal()
  }

  const initiateUserOnboarding = () => {
    
    try {
      if (!userData.user_profile?.is_initial_user_onboarding_completed && !userData.user_profile?.skipped_initial_onboarding && userData.user_details?.is_freelancer) {                
        navigate('/freelancer-onboarding')
      }
    } catch (error) {
      
    }
  }

  const fetchJobs = async () => {    
    try {
      
      const params = {
        action,
        query: searchQuery,
        page: pageNo,
        mode: userData.appMode,
      }
      const res = await fetch_jobs(params)
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        
        
        if (pageNo === 1) {
          setJobs(response.results);   
        } else {
          setJobs((prevState: any) => [...prevState, ...response.results]);   
        }     
        setMaxPageNo(response.count/20)
        setTotalCount(response.count)
        setHasNextPage(response.count > pageNo)      
      } else {

      }
    } catch (error) {
      
    }
  }

  const goToPrev = async () => {
    if (pageNo <= 1) {
      
    } else {
      loadingModalRef.current.showLoadingModal()
      setPageNo(prevPage => prevPage - 1);
      await fetchJobs()
      loadingModalRef.current?. hideLoadingModal()
    }
  }

  const goToNext = async () => {
    if (maxPageNo > pageNo) {
      setPageNo(prevPage => prevPage + 1);
      loadingModalRef.current.showLoadingModal()
      await fetchJobs()
      loadingModalRef.current?. hideLoadingModal()
    }    
  };

  const handleInputChange = async(event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setSearchQuery(target.value);

    if (target.value.length > 3) {
      setAction('search-only')
    } else {
      setAction("default")
      await fetchJobs()
    }
  }

  const submitSearch = async() => {
    setPageNo(1)
    loadingModalRef.current.showLoadingModal()
    await fetchJobs()
    loadingModalRef.current?. hideLoadingModal()
  }

  const printPaginationPosition = (page: number) => {
    var lowerRange = ((page - 1) * 20)  + 1
    var upperRange =  totalCount < page * 20 ? totalCount : page * 20
    return `${lowerRange} - ${upperRange} of ${totalCount}`
  }

  return (
    <div className='anyjob-jobs-page-container'>
      <Header title={"Find Jobs"} />
      {
        userData.appMode === 'freelancer' && (
          <div className='anyjob-jobs-page-search-container'>
            <div
              className='anyjob-jobs-page-search-input-group'
            >            
            <img 
              alt='Search Jobs'
              src={SearchIcon}
              className='anyjob-jobs-page-search-icon'
            />
            <input 
              type='text'
              value={searchQuery}
              onChange={handleInputChange}
              className='anyjob-jobs-page-search-input'
              placeholder='Job Title, Location or Keyword'
            />
            </div>
            <button className='anyjob-jobs-page-search-btn' onClick={submitSearch}>
              Submit
            </button>
            
          </div>
        )
      }
      
      <NotificationBanner />
      {
        Boolean(jobs.length) ? (
          <>
            <div className='anyjob-jobs-page-job-list'>
              
              {
                jobs.map((item: any, index: number) => {
                  if (item.status === 'Open') {
                    return (
                      <Link
                        key={index}
                        className='anyjob-jobs-page-job-item-container'
                        to={`/job-details/${item.unique_id}`}
                      >
                        <div className='anyjob-jobs-page-job-item-title-n-amount'>
                          <p className='anyjob-jobs-page-job-item-title'>{item.title}</p>
                          <p className='anyjob-jobs-page-job-item-amt'>
                            {item.compensation_currency.symbol} {formatCurrency(item.compensation_amount)}
                          </p>
                        </div>
                        <div className='anyjob-jobs-page-job-item-details-n-cta'>
                          <span className='anyjob-jobs-page-job-item-client-location'>
                            <p className='anyjob-jobs-page-job-item-sub-title'>
                              {item.client.business_name }
                            </p>
                            <span />
                            <p className='anyjob-jobs-page-job-item-sub-title'>
                              {item.location}
                            </p>
                          </span>
                          {
                            item?.requires_proposal_approval ? (
                              <Link 
                                className='anyjob-jobs-page-job-item-apply-btn'
                                to={`/submit-proposal/${item?.unique_id}`}
                              >
                                Apply Now 
                              </Link>
                            ) : (
                              <Link 
                                className='anyjob-jobs-page-job-item-apply-btn'
                                to={`/submit-task/${item?.unique_id}`}
                              >
                                Accept & Work 
                              </Link>
                            )
                          }
                        </div>
                        {/* <div dangerouslySetInnerHTML={{ __html: item.instructions }} className="anyjob-jobs-page-job-item-job-description" /> */}
                        
                        {
                          item?.requires_proposal_approval ? (
                            <Link 
                              className='anyjob-jobs-page-job-item-apply-btn-2'
                              to={`/submit-proposal/${item?.unique_id}`}
                            >
                              Apply Now 
                            </Link>
                          ) : (
                            <Link 
                              className='anyjob-jobs-page-job-item-apply-btn-2'
                              to={`/submit-task/${item?.unique_id}`}
                            >
                              Accept & Work 
                            </Link>
                          )
                        }
                      </Link>
                    )
                  } else {
                    return (<></>)
                  }                 
                })
              }
            </div>
            <div className='anyjob-pagination-controls-container'>
              <p>
                {printPaginationPosition(pageNo)}
              </p>

              <CaretLeft 
                className='anyjob-pagination-controls-btn'
                onClick={goToPrev}
              />
              <CaretRight 
                className='anyjob-pagination-controls-btn'
                onClick={goToNext}
              />
            </div>
          </>
        ) : (
          <div className='anyjob-jobs-page-empty-list-container'>
            <img 
              src={Briefcase}
              alt="No Jobs"
            />
            <p>
              {
                userData.appMode === 'freelancer' ? 'No Jobs Available' : 'You have not posted a job yet'
              }
            </p>
            {
              userData.appMode === 'client' && (
                <div className='anyjob-jobs-page-post-job'>
                  Post a Job
                </div>
              )
            }
          </div>
        )
      }
      
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}