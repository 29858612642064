import React, { useEffect, useState, useRef } from 'react'

import './MessagePage.css'

import SearchIcon from '../../assets/svg/search-normal.svg'
import { get_all_ongoing_chats } from '../../api/chats'
import { User } from 'phosphor-react'
import moment from 'moment'

export default function MessagePageNavPanel(props: any) {
  const [ongoingChats, setOngoingChats] = useState<any[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [inputValues, setInputValues] = useState({
    query: "",
  })
  const [err, setErr] = useState({
    query: "",
  })

  useEffect(() => {
    getOngoingChats()
  }, [])

  const getOngoingChats = async () => {
    try {
      const res = await get_all_ongoing_chats()
      console.log('res', res.status);
      
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        setOngoingChats(response.data)
      } else {

      }
    } catch (error) {
      
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
    
  }

  const handleOnSelect = (item: any, index: number) => {
    console.log('item', item);
    
    setSelectedIndex(index)
    props.onChange(item)
  }

  function detectDateStatus(date: string) {
    const dateToCheck = moment(date); // This can be replaced with any other date

    // Current date
    const currentDate = moment();

    // Calculate the difference in days
    const diffInDays = currentDate.diff(dateToCheck, 'days');

    // Check if the date is today
    if (currentDate.isSame(dateToCheck, 'day')) {
      return dateToCheck.format("hh:mm")
    } else if (diffInDays === 1) { // Check if the date is yesterday
      return "Yesterday"
    } else if (diffInDays < 7 && diffInDays > 1) { // Check if the date is less than seven days ago
      return dateToCheck.format('dddd')
    } else { // Check if the date is more than seven days ago
      return dateToCheck.format("dd/mm/yy")
    }
  }

  return (
    <div className='anyjob-message-page-nav-panel-container'>
      <div className='anyjob-message-page-nav-panel-search-container'>
        <img 
          src={SearchIcon}
        />
        <input 
          type='search'
          placeholder='Search'
          value={inputValues.query}
          name='query'
          onChange={handleInputChange}
        />
      </div>
      {
        Boolean(!ongoingChats.length) ? (
          <div className='anyjob-message-page-nav-panel-blank-container'>
            <p>
              Nothing here yet!
            </p>
          </div>
        ) : (
          <div className='anyjob-message-page-nav-panel-list'> 
            {
              ongoingChats.map((item: any, index: number) => {
                return (
                  <div 
                    className='anyjob-message-page-nav-panel-item'
                    key={index}
                    onClick={() => handleOnSelect(item, index)}
                  >
                    {
                      Boolean(item?.profile_details?.profile_picture_url?.length) ? (
                        <img 
                          src={item?.profile_details?.profile_picture_url}
                          // src={"https://anyjob-core.onrender.com/media/profile_pictures/1633594379497_rLlk5yS.jpeg"}
                          alt='Profile Picture'
                          className='anyjob-message-panel-item-profile-picture'
                        />
                      ) : (
                        <div 
                          className='anyjob-message-panel-item-profile-picture-default-icon-container'
                          // onClick={handleButtonClick}
                        >
                          <User
                            className="anyjob-message-panel-item-profile-picture-default-icon"
                          />
                        </div>
                      )
                    }

                    <div className='anyjob-message-panel-item-details'>
                      <div className='anyjob-message-panel-item-details-row'>
                        <p className='anyjob-message-panel-item-name'>
                          {item?.user_details?.is_client ? item?.profile_details?.business_name : `${item?.user_details.last_name} ${item?.user_details.first_name}`}
                        </p>
                        <p className='anyjob-message-panel-item-sub-text'>
                          {
                            (Boolean(item?.latest_message?.message?.length) ) && (detectDateStatus(item?.latest_message?.message?.sent_time))
                          }
                        </p>
                      </div>
                      <div className='anyjob-message-panel-item-details-row'>
                        <p className='anyjob-message-panel-item-sub-text'>
                          {
                            Boolean(item?.latest_message?.message?.length) ? (
                              item?.latest_message?.message.length > 16 ? `${item?.latest_message?.message.substring(0, 15)}...`: item?.latest_message?.message
                            ) : ''
                          }
                        </p>
                        {
                          Boolean(item?.total_no_of_unread) && (
                            <div className='anyjob-unread-message-container'>
                              {item?.total_no_of_unread}
                            </div>
                          ) 
                        }
                        
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}