import React, { useRef, useState } from 'react'
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';
import { toast } from 'react-toastify';

import './ContactUsPage.css'
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';

import EmailIcon from '../../assets/svg/email-icon.svg'
import PhoneIcon from '../../assets/svg/phone-icon.svg'
import WhatsAppIcon from '../../assets/svg/whatsapp-icon.svg'
import LoadingModal from '../../components/LoadingModal'
import { validateEmail } from '../../utils/validateEmail';
import { send_contact_form } from '../../api/notifications';

export default function ContactUsPage(props: any) {
  const [inputValues, setInputValues] = useState({
    email: "",
    full_name: "",
    message: "",
  })
  const [err, setErr] = useState({
    email: "",
    full_name: "",
    message: "",
  })

  const loadingModalRef = useRef<any>()

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
    
    setErr({
      ...inputValues,
      [name]: ""
    });
  }

  const validateForm = () => {
    var isValid = true

    if (!Boolean(inputValues.full_name.length) || inputValues.full_name.length < 3) {   
      isValid = false   
      setErr((prevState: any) => ({
        ...prevState,
        full_name: "Kindly provide a valid full name"
      }))
    }

    if (!Boolean(inputValues.message.length) || inputValues.message.length < 10) {   
      isValid = false   
      setErr((prevState: any) => ({
        ...prevState,
        message: "Kindly provide a valid full name"
      }))
    }

    if (Boolean(inputValues.email.length)) {
      if (!validateEmail(inputValues.email)) {
        isValid = false
        setErr((prevState: any) => ({
          ...prevState,
          email: "Kindly provide a valid email address"
        }))
      }
    } else {
      isValid = false
      setErr((prevState: any) => ({
        ...prevState,
        email: "Kindly provide a valid email address"
      }))
    }


    return isValid
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      loadingModalRef.current.showLoadingModal()
     
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        ...inputValues,
      }

      const res = await send_contact_form(params) 

      if (res.ok){
        const response = await res.json()
        setInputValues({
          ...inputValues,
          email: "",
          full_name: "",
          message: "",
        });
        loadingModalRef.current?. hideLoadingModal()
        toast.success(response.message || "Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const response = await res.json()
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message || "Oops... Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
      
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error("Oops... Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  var requestMessage = `Hello, good day I'd love to learn more about Anyjob`
  var whatsappLink = `https://api.whatsapp.com/send?phone=2348065368742&text=${requestMessage}`  

  return (
    <>
      <NavBar />
      <div className='anyjob-contact-us-container'>
        <section className='anyjob-contact-us-hero'>
          <p className='anyjob-contact-us-title'>
            Get in touch with AnyJob
          </p>
          <p className='anyjob-contact-us-sub-title'>
            Need our help, kindly reach out to us
          </p>
        </section>
        <section className='anyjob-contact-us-form-and-contact-info'>
          <div className='anyjob-contact-us-contact-info-section'>
            <div className='anyjob-contact-us-contact-item'>
              <img 
                src={EmailIcon}
                alt="Email"
              />
              <p>
                Email us: <a href='mailto:contact@anyjob.com'>contact@anyjob.com</a>
              </p>
            </div>
            <div className='anyjob-contact-us-contact-item'>
              <img 
                src={PhoneIcon}
                alt="Phone Number"
              />
              <p>
                Call Us: <a href='tel:+234 806 53 68742'>+234 806 536 8742</a>
              </p>
            </div>
            <div className='anyjob-contact-us-contact-item'>
              <img 
                src={WhatsAppIcon}
                alt="WhatsApp"
              />
              <p>
                Chat us via WhatsApp<a href={whatsappLink} target="_blank">+234 806 536 8742</a>
              </p>
            </div>
          </div>
          <form className='anyjob-contact-us-form' onSubmit={handleSubmit}>
            <p className='anyjob-contact-us-form-title'>
              Quick Message
            </p>
            <p className='anyjob-contact-us-form-sub-title'>
              Send a quick message 
            </p>

            <span className='anyjob-contact-us-form-input-group'>
              <p className='anyjob-contact-us-form-input-label'>
                Full Name
              </p>
              <input 
                className='anyjob-contact-us-form-input-field'
                placeholder='Enter Full Name'
                type="text"                
                required
                value={inputValues.full_name}
                onChange={handleInputChange}
                name="full_name"
              />
              {
                Boolean(err.full_name.length) && (
                  <p className="anyjob-contact-page-err-msg">
                    {err.full_name}                   
                  </p>
                )
              }
            </span>
            <span className='anyjob-contact-us-form-input-group'>
              <p className='anyjob-contact-us-form-input-label'>
                Email Address
              </p>
              <input 
                className='anyjob-contact-us-form-input-field'
                placeholder='Enter Email Address'
                type="email"
                required
                value={inputValues.email}
                onChange={handleInputChange}
                name="email"
              />
              {
                Boolean(err.email.length) && (
                  <p className="anyjob-contact-page-err-msg">
                    {err.email}                   
                  </p>
                )
              }
            </span>
            <span className='anyjob-contact-us-form-input-group'>
              <p className='anyjob-contact-us-form-input-label'>
                Description
              </p>
              <textarea 
                className='anyjob-contact-us-form-textarea-field'
                placeholder='Enter your Text'
                required
                value={inputValues.message}
                onChange={handleInputChange}
                name="message"
              />
              {
                Boolean(err.message.length) && (
                  <p className="anyjob-contact-page-err-msg">
                    {err.message}                   
                  </p>
                )
              }
            </span>
            <input 
              className='anyjob-contact-us-form-submit-btn'
              value="Send Message"
              type='submit'
            />
          </form>
        </section>
        <LoadingModal ref={loadingModalRef} />
        <Footer />
      </div>
      
    </>
  )
}