import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';

import './AddWorkExperience.css'
import { X } from "phosphor-react";
import countries from "../../utils/countries";
import LoadingModal from "../LoadingModal";
import getErrorMessage from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { create_work_history, update_work_history } from "../../api/profile";
import { ActiveTabType } from "../../pages/Onboarding/types";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};


type AddWorkExperienceProps = {
  goToNext?: (tabType: ActiveTabType) => void,
  increaseTabProgress?: () => void,
}

const AddWorkExperience = forwardRef((props: AddWorkExperienceProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    role: '',
    company: '',
    location: '',
    country: '',
    isActiveRole: false,
    startDateMonth: '', 
    startDateYear: '', 
    endDateMonth: '', 
    endDateYear: '', 
    description: '',
  })
  const [errMsg, setErrMsg] = useState({
    role: '',
    company: '',
    location: '',
    country: '',
    isActiveRole: false,
    startDateMonth: '', 
    startDateYear: '', 
    endDateMonth: '', 
    endDateYear: '', 
    description: '',
  })
  const [onClose, setOnClose] = useState<((status: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);
  const [action, setAction] = useState<'add' | 'edit'>('add')
  const [experienceID, setExperienceID] = useState<any>()

  const loadingModalRef = useRef<any>()

  const show1 = () => {
    setIsModalOpen(true)
  }

  const show = useCallback((action: 'add' | 'edit', cb?: (status: 'success' | 'failed' | 'canceled') => void, experience: any=null) => {
    // console.log('cb', cb);
    setAction(action)
    if (cb) {
      setOnClose(() => cb);
    }

    if (action === 'edit') {
      setInputValues((prevState: any) => ({
        ...prevState,
        role: experience?.role || '',
        company: experience?.company || '',
        location: experience?.location || '',
        country: experience?.country || '',
        isActiveRole: experience?.is_still_ongoing || false,
        startDateMonth: experience?.start_date_month || '', 
        startDateYear: experience?.start_date_year || '', 
        endDateMonth: experience?.end_date_month || '', 
        endDateYear: experience?.end_date_year || '', 
        description: experience?.description || '',
      }))
      setExperienceID(experience.id)
    }
    
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      role: '',
      company: '',
      location: '',
      country: '',
      isActiveRole: false,
      startDateMonth: '', 
      startDateYear: '', 
      endDateMonth: '', 
      endDateYear: '', 
      description: '',
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

    if (name === 'isActiveRole') {
      setInputValues({
        ...inputValues,
        endDateMonth: '', 
        endDateYear: '', 
        [name]: value
      });
    }

  }

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = currentYear; year >= 1960; year--) {
      years.push(year.toString());
    }

    return years
  }

  const validateForm = () => {
    var isValid = true

    if (Boolean(errMsg.role.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        role: "Kindly state your role"
      }))
      isValid = false
    }

    if (Boolean(errMsg.company.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        company: "Please provide the name of the company you worked with"
      }))
      isValid = false
    }

    if (Boolean(errMsg.location.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        location: "Please provide the location of your workplace"
      }))
      isValid = false
    }

    if (Boolean(errMsg.country.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        country: "Please provide the country of your workplace"
      }))
      isValid = false
    }

    if (Boolean(errMsg.startDateMonth.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        startDateMonth: "Please provide the start month and year of the position"
      }))
      isValid = false
    }

    if (Boolean(errMsg.startDateYear.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        startDateYear: "Please provide the start month and year of the position"
      }))
      isValid = false
    }

    if (Boolean(errMsg.description.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        description: "Please provide a description of your role in this position"
      }))
      isValid = false
    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      if (action === 'add') {
        await createWorkHistory()
      } else {
        await editWorkHistory()
      }
    } catch (error) {
    
    }
  }

  const createWorkHistory = async () => {
    try {
      var params = {
        role: inputValues.role,
        company: inputValues.company,
        location: inputValues.location,
        country: inputValues.country,
        is_still_ongoing: inputValues.isActiveRole,
        start_date_month: inputValues.startDateMonth, 
        start_date_year: inputValues.startDateYear, 
        end_date_month: inputValues.endDateMonth, 
        end_date_year: inputValues.endDateYear, 
        description: inputValues.description,
      }

      const res = await create_work_history(params)
      const response = await res.json()

      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        
        setIsModalOpen(false)
        setInputValues((prevState: any) => ({
          ...prevState,
          role: '',
          company: '',
          location: '',
          country: '',
          isActiveRole: false,
          startDateMonth: '', 
          startDateYear: '', 
          endDateMonth: '', 
          endDateYear: '', 
          description: '',
        }))
        // @ts-ignore
        onClose('success')
        // @ts-ignore
        props.increaseTabProgress()
        
      } else {
        console.log('response', response);
        
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const editWorkHistory = async () => {
    try {
      var params = {
        role: inputValues.role,
        company: inputValues.company,
        location: inputValues.location,
        country: inputValues.country,
        is_still_ongoing: inputValues.isActiveRole,
        start_date_month: inputValues.startDateMonth, 
        start_date_year: inputValues.startDateYear, 
        end_date_month: inputValues.endDateMonth, 
        end_date_year: inputValues.endDateYear, 
        description: inputValues.description,
        experience_id: experienceID,
      }

      const res = await update_work_history(params)
      const response = await res.json()

      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsModalOpen(false)
        setInputValues((prevState: any) => ({
          ...prevState,
          role: '',
          company: '',
          location: '',
          country: '',
          isActiveRole: false,
          startDateMonth: '', 
          startDateYear: '', 
          endDateMonth: '', 
          endDateYear: '', 
          description: '',
        }))
        // @ts-ignore
        onClose('success')
        
      } else {
        console.log('response', response);
        
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  

  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Add Work Experience"
      contentElement={() => {
        return (
          <div className="anyjob-add-experience-modal">
            <div className="anyjob-add-experience-modal-contents">
              <div className="anyjob-add-experience-modal-contents-header">
                <p>{action === 'add' ? "Add Work Experience" : "Edit Experience"}</p>
                <div className="anyjob-add-experience-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-add-experience-modal-close-icon"
                  />
                </div>
                
              </div>

              <form className="anyjob-add-experience-modal-form" onSubmit={handleSubmit}>
                <span className="anyjob-add-experience-modal-form-input-group">
                  <p className="anyjob-add-experience-modal-form-input-group-label">Role</p>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.role}
                    required
                    name="role"
                    className="anyjob-add-experience-modal-form-input-group-field"
                    placeholder="e.g. Product Designer"
                  />
                </span>
                {
                  errMsg.role && (
                    <p className="anyjob-add-experience-modal-form-err-msg">
                      {errMsg.role}
                    </p>
                  )
                }
                <span className="anyjob-add-experience-modal-form-input-group">
                  <p className="anyjob-add-experience-modal-form-input-group-label">Company</p>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.company}
                    required
                    name="company"
                    className="anyjob-add-experience-modal-form-input-group-field"
                    placeholder="e.g. AnyJob"
                  />
                </span>
                {
                  errMsg.company && (
                    <p className="anyjob-add-experience-modal-form-err-msg">
                      {errMsg.company}
                    </p>
                  )
                }
                <div className="anyjob-add-experience-modal-form-input-row-group">
                  <span className="anyjob-add-experience-modal-form-input-group anyjob-add-experience-location">
                    <p className="anyjob-add-experience-modal-form-input-group-label">Location</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.location}
                      required
                      name="location"
                      className="anyjob-add-experience-modal-form-input-group-field"
                    />
                    {
                      errMsg.location && (
                        <p className="anyjob-add-experience-modal-form-err-msg">
                          {errMsg.location}
                        </p>
                      )
                    }
                  </span>
                  <span className="anyjob-add-experience-modal-form-input-group anyjob-add-experience-country">
                    <p className="anyjob-add-experience-modal-form-input-group-label">Country</p>
                    <select 
                      onChange={handleInputChange} 
                      value={inputValues.country}
                      required
                      name="country"
                      className="anyjob-add-experience-modal-form-input-group-field"
                    >
                      <option value="" disabled></option>
                      {
                        countries.map((item: any, index: number) => {
                          return (
                            <option value={item.name} key={index}>{item.name}</option>
                          )
                        })
                      }
                      <option > </option>
                    </select>
                    {
                      errMsg.country && (
                        <p className="anyjob-add-experience-modal-form-err-msg">
                          {errMsg.country}
                        </p>
                      )
                    }
                  </span>
                </div>
                <div className="anyjob-add-experience-modal-form-input-row-group">
                  <span className="anyjob-add-experience-modal-form-input-group anyjob-add-experience-start-date">
                    <p className="anyjob-add-experience-modal-form-input-group-label">Start Date</p>
                    <span className="anyjob-add-experience-date-group">
                      <select 
                        onChange={handleInputChange} 
                        value={inputValues.startDateMonth}
                        required
                        name="startDateMonth"
                        className="anyjob-add-experience-modal-form-input-group-field anyjob-add-experience-start-date"
                      >
                        <option value="" disabled></option>
                        {
                          months.map((item: any, index: number) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                        <option > </option>
                      </select>
                      <select 
                        onChange={handleInputChange} 
                        value={inputValues.startDateYear}
                        required
                        name="startDateYear"
                        className="anyjob-add-experience-modal-form-input-group-field"
                      >
                        <option value="" disabled></option>
                        {
                          getYears().map((item: any, index: number) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                        <option > </option>
                      </select>
                    </span>
                    {
                      errMsg.startDateMonth && (
                        <p className="anyjob-add-experience-modal-form-err-msg">
                          {errMsg.startDateMonth}
                        </p>
                      )
                    }
                  </span>
                  <span className="anyjob-add-experience-modal-form-input-group anyjob-add-experience-start-date">
                    <p className="anyjob-add-experience-modal-form-input-group-label">End Date</p>
                    <span className="anyjob-add-experience-date-group">
                      <select 
                        onChange={handleInputChange} 
                        value={inputValues.endDateMonth}
                        // required
                        name="endDateMonth"
                        className="anyjob-add-experience-modal-form-input-group-field anyjob-add-experience-start-date"
                        disabled={inputValues.isActiveRole}
                      >
                        <option value="" disabled></option>
                        {
                          months.map((item: any, index: number) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                        <option > </option>
                      </select>
                      <select 
                        onChange={handleInputChange} 
                        value={inputValues.endDateYear}
                        // required
                        name="endDateYear"
                        className="anyjob-add-experience-modal-form-input-group-field"
                        disabled={inputValues.isActiveRole}
                      >
                        <option value="" disabled></option>
                        {
                          getYears().map((item: any, index: number) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                        <option > </option>
                      </select>
                    </span>
                    {
                      errMsg.endDateMonth && (
                        <p className="anyjob-add-experience-modal-form-err-msg">
                          {errMsg.endDateMonth}
                        </p>
                      )
                    }
                  </span>
                </div>
                <span className="anyjob-add-experience-modal-form-input-check">
                  <input 
                    type="checkbox"
                    onChange={handleInputChange} 
                    // @ts-ignore
                    value={inputValues.isActiveRole}
                    name="isActiveRole"
                  />
                  <p>
                    I’m currently working in this role
                  </p>
                </span>
                <span className="anyjob-add-experience-modal-form-input-group">
                  <p className="anyjob-add-experience-modal-form-input-group-label">Description</p>
                  <textarea 
                    onChange={handleInputChange} 
                    value={inputValues.description}
                    required
                    name="description"
                    className="anyjob-add-experience-modal-form-textarea"
                  />
                  {
                    errMsg.description && (
                      <p className="anyjob-add-experience-modal-form-err-msg">
                        {errMsg.description}
                      </p>
                    )
                  }
                </span>
                
                <span className="anyjob-add-experience-modal-form-actions">
                  <div className="anyjob-add-experience-modal-form-actions-cancel" onClick={hide}>
                    Cancel
                  </div>
                  <input 
                    value="Save"
                    type="submit"
                    className="anyjob-add-experience-modal-form-actions-save"
                  />
                </span>
              </form>
            </div>
          </div>
        )
      }}
    >
      <></>
      
    </Modal>
    
    <LoadingModal ref={loadingModalRef} />
    </>
  )
})

export default AddWorkExperience
