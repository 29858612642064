import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import './DashboardPage.css'

import { DefaultStateType } from '../../redux/types';
import LoadingModal from '../../components/LoadingModal';
import Header from '../../components/Header/Header';

import JobsApplied from '../../assets/svg/job-search.svg'
import OnGoingJobs from '../../assets/svg/ongoing-contracts.svg'
import Briefcase from '../../assets/svg/briefcase.svg'
import PaperAndPencil from '../../assets/svg/pencil-and-paper.svg'
import { ArrowRight } from 'phosphor-react';
import { get_freelancer_dashboard_data } from '../../api/profile';
import NotificationBanner from '../../components/NotificationBanner/NotificationBanner';
import { sleep } from '../../utils/sleep';
import { formatCurrency } from '../../utils/strings';

ChartJS.register(ArcElement, Tooltip);

export default function DashboardPage (props: any) {
  const [data, setData] = useState<any>(null)
  const [rejectedDoughnutValue, setRejectedDoughnutValue] = useState(1)
  const [completedDoughnutValue, setCompletedDoughnutValue] = useState(1)
  const userData = useSelector((state: DefaultStateType) => state.userData);
  const navigate = useNavigate()

  const loadingModalRef = useRef<any>()

  useEffect(() => {
    
    
    if (userData.isAuthenticated) {
      initiateUserOnboarding()
    } 
    
  }, [userData.isAuthenticated,])

  useEffect(() => {
    setUp()
    
  },[])
  useEffect(() => {
    setUp()
    
  },[userData.appMode])

  const setUp = async () => {
    if (userData.isAuthenticated) {
      initiateUserOnboarding()
    } 
    loadingModalRef.current.showLoadingModal()
    await fetchData()
    loadingModalRef.current?. hideLoadingModal()
  }

  const initiateUserOnboarding = () => {
    
    try {
      if (!userData.user_profile?.is_initial_user_onboarding_completed && !userData.user_profile?.skipped_initial_onboarding && userData.user_details?.is_freelancer) {                
        navigate('/freelancer-onboarding')
      }
    } catch (error) {
      
    }
  }

  const fetchData = async () => {
    try {      
      const res = await get_freelancer_dashboard_data(userData.user_details?.public_unique_id, userData.appMode )
      const response = await res.json()     
      if (res.ok) {
        setData(response.data)  
        setRejectedDoughnutValue(response.data.total_jobs_rejected)
        setCompletedDoughnutValue(response.data.total_jobs_completed)
      } else {
        toast.error(response.message || "Oops... Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error("Oops... Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const calReviewsNOffersPercentage = () => {

  }

  const doughData = {
    labels: ['Red', 'Blue', ],
    datasets: [
      {
        label: '# of Jobs Applied',
        data: [rejectedDoughnutValue, completedDoughnutValue,],
        backgroundColor: [
          'rgba(255, 200, 57, 1)',
          'rgba(255, 255, 177, 1)',
        ],
      },
    ],
  };

  const options = {
    // Set the width and height of the chart
    responsive: true,
    maintainAspectRatio: false,
    width: 100, // Set your desired width
    height: 100, // Set your desired height
  };

  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
  ) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

  return (
    <div className='anyjob-dashboard-page-container'>
      <LoadingModal ref={loadingModalRef} />
      <Header title={"Dashboard"} />
      <NotificationBanner />
      <div className='anyjob-dashboard-page-container'>
        <div className='anyjob-dashboard-page-content-hero'>
          <div className='anyjob-dashboard-page-hero-headings'>
            <p className='anyjob-proposal-hero-title'>
              Keep it up, {userData.user_details?.first_name}
            </p>
            <p className='anyjob-proposal-hero-desc'>
              Here is  what’s happening with your job search applications
            </p>
          </div>      
        </div>

        <div className='anyjob-dashboard-page-stats-section'>
          <div className='anyjob-dashboard-page-applied-ongoing-section' >
            <div className='anyjob-dashboard-page-stats-count'> 
              <p className='anyjob-dashboard-page-stats-count-title'>
                {
                  userData.appMode === 'client' ? "Total Jobs" : "Total Jobs Applied"
                }
              </p>
              <div className='anyjob-dashboard-page-stats-count-value-n-img'>
              <p className='anyjob-dashboard-page-stats-count-value'>
                {data?.total_jobs_applied}
              </p>
              <img 
                src={JobsApplied}
                alt='Total Jobs Applied'
                className='anyjob-dashboard-page-stats-count-img'
              />
              </div>
            
            </div>
            <div className='anyjob-dashboard-page-stats-count'> 
              <p className='anyjob-dashboard-page-stats-count-title'>
              Total Ongoing Tasks
              </p>
              <div className='anyjob-dashboard-page-stats-count-value-n-img'>
              <p className='anyjob-dashboard-page-stats-count-value'>
                {data?.total_jobs_in_review}
              </p>
              <img 
                src={OnGoingJobs}
                alt='Total Ongoing Projects'
                className='anyjob-dashboard-page-stats-count-img'
              />
              </div>
              
            </div>
          </div>
          

          <div className='anyjob-dashboard-page-stats-card'> 
            <p className='anyjob-dashboard-page-stats-card-title'>              
              {
                userData.appMode === 'client' ? "Project Status" : 'Jobs Applied Status'
              }
            </p>
            {
              ((data?.total_jobs_completed > 0) || (data?.total_jobs_rejected > 0)) ? (
                <>  
                  <div className='anyjob-dashboard-page-stats-card-stats-section'>
                    <div className='anyjob-dashboard-page-stats-doughnut-container'>
                      <Doughnut 
                        data={doughData} 
                        options={options}
                      />
                    </div>
                    <div className='anyjob-dashboard-page-stats-card-stats-legends'>
                      <div className='anyjob-dashboard-page-stats-legends-item'>
                        <span 
                          className='anyjob-dashboard-page-stats-legends-indicator'
                          style={{
                            background: "rgba(247, 206, 56, 1)",
                          }}
                        />
                        <span>
                          <p className='anyjob-dashboard-page-stats-legends-value'>
                            {data?.total_jobs_rejected}
                          </p>
                          <p className='anyjob-dashboard-page-stats-legends-label'>
                            Rejected
                          </p>
                        </span>
                      </div>
                      <div className='anyjob-dashboard-page-stats-legends-item'>
                        <span 
                          className='anyjob-dashboard-page-stats-legends-indicator'
                          style={{
                            background: "rgba(255, 255, 177, 1)",
                          }}
                        />
                        <span>
                          <p className='anyjob-dashboard-page-stats-legends-value'>
                            {data?.total_jobs_completed}
                          </p>
                          <p className='anyjob-dashboard-page-stats-legends-label'>
                          Completed
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>

                  <NavHashLink 
                    className='anyjob-dashboard-page-stats-card-cta'
                    exact to={'/tasks'} 
                    smooth
                  >
                    <p>
                      View All Tasks
                    </p>
                    <ArrowRight className='anyjob-dashboard-page-stats-card-cta-icon' />
                  </NavHashLink>
                </>
              ) : (
                <div className='anyjob-dashboard-page-stats-empty-card'>
                  <img 
                    src={Briefcase}
                    alt="No Jobs Applied"
                  />
                  <p>No Jobs Applied</p>
                </div>
              )
            }
            
            
            
          </div>
        </div>

        <div className='anyjob-dashboard-page-recent-applications-container'>
          <div className='anyjob-dashboard-page-recent-applications-header'>
            <p className='anyjob-dashboard-page-recent-applications-header-title'>
              Recent Tasks History
            </p>
            <NavHashLink 
              className='anyjob-dashboard-page-stats-card-cta'
              exact to={'/tasks'} 
              smooth
            >
              <p>
                View All Tasks
              </p>
              <ArrowRight className='anyjob-dashboard-page-stats-card-cta-icon' />
            </NavHashLink>
          </div>

          {
            Boolean(data?.latest_applications?.length) ? (
              <TableContainer component={Paper} className='anyjob-dashboard-table-container'>
                <Table sx={{ minWidth: 620 }} aria-label="simple table" className='anyjob-dashboard-table'>
                  <TableHead
                    className='anyjob-dashboard-table-header'
                  >
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell align="right">Date Applied</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.latest_applications.map((row: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => navigate(`/task-details/${row.unique_id}`)}
                        className='anyjob-dashboard-table-item:hover'
                      >
                        <TableCell component="th" scope="row">
                          {row?.job?.title}
                        </TableCell>
                        <TableCell align="right">{row?.date_applied}</TableCell>
                        <TableCell align="right">{row?.status}</TableCell>
                        <TableCell align="right">{row?.job?.compensation_currency?.symbol} {formatCurrency(row?.job?.compensation_amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className='anyjob-empty-state-container'>
                <img 
                  alt="No proposal submitted yet"
                  src={PaperAndPencil}
                />
                <p>
                  No tasks submitted yet
                </p>
                <Link
                  to="/jobs"
                  className='anyjob-empty-state-container-btn'
                >
                  Browse Jobs
                </Link>
              </div>
            )
          }    

        </div>

      </div>

    </div>
  )
}