import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { toast } from "react-toastify";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet'; // Import Leaflet library


import './SubmitTask.css'
import Header from '../../components/Header/Header'
import { fetch_jobs, get_job, send_proposal, submit_task } from '../../api/jobs'
import LoadingModal from '../../components/LoadingModal'
import { SubmitTaskProps } from './types';
import JobWidget from '../../components/JobWidget/JobWidget';
import getErrorMessage from '../../utils/getErrorMessage';
import CameraComponent, { cameraCallBackParamType } from '../../components/CameraComponent/CameraComponent';
import AttachementButton from './AttachementButton';
import PinIcon from '../../assets/images/Pin.png'
import { log } from 'console';

const customMarkerIcon = L.icon({
  iconUrl: PinIcon, // Adjust the path to your marker icon image
  iconSize: [30, 36], // Size of the icon image
  iconAnchor: [12, 41], // Anchor point of the icon image
  popupAnchor: [1, -34] // Popup anchor point
});

export default function SubmitTask(props: SubmitTaskProps) {
  const [jobData, setJobData] = useState<any>(null)
  const [coverLetter, setCoverLetter] = useState('')
  const [attachment, setAttachment] = useState<any>(null)
  const [attachmentFile, setAttachmentFile] = useState<any>(null)
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });
  const [isCapturingCoords, setIsCapturingCoords] = useState(false)
  const [dynamicInputValues, setDynamicInputValues ] = useState<any>({})

  const [errMsg, setErrMsg] = useState({
    coverLetter: '',
    attachment: '',
  })

  const { job } = useParams();

  const loadingModalRef = useRef<any>()
  const cameraComponentRef = useRef<any>()
  const attachmentButtonRef = useRef<any>()

  const navigate = useNavigate()

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    setDynamicInputValues({})
    setCoords({ latitude: 0, longitude: 0 })
    setCoverLetter('')
    loadingModalRef.current.showLoadingModal()
    await fetchJob()
    loadingModalRef.current?. hideLoadingModal()
    // getUserLocation()
  }

  const fetchJob = async () => {    
    try {
      
      const res = await get_job(job || '')
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        setJobData(response.data.job)    
      } else {

      }
    } catch (error) {
      
    }
  }

  const handleInputChange = async(event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setCoverLetter(target.value);

  }

  const handleDyanmicInputChange = async(event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setDynamicInputValues((prevState: any) => ({
      ...prevState,
      [name]: value
    }))

  }


  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        console.log('in valid form');
        
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params = {
        unique_id: jobData?.unique_id,
        ...(getSubmissionParam())
      }

      console.log('params', params);

      const res = await submit_task(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
        navigate("/tasks")
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const getSubmissionParam = () => {
    try {
      var params: any = {}
      if (jobData?.submission_type_dynamic_form) {
        params['dynamic_form_values'] = dynamicInputValues
      } else {
        params['dynamic_form_values'] = {}
      }

      if (jobData?.submission_type_geo_location) {
        params['coords'] = coords
      } else {
        params['coords'] = null
      }

      if (jobData?.submission_type_attachments) {
        params['attachments'] = attachmentButtonRef.current.getAttachments()
      } else {
        params['attachments'] = []
      }

      return params
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
 

  const validateForm = () => {
    try {
      var isValid = true
      if (jobData?.submission_type_dynamic_form) {
        console.log('dynamicInputValues.length', Object.keys(dynamicInputValues).length);
        console.log('jobData?.dynamic_form_fields.length', jobData?.dynamic_form_fields.length);
        
        if (Object.keys(dynamicInputValues).length === jobData?.dynamic_form_fields.length) {
          for (let field of jobData?.dynamic_form_fields) {
            if (!Boolean(dynamicInputValues[field?.name])) {
              isValid = false
            }
          }
        } else {
          isValid = false
        }
        
      }

      if (jobData?.submission_type_geo_location) {
        if (coords.latitude === 0 || coords.longitude === 0) {
          isValid = false
          toast.warn("Kindly use the button below to capture your location", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      if (jobData?.submission_type_attachments) {
        if (!Boolean(attachmentButtonRef.current.getAttachments().length)) {
          isValid = false
          toast.warn("Kindly attach/capture the required file(s)", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      return isValid
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false
    }
  }

  const validateFormBk = () => {
    try {
      var isValid = true
      switch (jobData?.submission_type) {
        case 'text-only':
          if (!Boolean(coverLetter.length)) {
            isValid = false
            toast.warn("Kindly fill the form", {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          break
        case 'text-n-attachments':
          if (!Boolean(coverLetter.length)) {
            isValid = false
            toast.warn("Kindly fill the form", {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          if (!Boolean(attachmentButtonRef.current.getAttachments().length)) {
            isValid = false
            toast.warn("Kindly attach/capture the required file(s)", {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          break
        case 'attachments-only':
          if (!Boolean(attachmentButtonRef.current.getAttachments().length)) {
            isValid = false
            toast.warn("Kindly attach/capture the required file(s)", {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          break
        case 'geo-location-only':
          if (coords.latitude === 0 || coords.longitude === 0) {
            isValid = false
            toast.warn("Kindly use the button below to capture your location", {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          break
        case 'geo-location-n-attachments':
          if (coords.latitude === 0 || coords.longitude === 0) {
            isValid = false
            toast.warn("Kindly use the button below to capture your location", {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          if (!Boolean(attachmentButtonRef.current.getAttachments().length)) {
            isValid = false
            toast.warn("Kindly attach/capture the required file(s)", {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          break
        case 'dynamic-form':
          if (dynamicInputValues.length === jobData?.dynamic_form_fields.length) {
            for (let field of jobData?.dynamic_form_fields) {
              if (!Boolean(dynamicInputValues[field?.name])) {
                isValid = false
              }
            }
          } else {
            isValid = false
          }
          
          break
      
        default:
          break
      }
      return isValid
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false
    }
  }

  const getSubmissionParamBk = () => {
    try {
      switch (jobData?.submission_type) {
        case 'text-only':
          return {
            cover_letter: coverLetter,
          }
        case 'text-n-attachments':
          return {
            cover_letter: coverLetter,
            attachments: attachmentButtonRef.current.getAttachments(),
          }
        case 'attachments-only':
          return {
            attachments: attachmentButtonRef.current.getAttachments(),
          }
        case 'geo-location-only':
          return {
            coords,
          }
        case 'geo-location-n-attachments':
          return {
            coords,
            attachments: attachmentButtonRef.current.getAttachments(),
          }
        case 'dynamic-form':
          return {
            dynamic_form_values: dynamicInputValues,
          }
      
        default:
          return {}
      }
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
 
  const getAllowedFileType = () => {
    switch (jobData?.attachment_type) {
      case 'audio-upload':
        return ".mp3, .wav, .ogg, .aac, .m4a"
      case 'doc-upload':
        return ".pdf, .doc, .docx, .txt"
      case 'image-upload':
        return ".jpg, .jpeg, .png, .gif, .bmp"
      case 'video-upload':
        return ".mp4, .avi, .mkv, .mov, .wmv"
    
      default:
        return ''
    }
  }

  const getUserLocation = (event?: any) => {
    event.preventDefault();
    if (navigator.geolocation) {
      setIsCapturingCoords(true)
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('position', position);
          
          setCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setIsCapturingCoords(false)
        },
        (error) => {
          // console.error("Error getting geolocation:", error);
          setIsCapturingCoords(false)
          toast.error("Error: " + error.message, {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
    } else {
      // console.error("Geolocation is not supported by this browser");
      toast.error("Geolocation is not supported by this browser", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  

  return (
    <div className='anyjob-task-page-container'>
      <Header title={"Submit Task"} />
      <div className='anyjob-submit-task-page-container'>        
        <div className='anyjob-submit-task-page-contents'>

        <form onSubmit={handleSubmit}>
          <div className='anyjob-submit-task-form'>
            <div className='anyjob-submit-task-input-group'>
              <p  className='anyjob-submit-task-input-group-label'>
              New Submission
              </p>
              {Boolean(jobData?.submission_type_dynamic_form ) && (
                <>
                  {
                    jobData?.dynamic_form_fields.map((field: any, index: number) => {
                      if (field.type === 'textarea') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            <textarea 
                              name={field.name}
                              // placeholder={field.label}
                              value={dynamicInputValues[field.name] || ''}
                              onChange={handleDyanmicInputChange}
                              required
                              className='anyjob-submit-task-dynamic-input-textare'
                            /> 
                          </span>
                        )
                      } else if (field.type === 'radio') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            {
                              field?.options.map((item: string, index: number) => {
                                return (
                                  <span className='anyjob-submit-task-dynamic-input-radio-span'>
                                    <input 
                                      onChange={handleDyanmicInputChange}
                                      name={field.name}
                                      value={item}
                                      checked={dynamicInputValues[field.name] === item}
                                      // placeholder={field.label}
                                      type={field.type}                            
                                      required
                                      className='anyjob-submit-task-dynamic-input-radio'
                                    />
                                    <p>
                                    {item}
                                    </p>
                                  </span>
                                )
                              })
                            }
                          </span>
                        )
                      } else if (field.type === 'select') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            <select 
                              name={field.name}
                              // placeholder={field.label}
                              value={dynamicInputValues[field.name] || ''}
                              onChange={handleDyanmicInputChange}
                              required
                              className='anyjob-submit-task-dynamic-input-text'

                            >
                              <option value="" disabled>-- Select Option -- </option>
                              {
                                field?.options.map((item: string, index: number) => {
                                  return (
                                    <option value={item} key={index}>{item}</option>
                                  )
                                })
                              }
                            </select> 
                          </span>
                        )
                      }
                      return (
                        <span 
                          className='anyjob-submit-task-dynamic-input-group'
                          key={index}
                        >
                          <p
                            className='anyjob-submit-task-dynamic-input-label'
                          >
                            {field.label}
                          </p>
                          <input 
                            onChange={handleDyanmicInputChange}
                            name={field.name}
                            value={dynamicInputValues[field.name] || ''}
                            // placeholder={field.label}
                            type={field.type}                            
                            required
                            className='anyjob-submit-task-dynamic-input-text'
                          />
                        </span>
                      )
                    })
                  }
                </>
              )}
              {
                Boolean(jobData?.submission_type_geo_location) && (
                  <>
                    <p className='anyjob-submit-task-note'>
                      This job requires you to be at the specified location in the job description/instructions when making this submission, hence your current location needs to be captured and submitted for review. You'd be asked to grant Anyjob access to view and capture your location, ensure to give the required permissions.
                    </p>
                    <p className='anyjob-submit-task-note'>
                      Note: Any submission made at a different location would be disregarded
                    </p>
                    {coords.latitude && coords.longitude ? (
                      <div>
                        <div className='anyjob-submit-task-map-container-box'>
                          <MapContainer 
                            center={[coords.latitude, coords.longitude]}
                            zoom={15} 
                            style={{ height: 185 }}
                            scrollWheelZoom={false}
                            className='anyjob-submit-task-map-container'
                          >
                            <TileLayer
                              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[coords.latitude, coords.longitude]} icon={customMarkerIcon}>
                              <Popup>
                                You are here!
                              </Popup>
                            </Marker>
                          </MapContainer>
                        </div>
                        <div 
                          className='anyjob-submit-task-btn2' 
                          onClick={getUserLocation}
                        >
                        {isCapturingCoords ? "Capturing..." :  "Re-Capture Location"}
                      </div>
                      </div>
                    ) : (
                      <button className='anyjob-submit-task-btn2' onClick={getUserLocation}>
                        {isCapturingCoords ? "Capturing..." :  "Capture Location"}
                      </button>
                    )}
                  </>
                )
              }
              {
                Boolean(jobData?.submission_type_attachments) && (
                  <>
                    <AttachementButton 
                      ref={attachmentButtonRef}
                      attachment_type={jobData?.attachment_type}
                      max_no_of_attachments={jobData?.max_no_of_attachments}
                      showCamera={cameraComponentRef.current.show}
                      attachments={[]}
                      isEditing
                    />
                    <p className='anyjob-submit-task-file-upload-note'>
                      The Maximum number of attachments is {jobData.max_no_of_attachments}{jobData?.attachment_type !== 'any' && `, and supported formats are ${getAllowedFileType()}`}
                    </p>
                  </>
                )
              }
            </div>
            <span className='anyjob-submit-task-submit-btn-container'>
              <input 
                className='anyjob-submit-task-submit-btn'
                type='submit'
              />
            </span>
          </div>
        </form>
          
          {
            jobData?.submission_type === 'text-only' && (
              <form onSubmit={handleSubmit}>
                <div className='anyjob-submit-task-form'>
                  <div className='anyjob-submit-task-input-group'>
                    <p  className='anyjob-submit-task-input-group-label'>
                    New Submission
                    </p>
                    <textarea 
                      value={coverLetter}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <span className='anyjob-submit-task-submit-btn-container'>
                    <input 
                      className='anyjob-submit-task-submit-btn'
                      type='submit'
                    />
                  </span>
                </div>
              </form>
            )
          }


          {
            jobData?.submission_type === 'text-n-attachments' && (
              <form onSubmit={handleSubmit}>
                <div className='anyjob-submit-task-form'>
                  <div className='anyjob-submit-task-input-group'>
                    <p  className='anyjob-submit-task-input-group-label'>
                    New Submission
                    </p>
                    <textarea 
                      value={coverLetter}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  
                  <div className='anyjob-submit-task-input-group'>
                    <p className='anyjob-submit-task-input-group-label'>
                      Attachments
                    </p>
                    <AttachementButton 
                      ref={attachmentButtonRef}
                      attachment_type={jobData?.attachment_type}
                      max_no_of_attachments={jobData?.max_no_of_attachments}
                      showCamera={cameraComponentRef.current.show}
                      attachments={[]}
                    />
                    <p className='anyjob-submit-task-file-upload-note'>
                      The Maximum number of attachments is {jobData.max_no_of_attachments}{jobData?.attachment_type !== 'any' && `, and supported formats are ${getAllowedFileType()}`}
                    </p>
                  </div>
                  <span className='anyjob-submit-task-submit-btn-container'>
                    <input 
                      className='anyjob-submit-task-submit-btn'
                      type='submit'
                    />
                  </span>
                </div>
              </form>
            )
          }

          {
            jobData?.submission_type === 'attachments-only' && (
              <form onSubmit={handleSubmit}>
                <div className='anyjob-submit-task-form'>
                  
                  <div className='anyjob-submit-task-input-group'>
                    <p className='anyjob-submit-task-input-group-label'>
                      New Submission 
                    </p>
                    <AttachementButton 
                      ref={attachmentButtonRef}
                      attachment_type={jobData?.attachment_type}
                      max_no_of_attachments={jobData?.max_no_of_attachments}
                      showCamera={cameraComponentRef.current.show}
                      attachments={[]}
                    />
                    <p className='anyjob-submit-task-file-upload-note'>
                      The Maximum number of attachments is {jobData.max_no_of_attachments}{jobData?.attachment_type !== 'any' && `, and supported formats are ${getAllowedFileType()}`}
                    </p>
                  </div>
                  <span className='anyjob-submit-task-submit-btn-container'>
                    <input 
                      className='anyjob-submit-task-submit-btn'
                      type='submit'
                    />
                  </span>
                </div>
              </form>
            )
          }

          {
            jobData?.submission_type === 'geo-location-only' && (
              <form onSubmit={handleSubmit}>
                <div className='anyjob-submit-task-form'>
                  
                  <div className='anyjob-submit-task-input-group'>
                    <p className='anyjob-submit-task-input-group-label'>
                      New Submission
                    </p>
                    <p className='anyjob-submit-task-note'>
                      This job requires you to be at the specified location in the job description/instructions when making this submission, hence your current location needs to be captured and submitted for review. You'd be asked to grant Anyjob access to view and capture your location, ensure to give the required permissions.
                    </p>
                    <p className='anyjob-submit-task-note'>
                      Note: Any submission made at a different location would be disregarded
                    </p>
                    {coords.latitude && coords.longitude ? (
                      <div>
                        <div className='anyjob-submit-task-map-container-box'>
                          <MapContainer 
                            center={[coords.latitude, coords.longitude]}
                            zoom={15} 
                            style={{ height: 185 }}
                            scrollWheelZoom={false}
                            className='anyjob-submit-task-map-container'
                          >
                            <TileLayer
                              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[coords.latitude, coords.longitude]} icon={customMarkerIcon}>
                              <Popup>
                                You are here!
                              </Popup>
                            </Marker>
                          </MapContainer>
                        </div>
                        <button className='anyjob-submit-task-btn2' onClick={getUserLocation}>
                        {isCapturingCoords ? "Capturing..." :  "Re-Capture Location"}
                      </button>
                      </div>
                    ) : (
                      <button className='anyjob-submit-task-btn2' onClick={getUserLocation}>
                        {isCapturingCoords ? "Capturing..." :  "Capture Location"}
                      </button>
                    )}
                  </div>
                  <span className='anyjob-submit-task-submit-btn-container'>
                    <input 
                      className='anyjob-submit-task-submit-btn'
                      type='submit'
                    />
                  </span>
                </div>
              </form>
            )
          }

          {
            jobData?.submission_type === 'geo-location-n-attachments' && (
              <form onSubmit={handleSubmit}>
                <div className='anyjob-submit-task-form'>
                  <div className='anyjob-submit-task-input-group'>
                    <p  className='anyjob-submit-task-input-group-label'>
                      New Submission
                    </p>
                    <p className='anyjob-submit-task-note'>
                      This job requires you to be at the specified location in the job description/instructions when making this submission, hence your current location needs to be captured and submitted for review. You'd be asked to grant Anyjob access to view and capture your location, ensure to give the required permissions.
                    </p>
                    <p className='anyjob-submit-task-note'>
                      Note: Any submission made at a different location would be disregarded
                    </p>
                    {coords.latitude && coords.longitude ? (
                      <div>
                        <div className='anyjob-submit-task-map-container-box'>
                          <MapContainer 
                            center={[coords.latitude, coords.longitude]}
                            zoom={15} 
                            style={{ height: 185 }}
                            scrollWheelZoom={false}
                            className='anyjob-submit-task-map-container'
                          >
                            <TileLayer
                              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[coords.latitude, coords.longitude]} icon={customMarkerIcon}>
                              <Popup>
                                You are here!
                              </Popup>
                            </Marker>
                          </MapContainer>
                        </div>
                        <button className='anyjob-submit-task-btn2' onClick={getUserLocation}>
                        {isCapturingCoords ? "Capturing..." :  "Re-Capture Location"}
                      </button>
                      </div>
                    ) : (
                      <button className='anyjob-submit-task-btn2' onClick={getUserLocation}>
                        {isCapturingCoords ? "Capturing..." :  "Capture Location"}
                      </button>
                    )}
                  </div>

                  
                  <div className='anyjob-submit-task-input-group'>
                    <p className='anyjob-submit-task-input-group-label'>
                      Attachments
                    </p>
                    <AttachementButton 
                      ref={attachmentButtonRef}
                      attachment_type={jobData?.attachment_type}
                      max_no_of_attachments={jobData?.max_no_of_attachments}
                      showCamera={cameraComponentRef.current.show}
                      attachments={[]}
                    />
                    <p className='anyjob-submit-task-file-upload-note'>
                      The Maximum number of attachments is {jobData.max_no_of_attachments}{jobData?.attachment_type !== 'any' && `, and supported formats are ${getAllowedFileType()}`}
                    </p>
                  </div>
                  <span className='anyjob-submit-task-submit-btn-container'>
                    <input 
                      className='anyjob-submit-task-submit-btn'
                      type='submit'
                    />
                  </span>
                </div>
              </form>
            )
          }

          {
            jobData?.submission_type === 'dynamic-form' && (
              <form onSubmit={handleSubmit}>
                <div className='anyjob-submit-task-form'>
                  <p  className='anyjob-submit-task-input-group-label'>
                    New Submission
                  </p>
                  {
                    jobData?.dynamic_form_fields.map((field: any, index: number) => {
                      console.log('field.type', field.type);
                      
                      if (field.type === 'textarea') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            <textarea 
                              name={field.name}
                              // placeholder={field.label}
                              value={dynamicInputValues[field.name] || ''}
                              onChange={handleDyanmicInputChange}
                              required
                              className='anyjob-submit-task-dynamic-input-textare'
                            /> 
                          </span>
                        )
                      } else if (field.type === 'select') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            <select 
                              name={field.name}
                              // placeholder={field.label}
                              value={dynamicInputValues[field.name] || ''}
                              onChange={handleDyanmicInputChange}
                              required
                              className='anyjob-submit-task-dynamic-input-text'

                            >
                              <option value="" disabled>-- Select Option -- </option>
                              {
                                field?.options.map((item: string, index: number) => {
                                  return (
                                    <option value={item} key={index}>{item}</option>
                                  )
                                })
                              }
                            </select> 
                          </span>
                        )
                      } else if (field.type === 'radio') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            {
                              field?.options.map((item: string, index: number) => {
                                return (
                                  <span className='anyjob-submit-task-dynamic-input-radio-span'>
                                    <input 
                                      onChange={handleDyanmicInputChange}
                                      name={field.name}
                                      value={item}
                                      checked={dynamicInputValues[field.name] === item}
                                      // placeholder={field.label}
                                      type={field.type}                            
                                      required
                                      className='anyjob-submit-task-dynamic-input-radio'
                                    />
                                    <p>
                                    {item}
                                    </p>
                                  </span>
                                )
                              })
                            }
                          </span>
                        )
                      } 
                      return (
                        <span 
                          className='anyjob-submit-task-dynamic-input-group'
                          key={index}
                        >
                          <p
                            className='anyjob-submit-task-dynamic-input-label'
                          >
                            {field.label}
                          </p>
                          <input 
                            onChange={handleDyanmicInputChange}
                            name={field.name}
                            value={dynamicInputValues[field.name] || ''}
                            // placeholder={field.label}
                            type={field.type}                            
                            required
                            className='anyjob-submit-task-dynamic-input-text'
                          />
                        </span>
                      )
                    })
                  }
                  <input 
                    className='anyjob-submit-task-submit-btn'
                    type='submit'
                  />
                </div>
                
              </form>
            )
          }

          <JobWidget 
            job={jobData}
          />
          
        </div>
      </div>
      <LoadingModal ref={loadingModalRef} />
      <CameraComponent ref={cameraComponentRef} />
    </div>
  )
}