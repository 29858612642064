import React, { useEffect, useRef, useState }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { CaretLeft, User } from "phosphor-react";
import { PhoneInput } from 'react-international-phone';
// @ts-ignore
import { PhoneNumberUtil } from 'google-libphonenumber';

import './Onboarding.css'
import { DefaultStateType } from "../../redux/types";
import { initiate_phone_verification, update_personal_info } from "../../api/auth";
import LoadingModal from '../../components/LoadingModal'
import { toast } from "react-toastify";
import getErrorMessage from "../../utils/getErrorMessage";
import { PersonalInfoProps } from "./types";
import { get_banks_via_paystack, get_supported_kyc_ids, process_kyc_v1, resolve_nigeria_bank_via_paystack, save_account_details } from "../../api/profile";
import PinModal from "../../components/PinModal/PinModal";
import VerifyOTPModal from "../../components/VerifyOTPModal/VerifyOTPModal";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export default function AccountVerification(props: PersonalInfoProps) {
  
  const [inputValues, setInputValues] = useState({
    idType: '',
    idNumber: '',
    phoneNumber: '',
  })
  const [errMsg, setErrMsg] = useState({
    idType: '',
    idNumber: '',
    phoneNumber: '',
  })
  const [phone, setPhone] = useState('');
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false)
  const userData = useSelector((state: DefaultStateType) => state.userData);
  const isValid = isPhoneValid(phone);

  const [supportedIDs, setSupportedIDs] = useState<any[]>([])

  // const dispatch = useDispatch()
  const loadingModalRef = useRef<any>()
  const verifyOTPModalRef = useRef<any>()

  useEffect(() => {
    setPhone(userData.user_details.phone_number)
  }, [])

  const fetchSupportedIDs =async () => {
    try {
      const res = await get_supported_kyc_ids()
      const response = await res.json()
      if (res.ok) {   
        setSupportedIDs(response.data.supported_kyc_ids)
        console.log('response.data.supported_kyc_ids', response.data.supported_kyc_ids[0]);
      } else {
        
        
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
    } catch (error) {
      
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
    
    setErrMsg({
      ...errMsg,
      [name]: ""
    });

  }


  const validateForm = () => {
    var isValid = true
    if (!Boolean(inputValues.idType.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        idType: "Please select your ID type"
      }))
    }
    if (!Boolean(inputValues.idNumber.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        idNumber: "Please provide your ID number"
      }))
    } else {
      var regexPattern = /^[0-9]{11}$/;// (supportedIDs.find(obj => obj.title === inputValues.idType)).id_number_format
      if (!(regexPattern.test(inputValues.idNumber))) {
        isValid = false
        setErrMsg((prevState: any) => ({
          ...prevState,
          idNumber: "Please provide a valid ID number"
        }))
      }

    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params = {       
        id_number: inputValues.idNumber,
        supported_id: (supportedIDs.find(obj => obj.title === inputValues.idType)),
      }

      const res = await process_kyc_v1(params)
      const response = await res.json()
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
 
        toast.success(response?.message || response?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.increaseTabProgress()
        props.goToNext('final-msg')
      } else {
        
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response?.message || response?.detail, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const verifyPhoneNumber = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!isValid) {
        
        setErrMsg((prevState: any) => ({
          ...prevState,
          phoneNumber: "Invalid phone number"
        }))
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        phone_number: phone
      }
      

      const res = await initiate_phone_verification(params)
      const response = await res.json()
      
      if (res.ok) {
        setIsPhoneNumberVerified(true)
        loadingModalRef.current?. hideLoadingModal()
        verifyOTPModalRef.current.show(verifyOTPCallback, response.data.temp_id)

        // toast.success("Success", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      } else {
        setErrMsg((prevState:any) => ({
          ...prevState,
          phoneNumber: response.message
        }))
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const verifyOTPCallback = async (status: 'success' | 'failed' | 'canceled', ) => {
    switch (status) {
      case 'success':
        props.increaseTabProgress()
        props.goToNext('final-msg')
        break;
    
      default:
        break;
    }
  }

  return (
    <div className="anyjob-onboarding-page-tab-content">
      <div className="anyjob-onboarding-page-tab-content-headings">
        <p className="anyjob-onboarding-page-tab-content-headings-title">
          Account Verification
        </p>
        <p className="anyjob-onboarding-page-tab-content-headings-desc">
          Almost done! Trust and safety is a big deal on AnyJob.
        </p>
      </div>
    
      {/* <form className="anyjob-onboarding-page-personal-info-form" onSubmit={handleSubmit}> 

        <div className="anyjob-onboarding-page-input-group">          
          <p className="anyjob-onboarding-page-input-group-desc">
          Means of Identification
          </p>
          <select
            onChange={handleInputChange} 
            value={inputValues.idType}
            required
            name="idType"
            className="anyjob-onboarding-page-input-group-input-field"
            
          >
            <option value="" disabled>National Identity Card, Passport or Driving License</option>
            {
              supportedIDs.map((item: any, index: number) => {
                return (
                  <option value={item.title} key={index}>{item.title}</option>
                )
              })
            }
          </select> 
          {
            errMsg.idType && (
              <p className="anyjob-onboarding-page-input-err-msg">
                {errMsg.idType}
              </p>
            )
          }
        </div>
        <div className="anyjob-onboarding-page-input-group">          
          <p className="anyjob-onboarding-page-input-group-desc">
          Identification Number
          </p>
          <input 
            onChange={handleInputChange} 
            type="text"
            value={inputValues.idNumber}
            required
            name="idNumber"
            
            className="anyjob-onboarding-page-input-group-input-field"
          />
          
          {
            errMsg.idNumber && (
              <p className="anyjob-onboarding-page-input-err-msg">
                {errMsg.idNumber}
              </p>
            )
          }
        </div>
        
      </form> */}
      <form className="anyjob-onboarding-page-personal-info-form" onSubmit={handleSubmit}> 

      <div className="anyjob-onboarding-page-input-group">          
          <p className="anyjob-onboarding-page-input-group-desc">
          Verify Your Phone Number
          </p>
          {/* <span className={isPhoneNumberFocused ? "anyjob-onboarding-page-acct-no-group-focused" : "anyjob-onboarding-page-acct-no-group"}>
            <input 
              onChange={handleInputChange} 
              type="text"
              value={inputValues.phoneNumber}
              required
              name="phoneNumber"
              className="anyjob-onboarding-page-acct-no-field"
              placeholder="e.g. +234 801 234 5678"
              onFocus={() => setIsPhoneNumberFocused(true)}
              onBlur={() => setIsPhoneNumberFocused(false)}
            />
            <button 
              className={isPhoneNumberVerified? "anyjob-onboarding-page-acct-no-group-verified-btn" : "anyjob-onboarding-page-acct-no-group-verify-btn"}
              onClick={verifyPhoneNumber}
              disabled={isPhoneNumberVerified}
            >
              <p>{isPhoneNumberVerified ? "Verified" : "Verify"}</p>
            </button>
          </span> */}

          <PhoneInput
            defaultCountry="ng"
            value={phone}
            onChange={(phone) => setPhone(phone)}
            className="anyjob-auth-page-phone-input"
            required
            // countrySelectorStyleProps={{
            //   className: "anyjob-auth-page-input"
              
            // }}
            inputStyle={{
              height: 58,
              width: 460,
              borderTopRightRadius: 30,
              borderBottom: 30,
              borderWidth: 0,
            }}
            style={{
              height: 60,
              background: 'transparent'
            }}
            inputClassName="anyjob-auth-page-phone-inner-input"
            countrySelectorStyleProps={{
              buttonClassName: "anyjob-auth-page-phone-input-selector-btn",
              buttonStyle: {
                height: 58,
                width: 100,
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
                justifyContent: 'center',
                alignItems: 'center',
                borderRightWidth: 1,
              },
              flagStyle: {
                flex: 1,
              }
            }}
          />
          
          {
            errMsg.phoneNumber && (
              <p className="anyjob-onboarding-page-input-err-msg">
                {errMsg.phoneNumber}
              </p>
            )
          }
        </div>
      </form>
      <div className="anyjob-onboarding-page-controls">
        <div className="anyjob-onboarding-page-back-btn"
          onClick={() => props.goToNext("payment-info")}
        >
          <CaretLeft className="anyjob-onboarding-page-back-btn-icon" />
          Back 
        </div>
        <button 
          className="anyjob-onboarding-page-cont-btn"
          type="submit"
          onClick={verifyPhoneNumber}
        >Continue </button>
      </div>
      <LoadingModal ref={loadingModalRef} />
      <VerifyOTPModal ref={verifyOTPModalRef} />
    </div>
  )
}