import React, { useState } from 'react'
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';

import './HomePage.css'
import HeroImage from '../../assets/images/home_hero_img.png'
import Betway from '../../assets/svg/betway.svg'
import Mobile9 from '../../assets/svg/9mobile.svg'
import Glovo from '../../assets/svg/glovo-logo-vector.svg'
import Airtel from '../../assets/svg/airtel.svg'
import Glo from '../../assets/svg/glo.svg'
import MTN from '../../assets/svg/mtn.svg'
import Coke from '../../assets/svg/coke.svg'
import Opera from '../../assets/svg/ONews_Wordmark_Horizontal_Positive 1.svg'
import Diageo from '../../assets/images/diageo.png'
import InDrive from '../../assets/svg/indriver.svg'
import PhoneCircle from '../../assets/svg/phone-circle.svg'
import LaptopCircle from '../../assets/svg/laptop-circle.svg'
import PenCircle from '../../assets/svg/pen-circle.svg'
import VideoIcon from '../../assets/svg/video.svg'
import MusicCircle from '../../assets/svg/music-circle.svg'
import TechCircle from '../../assets/svg/tect-circle.svg'
import Business from '../../assets/svg/business.svg'
import Fun from '../../assets/svg/fun.svg'
import FreelanceImg from '../../assets/images/freelancer.png'
import ClientImg from '../../assets/images/client.png'
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';

export default function HomePage(props: any) {
  const [email, setEmail] = useState("")  

  const heroHeading = "Get paid performing\nfor any job, any time"
  const heroHeadingParagraphs = heroHeading.split('\n');



  return (
    <>
      <NavBar />
      <div className='anyjob-homepage-container'>
        <section className='anyjob-homepage-hero'>
          <div className='anyjob-homepage-hero-contents'>
            {/* {heroHeadingParagraphs.map((paragraph, index) => (
              <p className='anyjob-homepage-hero-contents-heading'>
                {paragraph}
              </p>
            ))} */}
            <p className='anyjob-homepage-hero-contents-heading'>
              Task. Earn. Repeat.
            </p>
            <p className='anyjob-homepage-hero-contents-desc'>
              Complete tasks, earn cash effortlessly. Zero-skill-based tasks. Sign up now and start earning today!
            </p>

            <NavHashLink 
              className='anyjob-homepage-hero-contents-cta'
              exact to={'/signup'} 
              smooth
            >
              Get Started
            </NavHashLink>
            
            <div
              className='anyjob-homepage-hero-stats-container'
            >
              <div>
                <p className='anyjob-homepage-hero-stats-text'>
                  2k+ Users
                </p>
              </div>
              <div>
                <p className='anyjob-homepage-hero-stats-text'>
                  10k+ Tasks Completed
                </p>
              </div>
              <div>
                <p className='anyjob-homepage-hero-stats-text'>
                  ₦50M+ Paid
                </p>
              </div>
            </div>
          </div>
          <div className='anyjob-homepage-hero-image-container'>
            <img
              src={HeroImage}
              alt="Earn money performing basic tasks"
            />
          </div>         
        </section>
        <section className='anyjob-partnering-brand'>
          <img 
            src={Betway}
            alt='BetWay'
          />
          <img 
            src={Mobile9}
            alt='9 Mobile'
          />
          <img 
            src={Glovo}
            alt='Glovo'
          />
          <img 
            src={Airtel}
            alt='Airtel'
          />
          <img 
            src={Opera}
            alt='Opera'
          />
          <img 
            src={Glo}
            alt='Glo'
          />
          <img 
            src={MTN}
            alt='MTN'
          />
          <img 
            src={Coke}
            alt='Coke'
          />
          <img 
            src={InDrive}
            alt='InDrive'
          />
          <img 
            src={Diageo}
            alt='DIAGEO'
          />
        </section>
        {/* <section className='anyjob-homepage-categories'>
          <p className='anyjob-homepage-heading anyjob-homepage-categories-title'>
            Explore Categories
          </p>
          <p className='anyjob-homepage-desc anyjob-homepage-categories-title'>
            Search jobs by categories
          </p>

          <div className='anyjob-homepage-categories-grid-container'>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={PhoneCircle}
                alt="Mobiles"
              />
              <h2>Mobiles</h2>
              <p>Craft mobile magic: Secure top gigs through AnyJob platform</p>
            </div>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={LaptopCircle}
                alt="Digital Marketing"
              />
              <h2>Digital Marketing</h2>
              <p>
                Fuel your digital marketing career: Find prime gigs at AnyJob!
              </p>
            </div>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={PenCircle}
                alt="Writing &  Translation"
              />
              <h2>
              Writing &  Translation
              </h2>
              <p>
                Translate success: Unlock global opportunities through AnyJob for freelance jobs!
              </p>
            </div>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={VideoIcon}
                alt="Video & Animation"
              />
              <h2>
              Video & Animation
              </h2>
              <p>
              Capture freelance triumph: Discover video gigs with AnyJob now!
              </p>
            </div>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={MusicCircle}
                alt="Music & Audio"
              />
              <h2>
              Music & Audio
              </h2>
              <p>
              Compose your freelance success: Land music gigs via AnyJob!
              </p>
            </div>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={TechCircle}
                alt="Programming & Tech"
              />
              <h2>
              Programming & Tech
              </h2>
              <p>
              Code your path to  success: Find programming gigs at AnyJob
              </p>
            </div>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={Business}
                alt="Business"
              />
              <h2>
              Business
              </h2>
              <p>
              Unlock business opportunities: Land your dream jobs with AnyJob
              </p>
            </div>
            <div className='anyjob-homepage-categories-grid-item '>
              <img 
                src={Fun}
                alt="Fun"
              />
              <h2>
              Fun & Lifestyle
              </h2>
              <p>
              Elevate fun and lifestyle projects: score gigs on AnyJob
              </p>
            </div>
          </div>
          <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <NavHashLink 
              exact to={'/signup'} 
              smooth
              className={"anyjob-homepage-signup"}
            >
              Get Started
            </NavHashLink>
          </div>
        </section> */}
        <section className='anyjob-homepage-freelancer-sec'>
          <img 
            src={FreelanceImg}
            alt='Freelancer'
          />
          <div className='anyjob-homepage-freelancer-content'>
            <p className='anyjob-homepage-heading '>
              Tasks Done, Cash Made!
            </p>
            <p className='anyjob-homepage-desc'>
            Turn your spare time into extra cash effortlessly! Sign up now for our platform where you can complete zero-skill-based tasks and get paid. It's easy, fun, and a great way to earn on your schedule. Join today and start earning!"         
            </p>
            <NavHashLink 
              exact to={'/signup'} 
              smooth
              className={"anyjob-homepage-freelancer-content-btn"}
            >
              Sign-up Now!
            </NavHashLink>
          </div>
        </section>
        <section className='anyjob-homepage-client-sec'>        
          <div className='anyjob-homepage-client-content'>
            <p className='anyjob-homepage-heading '>
              List Tasks, Get Results!
            </p>
            <p className='anyjob-homepage-desc'>
            Looking for a simple solution to get your tasks done? List your zero-skill-based tasks on our platform and connect with eager users ready to help. From data entry to surveys, get your tasks completed hassle-free. List your tasks now and get results!
            </p>
            <NavHashLink 
              exact to={'/signup'} 
              smooth
              className={"anyjob-homepage-client-content-btn"}
            >
              List Your Tasks!
            </NavHashLink>
          </div>
          <img 
            src={ClientImg}
            alt='Client'
          />
        </section>
        
        <Footer />
      </div>
      
    </>
  )
}