import { BASE_URL } from "../env"
import { apiFetch, authApiFetch } from "./utils"


export const send_contact_form = async (params: any) => {
  try {
    const url = BASE_URL + '/api/notifications/send_contact_form'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const subscribe_to_newsletters = async (params: any) => {
  try {
    const url = BASE_URL + '/api/notifications/subscribe_to_newsletters'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

type FetchNotificationsParamsType = {
  page: number,
  notification_type: string,
}

export const notifications_list = async (params: FetchNotificationsParamsType) => {
  try {
    const url = BASE_URL + `/api/notifications/notifications_list?notification_type=${params.notification_type}&page=${params.page}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}
