import React, { useEffect, useRef, useState }  from "react";
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';
import { toast } from 'react-toastify';
import { 
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { PhoneInput } from 'react-international-phone';
// @ts-ignore
import { PhoneNumberUtil } from 'google-libphonenumber';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import OtpInput from 'react-otp-input';
import { Check } from "phosphor-react";


import './AuthPage.css'
import { AuthPageProps, DisplayType } from "./types";


import countries, { getCountryViaKeyValue } from "./coutries";
import { address_auto_complete, forgot_password, log_in, register_client, register_user, request_otp, verify_change_password, verify_email_or_phone, web_login } from "../../api/auth";
import getErrorMessage from "../../utils/getErrorMessage";
import { saveObject, saveSecureData } from "../../utils/handleStorage";

import { logIn } from "../../redux/userData";

import Logo from '../../assets/svg/Logo.svg'
import ArrowLeft from '../../assets/svg/arrow-left.svg'
import ClientIcon from '../../assets/svg/client-icon.svg'
import FreelancerIcon from '../../assets/svg/online-work.svg'
import HeroImage from '../../assets/images/home_hero_img.png'
import Pin from '../../assets/svg/Pin_light.svg'
import { containsNumber, containsSpecialChars, hasLowerCase, hasUpperCase } from "../../utils/strings";

import { sleep } from "../../utils/sleep";
import LoadingModal from '../../components/LoadingModal'
import FlyingMail from '../../assets/svg/flying-email.svg'
import EmailIcon from '../../assets/svg/vuesax/linear/vuesax/linear/sms.svg'
import { validateEmail } from "../../utils/validateEmail";
import { get_supported_countries } from "../../api/locations";
import { setActiveWallet } from "../../redux/wallet";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};


function AuthPage(props: AuthPageProps) {
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
    password2: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    address: "",
    country: "",
    city: "",
    region: "",
    businessName: "",
    isFleet: false,
    role: "",
    referralID: "",
    acceptedTerms: false,
    rememberMe: false,
    otp: "",
    placeID: "",
  })
  const [err, setErr] = useState({
    email: "",
    password: "",
    password2: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    address: "",
    country: "",
    city: "",
    region: "",
    businessName: "",
    isFleet: false,
    role: "",
    referralID: "",
    acceptedTerms: false,
    otp: "",
    default: "",
    userType: "",
  })
  const [showPassword, setShowPassword] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayType, setDisplayType] = useState<DisplayType>("sign-up-00");
  const [tempID, setTempID] = useState("")
  const [otpPurpose, setOtpPurpose] = useState("")
  const [userType, setUserType] = useState<"" | "client" | "freelancer">("") 
  const [showplaceSuggestions, setShowplaceSuggestions] = useState(false)
  const [placeSuggestions, setPlaceSuggestions] = useState([])
  const [placeSession, setPlaceSession] = useState('')
  const [isPasswordFocus, setIsPasswordFocus] = useState(false)
  const [isPassword2Focus, setIsPassword2Focus] = useState(false)
  const [passwordChecks, setPasswordChecks] = useState({
    upperCase: false,
    lowerCase: false,
    digit: false,
    specialChar: false,
    length: false,
  })
  const [otp, setOtp] = useState('');
  const [showCountrySelector, setShowCountrySelector] = useState(true)
  const [supportedCountries, setSupportedCountries] = useState<any>([])

  const [phone, setPhone] = useState('');
  const isValid = isPhoneValid(phone);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch()

  const loadingModalRef = useRef<any>()

  let to = location.state?.from?.pathname || "/jobs";

  useEffect(() => {
    fetchSupportedCountries()
  }, [])

  useEffect(() => {
    handleDisplayType()
    let code = searchParams.get("code")
    let temp_id = searchParams.get("temp_id")
    let email = searchParams.get("email")
    let purpose = searchParams.get("purpose")
    setTempID(temp_id || "")
    setInputValues({
      ...inputValues,
      otp: code || "",
      email: email || "",
    });
    setOtpPurpose(purpose || "")
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleDisplayType()
    let code = searchParams.get("code")
    let temp_id = searchParams.get("temp_id")
    let email = searchParams.get("email")
    let purpose = searchParams.get("purpose")
    setTempID(temp_id || "")
    setOtpPurpose(purpose || "")
    setInputValues({
      ...inputValues,
      otp: code || "",
      email: email || "",
    });

    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.action])

  const handleDisplayType = async () => {
    switch (props.action) {
      case 'signup':
        setDisplayType("sign-up-00")
        setInputValues((prevState: any) => ({
          ...prevState,
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          phoneNo: "",
          address: "",
          country: "",
          state: "",
          businessName: "",
          isFleet: false,
          role: "",
          referralID: "",
          acceptedTerms: false,
          rememberMe: false,
          otp: "",
          placeID: "",
        }))
        await fetchSupportedCountries()
        break;
      case 'login':
        setDisplayType("login")
        setOtpPurpose("Login")
        setShowPassword(false)
        setInputValues((prevState: any) => ({
          ...prevState,
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          phoneNo: "",
          address: "",
          country: "",
          state: "",
          businessName: "",
          isFleet: false,
          role: "",
          referralID: "",
          acceptedTerms: false,
          rememberMe: false,
          otp: "",
          placeID: "",
        }))
        break;
      case 'verify-otp':
        setDisplayType("verify-otp")
        requestOtp()
        break;
      case 'forgot-password':
        setDisplayType("forgot-password")
        setInputValues((prevState: any) => ({
          ...prevState,
          email: "",
          password: "",
          password2: "",
          firstName: "",
          lastName: "",
          phoneNo: "",
          address: "",
          country: "",
          state: "",
          businessName: "",
          isFleet: false,
          role: "",
          referralID: "",
          acceptedTerms: false,
          rememberMe: false,
          otp: "",
          placeID: "",
        }))
        break;
      case 'reset-password':
        setDisplayType("reset-password")
        setInputValues((prevState: any) => ({
          ...prevState,
          email: "",
          password: "",
          password2: "",
          firstName: "",
          lastName: "",
          phoneNo: "",
          address: "",
          country: "",
          state: "",
          businessName: "",
          isFleet: false,
          role: "",
          referralID: "",
          acceptedTerms: false,
          rememberMe: false,
          otp: "",
          placeID: "",
        }))
        requestOtp()
        break;
    
      default:
        setDisplayType("sign-up-00")
        setOtpPurpose("Sign Up")
        break;
    }

  }

  const fetchSupportedCountries = async () => {
    try {
      const res = await get_supported_countries()
      const response = await res.json()
      
      if (res.ok) {
        console.log('response', response);
        
        setSupportedCountries(response.data.supported_countries)
      }
    } catch (error) {
      
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

    if (name === 'country') {
      let x = supportedCountries.find((item: any, index: number) => {
        return item.title === value
      })
      console.log('x', x);
      
    }
    
    

    if (name === 'password') {
      evalPassword(value)
    }

    if (name === 'address') {
      setInputValues({
        ...inputValues,
        placeID: ''
      });
    }
  }

  const selectPlace = (item: any) => {
    setInputValues(prevState => ({
      ...prevState,
      address: item.description,
      placeID: item.place_id
    }));
    setPlaceSuggestions([])
    setShowplaceSuggestions(false)
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSelectUserType = () => {
    if (userType === '') {
      setErr((prevState: any) => ({
        ...prevState,
        userType: "Please select the user type that suits you",
      }))
      return 
    } else if (userType === 'client') {
      // go to google docs
      window.open("https://forms.gle/Q6k82neeFV2L7Xn16", '_blank');
      // setDisplayType('sign-up-01')
    } else if (userType === 'freelancer') {
      setDisplayType('sign-up-01')
    }
  }

  const handleRegister = async () => {
    
    try {
      loadingModalRef.current.showLoadingModal()
      if (!Boolean(inputValues.acceptedTerms)) {
        loadingModalRef.current?. hideLoadingModal()
        toast.warn("Kindly review and accept our terms of service before signing up.", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return
      }

      if (!evalPassword(inputValues.password, true)) {
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      if (!Boolean(inputValues.placeID.length)) {
        if (!Boolean(inputValues.address.length)) {
          loadingModalRef.current?. hideLoadingModal()
          toast.warn("Kindly provide a valid address", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return
        }
        if (!Boolean(inputValues.country.length)) {
          loadingModalRef.current?. hideLoadingModal()
          toast.warn("Kindly select a location", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return
        }
        if (!Boolean(inputValues.region.length)) {
          loadingModalRef.current?. hideLoadingModal()
          toast.warn("Kindly provide valid city within your selected country", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return
        }
      }

      
      var params = {
        first_name: inputValues.firstName,
        last_name: inputValues.lastName,
        email: inputValues.email,
        phone_number: phone,
        password: inputValues.password,
        address: inputValues.address,
        place_id: inputValues.placeID,
        referral_id: inputValues.referralID,
        is_freelancer: userType === 'freelancer' ? true : false,
        is_client: userType === "client" ? true : false,
      }

      if (!Boolean(inputValues.placeID.length)) {
        // @ts-ignore
        params["country"] = inputValues.country
        // @ts-ignore
        params["region"] = inputValues.region
        // params["region"] = inputValues.city
      }
      
      setErr({
        email: "",
        password: "",
        region: '',
        password2: "",
        lastName: "",
        phoneNo: "",
        country: "",
        city: "",
        businessName: "",
        isFleet: false,
        role: "",
        referralID: "",
        acceptedTerms: false,
        otp: "",
        default: "",
        firstName: "",
        userType: '',
        address: '',
      })

      const res = await register_user(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        toast.success(response.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setDisplayType("verify-email")
        // setTempID(response.data.temp_id)
        loadingModalRef.current?. hideLoadingModal()
        navigate(`/verify-otp?temp_id=${response.data.temp_id}&purpose=signup&email=${inputValues.email}`)
        // setOtpPurpose("Sign Up")
      } else {
        if (Boolean(response?.field.length)) {
          switch (response.field) {
            case "first_name":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  firstName: response.message,
                }
              })
              setDisplayType('sign-up-01')
              break;
            case "last_name":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  lastName:  response.message,
                }
              })
              setDisplayType('sign-up-01')
              break;
            case "email":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  email:  response.message,
                }
              })
              
              break;
            case "phone_number":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  phoneNo:  response.message,
                }
              })
              setDisplayType('sign-up-01')
              break;
            case "role":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  role:  response.message,
                }
              })
              break;
            case "business_name":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  businessName: response.message,
                }
              })
              break;
            case "password":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  password:  response.message,
                }
              })
              break;
            case "referral_code":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  referralID:  response.message,
                }
              })
              break;
            case "country":
              setErr((prevState: any) => {
                return {
                  ...prevState,
                  country:  response.message,
                }
              })
              setDisplayType('sign-up-01')
              break;
            default:
              break
          }
          loadingModalRef.current?. hideLoadingModal()
          return
        }
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.detail, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleLogIn = async () => {
    try {
      loadingModalRef.current.showLoadingModal()

      if(!Boolean(inputValues.email.length) || !validateEmail(inputValues.email)) {
        toast.error("Kindly enter a valid email address", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      if(!Boolean(inputValues.password.length)) {
        toast.error("Kindly enter a valid password", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        email: inputValues.email,
        password: inputValues.password
      }

      const res = await log_in(params)
      const response = await res.json()
      
      if (res.ok) {
        
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        
        
        if (response.data.is_email_verified) {
          
          dispatch(logIn({
            isAuthenticated: true,
            user_details: response.data.user_data?.user_details,
            user_profile: response.data?.user_data?.user_profile || {},
          }))   
          dispatch(setActiveWallet(response.data.user_data.wallet))     
          saveSecureData("token", response.data.token || "")  
          // Send them back to the page they tried to visit when they were
          // redirected to the login page. Use { replace: true } so we don't create
          // another entry in the history stack for the login page.  This means that
          // when they get to the protected page and click the back button, they
          // won't end up back on the login page, which is also really nice for the
          // user experience.
          loadingModalRef.current?. hideLoadingModal()
          navigate(to, { replace: true });
        } else {
          loadingModalRef.current?. hideLoadingModal()
          navigate(`/verify-otp?temp_id=${response.data.temp_id}&purpose=login&email=${inputValues.email}`)
        }
      } else {
        
        if (response?.invalid_params) {
          if (Boolean(response?.invalid_params?.length)) {
            for (let invalid_param of response?.invalid_params) {
              
              switch (invalid_param?.name) {
                case "email":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      email: invalid_param?.reason,
                    }
                  })
                  break;
                case "password":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      password: invalid_param?.reason,
                    }
                  })
                  break;
                default:
                  break
              }
            }
          }
          loadingModalRef.current?. hideLoadingModal()
          return
        }
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleForgotPassword = async () => {
    try {
      loadingModalRef.current.showLoadingModal()
      var params = {
        email: inputValues.email,
      }

      const res = await forgot_password(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {        
        loadingModalRef.current?. hideLoadingModal()
        navigate(`/reset-password?temp_id=${response.data.temp_id}&purpose=password-reset&email=${inputValues.email}`)
      } else {
        
        if (response?.invalid_params) {
          if (Boolean(response?.invalid_params?.length)) {
            for (let invalid_param of response?.invalid_params) {
              
              switch (invalid_param?.name) {
                case "email":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      email: invalid_param?.reason,
                    }
                  })
                  break;
                default:
                  break
              }
            }
          }
          loadingModalRef.current?. hideLoadingModal()
          return
        }
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleVerifyOtp = async () => {
    
    try {
      loadingModalRef.current.showLoadingModal()
      var params = {
        temp_id: tempID,
        code: otp,
        purpose: otpPurpose,
        platform: 'web',
        channel: "email",
      }

      
      const res = await verify_email_or_phone(params)
      const response = await res.json()
      if (res.ok) {
        
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(logIn({
          isAuthenticated: true,
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))   
        dispatch(setActiveWallet(response.data.user_data.wallet))  
        saveSecureData("token", response.data.token || "")       
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        loadingModalRef.current?. hideLoadingModal()
        navigate(to, { replace: true });
      } else {
        
        loadingModalRef.current?. hideLoadingModal()
        
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      
      switch (displayType) {
        case "login":
          await handleLogIn()
          setIsSubmitting(false)
          break;
        case "sign-up-02":
          await handleRegister()
          setIsSubmitting(false)
          break;
        case "verify-otp":
          await handleVerifyOtp()
          break;
        
        case "forgot-password":
          await handleForgotPassword()
          break;
        
        case "reset-password":
          await validateNewPasswords()
          break;
        
        case "reset-password-2":
          await changePassword()
          break;
        
        default:
          toast('🦄 Wow so easy?', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsSubmitting(false)
          break;
      }
      
    } catch (error) {
      toast.error("Oops... Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleSubmitFirstHalf = async (event: any) => {
    event.preventDefault();
    try {
      if (!isValid) {
        return
      }
      setDisplayType("sign-up-02")
      await sleep(1000)
      setShowPassword(false)
      
      
    } catch (error) {
      toast.error("Oops... Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  const handleAddress = async (event: any) => {    
    // event.preventDefault(); 
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;


    const address = value

    setInputValues((prevState: any) => ({
      ...prevState,
      address: address,
    }))

    // Temporarily disable Google place search until we get a new GCP account and place api keys

    // if (address.length >= 3 ) {
    //   try {
    //     setInputValues((prevState: any) => ({
    //       ...prevState,
    //       street: address,
    //     }))
    //     const session_token = Boolean(placeSession.length) ? placeSession : uuidv4();
    //     // @ts-ignore
    //     setPlaceSession(session_token)
              
    //     var params = {
    //       input_text: address,
    //       session_token,
    //       language: 'en',
    //       use_default: true
    //     }
  
  
    //     const res = await address_auto_complete(params)
    //     if (res.ok) {
    //       const response = await res.json()
    //       if (Boolean(response.places.length)) {
    //         setPlaceSuggestions(response.places)
    //         setShowplaceSuggestions(true)
    //       } else {
    //         setPlaceSuggestions([])
    //         setShowplaceSuggestions(false)
    //       }
          
    //     } else {
    //       setPlaceSuggestions([])
    //       setShowplaceSuggestions(false)
    //     }
        
  
    //   } catch (error) {
    //     setPlaceSuggestions([])
    //     setShowplaceSuggestions(false)
    //     // captureException({
    //     //   file: 'KYCModal',
    //     //   methodName: 'handleAddress',
    //     //   exception: error
    //     // })
    //   }
    // } else {
    //   setShowplaceSuggestions(false)
    // }
    setErr((prevState: any) => ({
      ...prevState,
      address: "",
    }))
    
    
    
  }

  const evalPassword = (password: string, showErrorMsg = false) => {
    if (hasLowerCase(password)) {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        lowerCase: true
      }))
    } else {
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        lowerCase: false
      }))

      if (showErrorMsg) {
        toast.warn("Your password must include at least one lowercase letter", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (hasUpperCase(password)) {     
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        upperCase: true
      }))
    } else {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        upperCase: false
      }))
      if (showErrorMsg) {
        toast.warn("Your password must include at least one uppercase letter", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (containsNumber(password)) {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        digit: true
      }))
    } else {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        digit: false
      }))
      if (showErrorMsg) {
        toast.warn("Your password must include at least one number", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (containsSpecialChars(password)) {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        specialChar: true
      }))
    } else {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        specialChar: false
      }))

      if (showErrorMsg) {
        toast.warn("Your password must include at least one special character", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (password.length >= 8) {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        length: true
      }))
    } else {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        length: false
      }))

      if (showErrorMsg) {
        toast.warn("Your password must contains at least 8 letters", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    return true
  }

  const requestOtp = async () => {
    try {
      let temp_id = searchParams.get("temp_id")
      var params = {
        temp_id: temp_id || "",
      }

      const res = await request_otp(params)
      const response = await res.json()

      if (res.ok) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
    } catch (error) {
      
    }
  }

  const checkIfUserAddressValidity = async () => {
    try {
      await sleep(2000)
      if (!Boolean(inputValues.placeID.length)) {
        if (!showplaceSuggestions) {
          setShowCountrySelector(true)
        } else {
          setShowCountrySelector(false)
        }       
      } else {
        setShowCountrySelector(false)
      }
    } catch (error) {
      
    }
  }

  const validateNewPasswords = () => {
    try {
      var isValid = true
      loadingModalRef.current.showLoadingModal()

      if (!evalPassword(inputValues.password, true)) {
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      if (inputValues.password !== inputValues.password2) {
        toast.warn("Passwords does not match", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      loadingModalRef.current?. hideLoadingModal()
      setDisplayType("reset-password-2")




    } catch (error) {
      
    }
  }

  const changePassword =async () => {
    try {
      loadingModalRef.current.showLoadingModal()
      if(otp.length < 6) {
        toast.warn("Incomplete OTP code", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params = {
        temp_id: tempID,
        code: otp,
        password: inputValues.password,
      }
      var res = await verify_change_password(params)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
        navigate("/login")
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
        toast.error(getErrorMessage(error), {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
  }
  

  return (
    <div className="anyjob-auth-page-container">
      {
        displayType === 'sign-up-00' && (
          <div className="anyjob-auth-page-select-user-type-container">
            <div className="anyjob-auth-page-header">
              <NavHashLink
                  exact to="/" 
                  smooth                     
                >
                <img 
                  src={Logo}
                  alt="Anyjob"
                  className="anyjob-auth-page-header-logo"
                />
              </NavHashLink>
            </div>
            <div className="anyjob-auth-page-select-user-type-heading">
              <h2 className="anyjob-auth-page-heading">
                Select Account Type
              </h2>
              <p className="anyjob-auth-page-desc">
                Join us as a Freelancer or Client
              </p>
            </div>
            <div className={"anyjob-auth-page-user-type-selections"}>
              <div 
                className={userType === 'client' ? "anyjob-auth-page-user-type-selected-item" : "anyjob-auth-page-user-type-selections-item"}
                onClick={() => setUserType('client')}
              >
                <img 
                  src={ClientIcon}
                  alt="Client"
                />
                <p className="anyjob-auth-page-user-type-selections-item-title">
                  Client
                </p>
                <p className="anyjob-auth-page-user-type-selections-item-desc">
                  I want to list a task
                </p>
              </div>
              <div 
                className={userType === 'freelancer' ? "anyjob-auth-page-user-type-selected-item" : "anyjob-auth-page-user-type-selections-item"}
                onClick={() => setUserType('freelancer')}
              >
                <img 
                  src={FreelancerIcon}
                  alt="Freelancer"
                />
                <p className="anyjob-auth-page-user-type-selections-item-title">
                Freelancer
                </p>
                <p className="anyjob-auth-page-user-type-selections-item-desc">
                  I want to earn
                </p>
              </div>
            </div>
            {
              Boolean(err.userType.length) && (
                <p className="anyjob-auth-page-err-msg">
                  {err.userType}                   
                </p>
              )
            }
            
            <div className="anyjob-auth-page-ctas">
              <div 
                className={userType === "" ? "anyjob-auth-page-disabled-submit-btn" : "anyjob-auth-page-submit-btn"}
                onClick={handleSelectUserType}
              >
                Next
              </div>
              <div className="anyjob-auth-page-alt-action">
                <p>Already have an account?</p>
                <NavHashLink 
                  exact to="/login" 
                  smooth
                  className="auth-header-btn"   
                >
                  Login
                </NavHashLink>
              </div>
            </div>
            
          </div>
        )
      }
      {
        displayType === 'sign-up-01' && (
          <div className="anyjob-auth-page-content-container">
            <div className="anyjob-auth-page-content-image-sec">
              <img 
                src={HeroImage}
                alt=""
              />
            </div>
            <div className="anyjob-auth-page-content-form-sec">
              <div className="anyjob-auth-page-header">
                <div className="anyjob-auth-page-header-go-back-btn" onClick={() => setDisplayType('sign-up-00')}>
                  <img 
                    src={ArrowLeft}
                    alt="Go Back"
                  />
                  <p>
                    Back
                  </p>
                </div>
                <NavHashLink
                    exact to="/" 
                    smooth                     
                  >
                  <img 
                    src={Logo}
                    alt="Anyjob"
                    className="anyjob-auth-page-header-logo"
                  />
                </NavHashLink>
              </div>
              <div className="anyjob-auth-page-select-user-type-heading">
                <h2 className="anyjob-auth-page-heading">
                  Create Account
                </h2>
                <p className="anyjob-auth-page-desc">
                 Create an account to join for a good start
                </p>
              </div>
              <div className="anyjob-auth-page-pagination-container">
                <div className={"anyjob-auth-page-active-page-count"}>
                  1
                </div>
                <div className="anyjob-auth-page-pagination-divider"/>
                <div className={"anyjob-auth-page-inactive-page-count"}>
                  2
                </div>
              </div>
              <form id="form1" className="anyjob-auth-page-form" autoComplete="on" onSubmit={handleSubmitFirstHalf}>
                <span className="anyjob-auth-page-inputs-group">
                  <span className="anyjob-auth-page-input-span">
                    <label className="anyjob-auth-page-input-label">First Name</label>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.firstName}
                      required
                      name="firstName"
                      className="anyjob-auth-page-input"
                    />
                    {
                      Boolean(err.firstName.length) && (
                        <p className="anyjob-auth-input-err-msg">
                          {err.firstName}
                        </p>
                      )
                    }
                  </span>
                  <span className="anyjob-auth-page-inputs-group-divider"/>
                  <span className="anyjob-auth-page-input-span">
                    <label className="anyjob-auth-page-input-label">Last Name</label>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.lastName}
                      required
                      name="lastName"
                      className="anyjob-auth-page-input"
                    />
                    {
                      Boolean(err.lastName.length) && (
                        <p className="anyjob-auth-input-err-msg">
                          {err.lastName}
                        </p>
                      )
                    }
                  </span>
                </span>
                <span className="anyjob-auth-page-input-span1">
                  <span className="anyjob-auth-page-input-span">
                    <label className="anyjob-auth-page-input-label">Address</label>
                    <input 
                      onChange={handleAddress} 
                      type="text"
                      autoComplete='off'
                      value={inputValues.address}
                      required
                      className="anyjob-auth-page-input"
                      onBlur={checkIfUserAddressValidity}
                    />
                    {
                      Boolean(err.address.length) && (
                        <p className="anyjob-auth-input-err-msg">
                          {err.address}
                        </p>
                      )
                    }
                  </span>
                  {
                    showplaceSuggestions ? (
                      <div className="anyjob-auth-page-street-suggestions">
                        <div className="anyjob-auth-page-street-suggestions-contents">                              
                          { 
                            placeSuggestions.map((item: any, index: number) => {
                              return (
                                <div 
                                  className='anyjob-auth-page-suggestion-item'
                                  onClick={() => selectPlace(item)}
                                  key={index}
                                >
                                  <img 
                                    src={Pin} 
                                    alt="Right arrow" 
                                    style={{
                                      width: 24,
                                    }}
                                  />
                                  <p>{item.description}</p>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    ) : null
                  }
                </span>
                {
                  showCountrySelector && (
                    <span className="anyjob-auth-page-country-n-city-section">
                      <span className="anyjob-auth-page-country-n-city-section-item">
                        <label className="anyjob-auth-page-input-label">Country</label>
                        <select
                          onChange={handleInputChange} 
                          value={inputValues.country}
                          required
                          name="country"
                          className="anyjob-auth-page-input"
                        >
                          <option value="" disabled>-- Select County -- </option>
                          {
                            supportedCountries.map((item: any, index: number) => {
                              return (
                                <option value={item.title} key={index}>{item.title}</option>
                              )
                            })
                          }
                        </select>
                      </span>
                      <span className="anyjob-auth-page-country-n-city-section-item">
                        <label className="anyjob-auth-page-input-label">Region</label>
                        <select
                          onChange={handleInputChange} 
                          value={inputValues.region}
                          required
                          name="region"
                          className="anyjob-auth-page-input"
                        >
                          <option value="" disabled>-- Select Region -- </option>
                          {
                            supportedCountries.find((item: any, index: number) => {
                              return item.title === inputValues.country
                            })?.regions.map((item: any, index: number) => {
                              return (
                                <option value={item.title} key={index}>{item.title}</option>
                              )
                            })
                          }
                        </select>
                      </span>
                      {/* <span className="anyjob-auth-page-country-n-city-section-item">
                        <label className="anyjob-auth-page-input-label">City</label>
                        <input 
                          onChange={handleInputChange} 
                          type="text"
                          value={inputValues.city}
                          required
                          name="city"
                          autoComplete='off'
                          className="anyjob-auth-page-input"
                        />
                      </span> */}
                    </span>
                  )
                }
                
                {/* <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">Phone Number</label>
                  <input 
                    onChange={handleInputChange} 
                    type="tel"
                    value={inputValues.phoneNo}
                    required
                    name="phoneNo"
                    className="anyjob-auth-page-input"
                  />
                  {
                    Boolean(err.phoneNo.length) && (
                      <p className="anyjob-auth-input-err-msg">
                        {err.phoneNo}
                      </p>
                    )
                  }
                </span> */}
                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">Phone Number</label>
                  <PhoneInput
                    defaultCountry="ng"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    className="anyjob-auth-page-phone-input"
                    required
                    // countrySelectorStyleProps={{
                    //   className: "anyjob-auth-page-input"
                      
                    // }}
                    inputStyle={{
                      height: 58,
                      width: 460,
                      borderTopRightRadius: 30,
                      borderBottom: 30,
                      borderWidth: 0,
                    }}
                    style={{
                      height: 60,
                      background: 'transparent'
                    }}
                    inputClassName="anyjob-auth-page-phone-inner-input"
                    countrySelectorStyleProps={{
                      buttonClassName: "anyjob-auth-page-phone-input-selector-btn",
                      buttonStyle: {
                        height: 58,
                        width: 100,
                        borderTopLeftRadius: 50,
                        borderBottomLeftRadius: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRightWidth: 1,
                      },
                      flagStyle: {
                        flex: 1,
                      }
                    }}
                  />
                  {
                    Boolean(err.phoneNo.length) && (
                      <p className="anyjob-auth-input-err-msg">
                        {err.phoneNo}
                      </p>
                    )
                  }
                  {(!isValid && Boolean(phone.length)) && <div style={{ color: 'red' }}>Kindly enter a valid phone number</div>}
                  
                </span>

                <div className="anyjob-auth-page-ctas">
                  <input 
                    className={"anyjob-auth-page-submit-btn"}
                    // onClick={handleSubmitFirstHalf}
                    type="submit"
                    value="Continue"
                  />
                  <div className="anyjob-auth-page-alt-action">
                    <p>Already have an account?</p>
                    <NavHashLink 
                      exact to="/login" 
                      smooth
                      className="auth-header-btn"   
                    >
                      Login
                    </NavHashLink>
                  </div>
                </div>
              </form>

            </div>            
          </div>
        )
      }
      {
        displayType === 'sign-up-02' && (
          <div className="anyjob-auth-page-content-container">
            <div className="anyjob-auth-page-content-image-sec">
              <img 
                src={HeroImage}
                alt=""
              />
            </div>
            <div className="anyjob-auth-page-content-form-sec">
              <div className="anyjob-auth-page-header">
                <div className="anyjob-auth-page-header-go-back-btn" onClick={() => setDisplayType('sign-up-01')}>
                  <img 
                    src={ArrowLeft}
                    alt="Go Back"
                  />
                  <p>
                    Back
                  </p>
                </div>
                <NavHashLink
                    exact to="/" 
                    smooth                     
                  >
                  <img 
                    src={Logo}
                    alt="Anyjob"
                    className="anyjob-auth-page-header-logo"
                  />
                </NavHashLink>
              </div>
              <div className="anyjob-auth-page-select-user-type-heading">
                <h2 className="anyjob-auth-page-heading">
                  Create Account
                </h2>
                <p className="anyjob-auth-page-desc">
                 Create an account to join for a good start
                </p>
              </div>
              <div className="anyjob-auth-page-pagination-container">
                <div className={"anyjob-auth-page-inactive-page-count"}>                
                  1
                </div>
                <div className="anyjob-auth-page-pagination-divider"/>
                <div className={"anyjob-auth-page-active-page-count"}>
                  2
                </div>
              </div>
              <form id="form2" className="anyjob-auth-page-form" autoComplete="off" onSubmit={handleSubmit}>                
                
                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">Email</label>
                  <input 
                    onChange={handleInputChange} 
                    type="email"
                    value={inputValues.email}
                    required
                    name="email"
                    className="anyjob-auth-page-input"
                  />
                  {
                    Boolean(err.email.length) && (
                      <p className="anyjob-auth-input-err-msg">
                        {err.email}
                      </p>
                    )
                  }
                </span>                
                

                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">Password</label>
                  <span className={isPasswordFocus ? "anyjob-auth-page-password-input-focus" : "anyjob-auth-page-password-input"}>
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.password}
                      required
                      name="password"
                      autoComplete='off'
                      onBlur={() => setIsPasswordFocus(false)}
                      onFocus={() => setIsPasswordFocus(true)}
                      // className="anyjob-auth-page-input"
                    />
                    <p onClick={handleTogglePassword} className="anyjob-auth-page-toggle-password">
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>                                    
                </span>
                <div className="anyjob-auth-page-password-validations-group">
                  <div className={passwordChecks.length ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>8 characters</p>
                  </div>
                  <div className={passwordChecks.lowerCase ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 lowercase letter</p>
                  </div>
                  <div className={passwordChecks.upperCase ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 uppercase letter</p>
                  </div>

                  <div className={passwordChecks.specialChar ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 symbol</p>
                  </div>
                  <div className={passwordChecks.digit ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 number</p>
                  </div>
                </div>
                {
                  Boolean(err.email.length) && (
                    <p className="anyjob-auth-input-err-msg">
                      {err.email}
                    </p>
                  )
                }
                <span className="anyjob-auth-terms-input-group">
                  <input 
                    type="checkbox"
                    onChange={handleInputChange} 
                    // @ts-ignore
                    value={inputValues.acceptedTerms}
                    name="acceptedTerms"
                  />
                  <p>
                    I agree to AnyJob’s <NavHashLink exact to={"/terms"} >Terms & Privacy Policy</NavHashLink>
                  </p>
                </span>

                <div className="anyjob-auth-page-ctas">
                  <div 
                    className={"anyjob-auth-page-submit-btn"}
                    onClick={handleSubmit}
                  >
                    Submit
                  </div>
                  <div className="anyjob-auth-page-alt-action">
                    <p>Already have an account?</p>
                    <NavHashLink 
                      exact to="/login" 
                      smooth
                      className="auth-header-btn"   
                    >
                      Login
                    </NavHashLink>
                  </div>
                </div>
              </form>

            </div>            
          </div>
        )
      }
      {
        displayType === 'login' ? (
          <div className="anyjob-auth-page-content-container">
            <div className="anyjob-auth-page-content-image-sec">
              <img 
                src={HeroImage}
                alt=""
              />
            </div>
            <div className="anyjob-auth-page-content-form-sec">
              <div className="anyjob-auth-page-header">
                {/* <div className="anyjob-auth-page-header-go-back-btn" onClick={() => setDisplayType('sign-up-01')}>
                  <img 
                    src={ArrowLeft}
                    alt="Go Back"
                  />
                  <p>
                    Back
                  </p>
                </div> */}
                <NavHashLink
                    exact to="/" 
                    smooth                     
                  >
                  <img 
                    src={Logo}
                    alt="Anyjob"
                    className="anyjob-auth-page-header-logo"
                  />
                </NavHashLink>
              </div>
              <div className="anyjob-auth-page-select-user-type-heading">
                <h2 className="anyjob-auth-page-heading">
                  Login
                </h2>
                <p className="anyjob-auth-page-desc">
                Welcome back! So glad to have you back again.
                </p>
              </div>
              <form id="form3" className="anyjob-auth-page-form" autoComplete="off" onSubmit={handleSubmit}>                
                
                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">Email</label>
                  <input 
                    onChange={handleInputChange} 
                    type="email"
                    value={inputValues.email}
                    required
                    name="email"
                    className="anyjob-auth-page-input"
                  />
                  {
                    Boolean(err.email.length) && (
                      <p className="anyjob-auth-input-err-msg">
                        {err.email}
                      </p>
                    )
                  }
                </span>

                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">Password</label>
                  <span className={isPasswordFocus ? "anyjob-auth-page-password-input-focus" : "anyjob-auth-page-password-input"}>
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.password}
                      required
                      name="password"
                      autoComplete='off'
                      onBlur={() => setIsPasswordFocus(false)}
                      onFocus={() => setIsPasswordFocus(true)}
                      // className="anyjob-auth-page-input"
                    />
                    <p onClick={handleTogglePassword} className="anyjob-auth-page-toggle-password">
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>                                    
                </span>
                {
                  Boolean(err.password.length) && (
                    <p className="anyjob-auth-input-err-msg">
                      {err.password}
                    </p>
                  )
                }

                <span className="anyjob-auth-page-remember-me-n-forgot-password">
                  <span className="anyjob-auth-remember-me">
                    <input 
                      type="checkbox"
                      onChange={handleInputChange} 
                      // @ts-ignore
                      value={inputValues.rememberMe}
                      name="rememberMe"
                    />
                    <p>
                      Remember Me
                    </p>
                  </span>
                  <NavHashLink exact to={"/forgot-password"} >Forgot Password</NavHashLink>
                </span>

                <div className="anyjob-auth-page-ctas">
                  <div 
                    className={"anyjob-auth-page-submit-btn"}
                    onClick={handleSubmit}
                  >
                    Submit
                  </div>
                  <div className="anyjob-auth-page-alt-action">
                    <p>Already have an account?</p>
                    <NavHashLink 
                      exact to="/signup" 
                      smooth
                      className="auth-header-btn"   
                    >
                      Create Account
                    </NavHashLink>
                  </div>
                </div>
              </form>

            </div>            
          </div>
        ) : null
      }

      {
        displayType === 'forgot-password' ? (
          <div className="anyjob-auth-page-content-container">
            <div className="anyjob-auth-page-content-image-sec">
              <img 
                src={HeroImage}
                alt=""
              />
            </div>
            <div className="anyjob-auth-page-content-form-sec">
              <div className="anyjob-auth-page-header">
                {/* <div className="anyjob-auth-page-header-go-back-btn" onClick={() => setDisplayType('sign-up-01')}>
                  <img 
                    src={ArrowLeft}
                    alt="Go Back"
                  />
                  <p>
                    Back
                  </p>
                </div> */}
                <NavHashLink
                    exact to="/" 
                    smooth                     
                  >
                  <img 
                    src={Logo}
                    alt="Anyjob"
                    className="anyjob-auth-page-header-logo"
                  />
                </NavHashLink>
              </div>
              <div className="anyjob-auth-page-select-user-type-heading">
                <h2 className="anyjob-auth-page-heading">
                  Forgot Password?
                </h2>
                <p className="anyjob-auth-page-desc">
                No worries we got you, please provide your registered email address
                </p>
              </div>
              <form id="form3" className="anyjob-auth-page-form" autoComplete="off" onSubmit={handleSubmit}>                
                
                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">Email</label>
                  <input 
                    onChange={handleInputChange} 
                    type="email"
                    value={inputValues.email}
                    required
                    name="email"
                    className="anyjob-auth-page-input"
                  />
                  {
                    Boolean(err.email.length) && (
                      <p className="anyjob-auth-input-err-msg">
                        {err.email}
                      </p>
                    )
                  }
                </span>



                <div className="anyjob-auth-page-ctas">
                  <div 
                    className={"anyjob-auth-page-submit-btn"}
                    onClick={handleSubmit}
                  >
                    Submit
                  </div>
                  <div className="anyjob-auth-page-alt-action">
                    <p>Remembered your password?</p>
                    <NavHashLink 
                      exact to="/login" 
                      smooth
                      className="auth-header-btn"   
                    >
                      Login
                    </NavHashLink>
                  </div>
                </div>
              </form>

            </div>            
          </div>
        ) : null
      }

      {
        displayType === 'verify-otp' ? (
          <div className="anyjob-auth-page-content-container">
            <div className="anyjob-auth-page-content-image-sec">
              <img 
                src={HeroImage}
                alt=""
              />
            </div>
            <div className="anyjob-auth-page-content-form-sec">
              <div className="anyjob-auth-page-header">
                {/* <div className="anyjob-auth-page-header-go-back-btn" onClick={() => setDisplayType('sign-up-01')}>
                  <img 
                    src={ArrowLeft}
                    alt="Go Back"
                  />
                  <p>
                    Back
                  </p>
                </div> */}
                <NavHashLink
                    exact to="/" 
                    smooth                     
                  >
                  <img 
                    src={Logo}
                    alt="Anyjob"
                    className="anyjob-auth-page-header-logo"
                  />
                </NavHashLink>
              </div>
              <img 
                src={FlyingMail}
                alt="Email"
                className="anyjob-auth-page-email-icon"
              />
              <div className="anyjob-auth-page-select-user-type-heading">
                <h2 className="anyjob-auth-page-heading-1">
                  A code has been sent to your mail 
                </h2>
                <span className="anyjob-auth-verify-otp-email">
                  <img 
                    src={EmailIcon}
                    alt="Email"

                  /> 
                  <p>{inputValues.email}</p>
                </span>
                <p className="anyjob-auth-page-desc">
                Kindly enter the 6-digit code sent to your email.
                </p>
              </div>
              <form id="form3" className="anyjob-auth-page-form" autoComplete="off" onSubmit={handleSubmit}>                
                
                <span className="anyjob-auth-page-input-span">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle="anyjob-auth-otp-input"
                  containerStyle="anyjob-auth-otp-input-container"
                  shouldAutoFocus
                  skipDefaultStyles
                />
                </span>



                <div className="anyjob-auth-page-ctas">
                  <div 
                    className={"anyjob-auth-page-submit-btn"}
                    onClick={handleSubmit}
                  >
                    Submit
                  </div>
                  <div className="anyjob-auth-page-alt-action">
                    <p>Didn’t  receive any code? </p>
                    <a 
                      href="#"
                      onClick={requestOtp}
                      className="auth-header-btn"   
                    >
                      Resend OTP
                    </a>
                  </div>
                </div>
              </form>

            </div>            
          </div>
        ) : null
      }

      {
        displayType === 'reset-password' ? (
          <div className="anyjob-auth-page-content-container">
            <div className="anyjob-auth-page-content-image-sec">
              <img 
                src={HeroImage}
                alt=""
              />
            </div>
            <div className="anyjob-auth-page-content-form-sec">
              <div className="anyjob-auth-page-header">
                {/* <div className="anyjob-auth-page-header-go-back-btn" onClick={() => setDisplayType('sign-up-01')}>
                  <img 
                    src={ArrowLeft}
                    alt="Go Back"
                  />
                  <p>
                    Back
                  </p>
                </div> */}
                <NavHashLink
                    exact to="/" 
                    smooth                     
                  >
                  <img 
                    src={Logo}
                    alt="Anyjob"
                    className="anyjob-auth-page-header-logo"
                  />
                </NavHashLink>
              </div>
              {/* <img 
                src={FlyingMail}
                alt="Email"
                className="anyjob-auth-page-email-icon"
              /> */}
              <div className="anyjob-auth-page-select-user-type-heading">
                <h2 className="anyjob-auth-page-heading">
                  Choose Your New Password
                </h2>
                <p className="anyjob-auth-page-desc">
                Kindly enter new password and enter it again just for confirmation
                </p>
              </div>
              <form id="form3" className="anyjob-auth-page-form" autoComplete="off" onSubmit={handleSubmit}>                
                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">New Password</label>
                  <span className={isPasswordFocus ? "anyjob-auth-page-password-input-focus" : "anyjob-auth-page-password-input"}>
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.password}
                      required
                      name="password"
                      autoComplete='off'
                      onBlur={() => setIsPasswordFocus(false)}
                      onFocus={() => setIsPasswordFocus(true)}
                      // className="anyjob-auth-page-input"
                    />
                    <p onClick={handleTogglePassword} className="anyjob-auth-page-toggle-password">
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>                                    
                </span>
                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">New Password Again</label>
                  <span className={isPassword2Focus ? "anyjob-auth-page-password-2-input-focus" : "anyjob-auth-page-password-2-input"}>
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.password2}
                      required
                      name="password2"
                      autoComplete='off'
                      onBlur={() => setIsPassword2Focus(false)}
                      onFocus={() => setIsPassword2Focus(true)}
                      // className="anyjob-auth-page-input"
                    />
                    <p onClick={handleTogglePassword} className="anyjob-auth-page-toggle-password">
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>                                    
                </span>



                <div className="anyjob-auth-page-ctas">
                  <div 
                    className={"anyjob-auth-page-submit-btn"}
                    onClick={handleSubmit}
                  >
                    Next
                  </div>
                  <div className="anyjob-auth-page-alt-action">
                    <p>Didn’t  receive any code? </p>
                    <a 
                      href="#"
                      onClick={requestOtp}
                      className="auth-header-btn"   
                    >
                      Resend OTP
                    </a>
                  </div>
                </div>
              </form>

            </div>            
          </div>
        ) : null
      }

      {
        displayType === 'reset-password-2' ? (
          <div className="anyjob-auth-page-content-container">
            <div className="anyjob-auth-page-content-image-sec">
              <img 
                src={HeroImage}
                alt=""
              />
            </div>
            <div className="anyjob-auth-page-content-form-sec">
              <div className="anyjob-auth-page-header">
                {/* <div className="anyjob-auth-page-header-go-back-btn" onClick={() => setDisplayType('sign-up-01')}>
                  <img 
                    src={ArrowLeft}
                    alt="Go Back"
                  />
                  <p>
                    Back
                  </p>
                </div> */}
                <NavHashLink
                    exact to="/" 
                    smooth                     
                  >
                  <img 
                    src={Logo}
                    alt="Anyjob"
                    className="anyjob-auth-page-header-logo"
                  />
                </NavHashLink>
              </div>
              <img 
                src={FlyingMail}
                alt="Email"
                className="anyjob-auth-page-email-icon"
              />
              <div className="anyjob-auth-page-select-user-type-heading">
                <h2 className="anyjob-auth-page-heading-1">
                  A code has been sent to your mail 
                </h2>
                <span className="anyjob-auth-verify-otp-email">
                  <img 
                    src={EmailIcon}
                    alt="Email"

                  /> 
                  <p>{inputValues.email}</p>
                </span>
                <p className="anyjob-auth-page-desc">
                Kindly enter new password and the 6-digit code sent to your email.
                </p>
              </div>
              <form id="form3" className="anyjob-auth-page-form" autoComplete="off" onSubmit={handleSubmit}>                                
                <span className="anyjob-auth-page-input-span">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle="anyjob-auth-otp-input"
                  containerStyle="anyjob-auth-otp-input-container"
                  shouldAutoFocus
                  skipDefaultStyles
                />
                </span>



                <div className="anyjob-auth-page-ctas">
                  <div 
                    className={"anyjob-auth-page-submit-btn"}
                    onClick={handleSubmit}
                  >
                    Submit
                  </div>
                  <div className="anyjob-auth-page-alt-action">
                    <p>Didn’t  receive any code? </p>
                    <a 
                      href="#"
                      onClick={requestOtp}
                      className="auth-header-btn"   
                    >
                      Resend OTP
                    </a>
                  </div>
                </div>
              </form>

            </div>            
          </div>
        ) : null
      }
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}

export default AuthPage