import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_STATE, } from '../default_state'
import {
  updateUserData,
} from './utils'
import { removeData } from '../../utils/handleStorage'

export const userDataSlice = createSlice({
  name: 'user_data',
  initialState: DEFAULT_STATE.userData,
  reducers: {    
    reset: (state, action) => {
      state = DEFAULT_STATE.userData
    },
    storeUserData: (state, action) => {
      if (action.payload.user_details.is_freelancer) {
        state.appMode = 'freelancer'
      } else if (action.payload.user_details.is_client) {
        state.appMode = 'client'
      } else {
        state.appMode = 'freelancer'
      }
      state.user_details = action.payload.user_details;
      state.user_profile = action.payload.user_profile;
    },
    logIn: (state, action) => {
      state.user_details = action.payload.user_details;
      state.isAuthenticated = true
      state.user_profile = action.payload.user_profile;
    },
    logOut: (state, action) => {
      removeData("token")
      state.isAuthenticated = false
    },
    switchAppMode: (state, action) => {
      state.appMode = action.payload.mode
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserData.fulfilled, (state, action) => {        
        state.user_details = action.payload.user_details;
        // state.user_profile = action.payload.user_profile;
      })
      
      // .addCase(updateAppFeatures.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // });
      // .addCase(updateAppFeatures.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
  },
})

export const { 
  reset,  
  storeUserData,
  logIn,
  logOut,
  switchAppMode,
 } = userDataSlice.actions

export {
  updateUserData
}

export const userDataReducer = userDataSlice.reducer