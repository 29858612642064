import { DefaultStateType } from "./types";

export const migrations = {
  // 1: (state: DefaultStateType) => {
  //   return {
  //     ...state,
  //     Bible: { // nested state if you have
  //       ...state.Bible,
  //       currentLanguage: {
  //         iso_code: 'en',
  //         title: "English",
  //         id: 0,
  //       },
  //       downloadedVersions: [],
  //       currentBibleVersion: null,
  //     },
  //   };
  // },

  // 2: (state: DefaultStateType) => {
  //   // Migration logic from version 2 to 3
  //   // return {
  //   //   ...state,
  //   //   nestedObject: {
  //   //     // Update the structure of the nested object
  //   //     ...state.nestedObject,
  //   //     newField: defaultValue,
  //   //   },
  //   // };
  // },
};