import React, {useEffect, useRef} from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate, 
  useLocation, 
  useNavigate
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {store, persistor} from './redux/store'

import './App.css'

import HomePage from "./pages/HomePage/HomePage";
import AuthPage from "./pages/AuthPage/AuthPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import Jobs from "./pages/Jobs/Jobs";
import Onboarding from "./pages/Onboarding/Onboarding";
import FAQPage from "./pages/FAQPage/FAQPage";


import { DefaultStateType } from './redux/types';
import SideBar from './components/Drawer/SideBar';
import Drawer from './components/Drawer/Drawer';
import JobsDetails from './pages/JobsDetails/JobsDetails';
import SubmitProposal from './pages/SubmitProposal/SubmitProposal';
import SubmitTask from './pages/SubmitTask/SubmitTask';
import TaskDetails from './pages/SubmitTask/TaskDetails';
import Proposals from './pages/Proposals/Proposals';
import Tasks from './pages/Tasks/Tasks';
import Profile from './pages/Profile/Profile';
import { get_user_details } from './api/auth';
import { logOut, storeUserData } from './redux/userData';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import { setActiveWallet } from './redux/wallet';
import Wallet from './pages/Wallet/Wallet';
import TransactionsPage from './pages/TransactionsPage/TransactionsPage';
import WithdrawalPage from './pages/WithdrawPage/WithdrawalPage';
import MessagePage from './pages/MessagePage/MessagePage';
import NotificationPage from './pages/NotificationPage/NotificationPage';
import TermsPage from './pages/TermsPage/TermsPage';
import PrivacyPolicyPage from './pages/TermsPage/PrivacyPolicyPage';


function RequireAuth({ children, showSideBar }: {children: any, showSideBar: boolean}) {
  const userData = useSelector((state: DefaultStateType) => state.userData);
  
  let location = useLocation();

  
  if (!userData.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (showSideBar) {
    return (
      <div className='anyjob-required-auth-container'>
        {/* <SideBar /> */}
        <Drawer />
        {(children)}
      </div>
    )
  } else {
    return children;
  }
  
  
}

function AppNav () {
  const userData = useSelector((state: DefaultStateType) => state.userData);  
  const dispatch = useDispatch()

  useEffect(() => {
    updateUserData()
  }, [])

  const updateUserData = async () => {
    try {
      const res = await get_user_details()
      if (res.ok) {
        const response = await res.json()
        // console.log('response.data.user_data.wallet', response.data.user_data.wallet);
        
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))
        dispatch(setActiveWallet(response.data.user_data.wallet))
      } else {
        const response = await res.json()
        console.log('response', response);
        dispatch(logOut({}))
        
      }
    } catch (error) {
      
    }
  }

  return (
    <div >      
      <Router>          
        <Routes>            
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/partners" element={<Partner />} /> */}
          {/* <Route path="/about" element={<AboutUs />} /> */}
          {/* <Route path="/terms" element={<Terms />} /> */}
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/login" element={<AuthPage action="login" />} />
          <Route path="/signup" element={<AuthPage action="signup" />} />
          <Route path="/verify-otp" element={<AuthPage action="verify-otp" />} />
          <Route path="/forgot-password" element={<AuthPage action="forgot-password" />} />
          <Route path="/reset-password" element={<AuthPage action="reset-password" />} />
          <Route 
            path="/contact-us" 
            element={
                <ContactUsPage/>
            } 
          /> 
          {
            userData.isAuthenticated ? (
              <Route 
                path="/profile/:profile_id" 
                element={
                  <RequireAuth showSideBar={true}>
                    <Profile/>
                  </RequireAuth>
                } 
              />   
            ) : (
              <Route 
                path="/profile/:profile_id" 
                element={
                    <Profile/>
                } 
              />   
            )
          }
          <Route 
            path="/dashboard" 
            element={
              <RequireAuth showSideBar>
                <DashboardPage/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/freelancer-onboarding" 
            element={
              <RequireAuth showSideBar={false}>
                <Onboarding/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/jobs" 
            element={
              <RequireAuth showSideBar={true}>
                <Jobs/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/job-details/:job" 
            element={
              <RequireAuth showSideBar={true}>
                <JobsDetails/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/submit-proposal/:job" 
            element={
              <RequireAuth showSideBar={true}>
                <SubmitProposal/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/submit-task/:job" 
            element={
              <RequireAuth showSideBar={true}>
                <SubmitTask/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/task-details/:task" 
            element={
              <RequireAuth showSideBar={true}>
                <TaskDetails/>
              </RequireAuth>
            } 
          />   
          
          <Route 
            path="/tasks" 
            element={
              <RequireAuth showSideBar={true}>
                <Tasks/>
              </RequireAuth>
            } 
          />   
          
          <Route 
            path="/proposals" 
            element={
              <RequireAuth showSideBar={true}>
                <Proposals/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/wallet" 
            element={
              <RequireAuth showSideBar={true}>
                <Wallet/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/transactions" 
            element={
              <RequireAuth showSideBar={true}>
                <TransactionsPage/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/withdrawal" 
            element={
              <RequireAuth showSideBar={true}>
                <WithdrawalPage/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/notifications" 
            element={
              <RequireAuth showSideBar={true}>
                <NotificationPage/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/message" 
            element={
              <RequireAuth showSideBar={true}>
                <MessagePage/>
              </RequireAuth>
            } 
          />   
            

          {/* change the "/" path here to page not found screen */}
          <Route path="*" element={<Navigate to={userData.isAuthenticated ? "/jobs" : "/"} />} />      
        </Routes>
        
      </Router>   
      
    </div>
  )
}

function App() {

  return (
    <div className="anyjob-site-container">
      <ToastContainer />
      <Provider store={store}>
        <PersistGate 
          loading={null}
          persistor={persistor}
        >
          <AppNav />
        </PersistGate>
      </Provider>
      
    </div>
  );
}

export default App;