import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, } from 'redux-persist'
import storage from "redux-persist/lib/storage";

import reducer from './reducer'
import { migrations } from './migrations';


const persistConfig = {
  key: 'root',
  storage: storage,
  version: 1,
  migrations: migrations,
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)