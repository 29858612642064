import { DefaultStateType } from "./types"

export const DEFAULT_STATE: DefaultStateType = {  
  userData: {
    isAuthenticated: false,
    appMode: 'freelancer',
    user_details: {
      date_created: '',
      email: '',
      title: '',
      first_name: '',
      last_name: '',
      phone_number: '',      
      id: '',
      public_unique_id: '',
      is_active: true,
      is_admin: false,
      is_freelancer: true,
      is_client: false,
      is_staff: false,
      has_pin: false,
      
    },
    user_profile: {
      average_ratings: 0,
      profile_picture: '',   
      profile_picture_url: '',   

      last_login: '',
      dob: '' ,
      is_active: true,
      is_email_verified: false,
      is_phone_number_verified: false,
      is_work_experience_provided: false,
      is_education_history_provided: false,
      is_kyc_verified: false,
      is_bank_acct_provided: false,
      is_initial_user_onboarding_completed: false,
      login_barred: false,
      kyc_status: '',
      short_bio: '',
      long_bio: '',
      street_address: '',
      locale: '',
      lang_code: '',
      socials_instagram: '',
      socials_twitter: '',
      socials_website: '',
    },
  },
  wallet: {
    activeWallet: null,
  },
}
// navigation_state
// default_state