import { BASE_URL } from "../env"
import { apiFetch, authApiFetch } from "./utils"

export type RegisterParams = {
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  role: string,
  business_name: string,
  country: string,
  referral_code: string,
  password: string,
  region?: string,
}


export const  register_client = async (params: RegisterParams) => {
  try {
    const url = BASE_URL + '/api/account/register_client'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export type RegisterUserParams = {
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  address: string,
  place_id: string,
  password: string,
  is_freelancer: boolean,
}

export const  register_user = async (params: RegisterUserParams) => {
  try {
    const url = BASE_URL + '/api/account/register_user'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export type LoginParams = {
  email: string,
  password: string,
}

export const web_login = async (params: LoginParams) => {
  try {
    const url = BASE_URL + '/api/account/web_login'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const log_in = async (params: LoginParams) => {
  try {
    const url = BASE_URL + '/api/account/log_in'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const forgot_password = async (params: {email: string}) => {
  try {
    const url = BASE_URL + '/api/account/forgot_password'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const request_otp = async (params: {temp_id: string}) => {
  try {
    const url = BASE_URL + '/api/account/request_otp'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export type VerifyEmailPhoneParams = {
  code: string,
  temp_id: string,
  purpose?: string,
  platform?: string,
  channel: string,
}

export const verify_email_or_phone = async (params: VerifyEmailPhoneParams) => {
  try {
    const url = BASE_URL + '/api/account/verify_email_or_phone'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const get_user_details = async () => {
  try {
    const url = BASE_URL + '/api/account/get_user_details'
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
  })
  } catch (error) {
    throw error
  }
}

export const address_auto_complete = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/address_auto_complete'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({params})
  })
  } catch (error) {
    throw error
  }
}

export const update_personal_info = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/update_personal_info'
    return await authApiFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const update_cover_image = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/update_cover_image'
    return await authApiFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const get_supported_kyc_ids = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/get_supported_kyc_ids'
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const initiate_otp_challenge = async (shouldSendOTP=0) => {
  try {
    const url = BASE_URL + `/api/account/initiate_otp_challenge?should_send_otp=${shouldSendOTP}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
  })
  } catch (error) {
    throw error
  }
}


export const verify_change_password = async (params: any) => {
  try {
    var url = BASE_URL + `/api/account/verify_change_password`
      
      var response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params,
        })
      })
      return response
  } catch (error) {
    throw error
  }
}

export const set_pin = async (params: any) => {
  try {
    var url = BASE_URL + `/api/account/set_pin`
      
      var response = await authApiFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params,
        })
      })
      return response
  } catch (error) {
    throw error
  }
}

export const initiate_phone_verification = async (params: any) => {
  try {
    var url = BASE_URL + `/api/account/initiate_phone_verification`
      
      var response = await authApiFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params,
        })
      })
      return response
  } catch (error) {
    throw error
  }
}

// export const log_in = async (params) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       var url = BASEURL + 'api/account/log_in'
//       var net_state = await NetInfo.fetch()
//       console.log('net_state', net_state)

//       var response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Accept: 'application/json',
//         },
//         body: JSON.stringify({
//           ...params,
//           device_id: await getUniqueId(),
//           device_name: await getDeviceName(),
//           ip_address: net_state.details.ipAddress,
//         })
//       })
//       return resolve(response)
//     } catch (error) {
//       return reject(error)
//     }
//   })
// }

// export const register_user = async (params) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       var url = BASEURL + 'api/account/register_user'
//       var net_state = await NetInfo.fetch()
//       console.log('net_state', net_state)

//       var response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Accept: 'application/json',
//         },
//         body: JSON.stringify({
//           ...params,
//           device_id: await getUniqueId(),
//           device_name: await getDeviceName(),
//           ip_address: net_state.details.ipAddress,
//         })
//       })
//       return resolve(response)
//     } catch (error) {
//       return reject(error)
//     }
//   })
// }

// export const request_otp = async (params) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       var url = BASEURL + `api/account/request_otp`
//       var net_state = await NetInfo.fetch()

//       var response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Accept: 'application/json',
//         },
//         body: JSON.stringify({
//           ...params,
//           device_id: await getUniqueId(),
//           device_name: await getDeviceName(),
//           ip_address: net_state.details.ipAddress,
//         })
//       })
//       return resolve(response)
//     } catch (error) {
//       return reject(error)
//     }
//   })
// }

// export const verify_otp = async (params) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       var url = BASEURL + `api/account/verify_otp`
//       var net_state = await NetInfo.fetch()
      
//       var response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Accept: 'application/json',
//         },
//         body: JSON.stringify({
//           ...params,
//           device_id: await getUniqueId(),
//           device_name: await getDeviceName(),
//           ip_address: net_state.details.ipAddress,
//         })
//       })
//       return resolve(response)
//     } catch (error) {
//       return reject(error)
//     }
//   })
// }

