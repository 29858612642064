import React, { useRef, useState } from 'react'
import { CaretUp, CaretDown } from "phosphor-react";

import './FAQPage.css'

export default function FAQItem (props: any){

  return (
    <div 
      className={props.isActive ? 'anyjob-active-faq-item' : 'anyjob-faq-item'}
      onClick={() =>  props.setActiveItemIndex(props.index)}  
    >
      <div className='anyjob-faq-item-content'>
        <p className='anyjob-faq-item-title'>{props.item.title}</p>
        {
          props.isActive ? (
            <p className='anyjob-faq-item-desc'>{props.item.content}</p>
          ) : null
        }
      </div>
      <div className={props.isActive ? 'anyjob-faq-item-close-btn' : 'anyjob-faq-item-open-btn'}>
        {
          props.isActive ? (
            <CaretUp className="anyjob-faq-item-close-btn-icon" />
          ) : (
            <CaretDown className="anyjob-faq-item-open-btn-icon" />
          )
        }
      </div>
    </div>
  )
}