import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { toast } from "react-toastify";

import './SubmitProposal.css'
import Header from '../../components/Header/Header'
import { fetch_jobs, get_job, send_proposal } from '../../api/jobs'
import LoadingModal from '../../components/LoadingModal'
import { SubmitProposalProps } from './types';
import JobWidget from '../../components/JobWidget/JobWidget';
import getErrorMessage from '../../utils/getErrorMessage';

export default function SubmitProposal(props: SubmitProposalProps) {
  const [data, setData] = useState<any>(null)
  const [coverLetter, setCoverLetter] = useState('')
  const [attachment, setAttachment] = useState<any>(null)
  const [attachmentBase64Str, setAttachmentBase64Str] = useState<any>(null)
  const [attachmentFile, setAttachmentFile] = useState<any>(null)

  const [errMsg, setErrMsg] = useState({
    coverLetter: '',
    attachment: '',
  })

  const { job } = useParams();

  const loadingModalRef = useRef<any>()
  const fileInputRef = useRef<any>(null);

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchJob()
    loadingModalRef.current?. hideLoadingModal()
  }

  const fetchJob = async () => {    
    try {
      
      const res = await get_job(job || '')
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        setData(response.data.job)    
      } else {

      }
    } catch (error) {
      
    }
  }

  const handleInputChange = async(event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setCoverLetter(target.value);

  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    setAttachmentFile(file)

    console.log('file', file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setAttachment(reader.result);
        // @ts-ignore
        const base64String = reader.result.split(',')[1];
        // console.log('reader.result', reader.result);
        // console.log('base64String', base64String);
        
        setAttachmentBase64Str(base64String)
      };

      reader.readAsDataURL(file);

      // console.log(' reader.readAsDataURL(file);',  reader.readAsDataURL(file););
      
    }
    
    
    // Perform additional checks here if needed

    // Now you can upload the selected image using Axios or any other method
    // if (file) {
    //   const formData = new FormData();
    //   formData.append('image', file);
    // }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    var isValid = true
    if (!Boolean(coverLetter)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        coverLetter: "Please provide an image of yourself to be used as your profile picture"
      }))
    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params = {
        cover_letter: coverLetter,
        unique_id: data?.unique_id
      }

      if (Boolean(attachmentFile)) {
        // @ts-ignore
        params['attachment'] = {
          base64: attachment,
          fileName: attachmentFile.name,
          size: attachmentFile.size,
          type: attachmentFile.type,
        }
      }

      console.log('params', params);

      const res = await send_proposal(params)
      const response = await res.json()
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
  
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div className='anyjob-proposal-page-container'>
      <Header title={"Proposals"} />
      <div className='anyjob-submit-proposal-page-container'>
        <div className='anyjob-submit-proposal-page-content-hero'>
          <p className='anyjob-submit-proposal-title'>
            Submit Proposal
          </p>
        </div>
        <div className='anyjob-submit-proposal-page-contents'>
          <form onSubmit={handleSubmit}>
            <div className='anyjob-submit-proposal-form'>
              <div className='anyjob-submit-proposal-input-group'>
                <p  className='anyjob-submit-proposal-input-group-label'>
                  Cover Letter
                </p>
                <textarea 
                  value={coverLetter}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {/* <div className='anyjob-submit-proposal-input-group'>
                <p className='anyjob-submit-proposal-input-group-label'>
                  Attachments
                </p>
                <div 
                  onDrop={handleButtonClick}
                  onDragOver={(e) => e.preventDefault()}
                  onClick={handleButtonClick}
                  className='anyjob-submit-proposal-file-upload'
                >
                  <p className='anyjob-submit-proposal-file-upload-label'>Drag or  <span className='anyjob-submit-proposal-file-upload-label-highlight'>upload</span> project files</p>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    multiple={false}
                    required={false}
                  />
                </div>
                <p className='anyjob-submit-proposal-file-upload-note'>
                  Include work samples or other documents to support your application.
                </p>
              </div> */}
            </div>
            <input 
              className='anyjob-submit-proposal-submit'
              type='submit'
            />
          </form>

          <JobWidget 
            job={data}
          />
        </div>
      </div>
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}