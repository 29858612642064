import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';

import './EditProfileDetails.css'

import { User, X } from "phosphor-react";
import LoadingModal from "../LoadingModal";
import getErrorMessage from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DefaultStateType } from "../../redux/types";
import { edit_user_profile } from "../../api/profile";
import { get_user_details } from "../../api/auth";
import { storeUserData } from "../../redux/userData";
import { BASE_URL } from "../../env";
import { setActiveWallet } from "../../redux/wallet";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};

type EditProfileDetailsProps = {

}

const EditProfileDetails = forwardRef((props: EditProfileDetailsProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [userProfilePicture, setUserProfilePicture] = useState<any>(null)
  const [userProfilePictureBase64Str, setUserProfilePictureBase64Str] = useState<any>(null)
  const [userProfilePictureFile, setUserProfilePictureFile] = useState<any>(null)
  const [inputValues, setInputValues] = useState({
    first_name: '',
    last_name: '',
    short_bio: '',
    long_bio: '',
    socials_instagram: '',
    socials_twitter: '',
    socials_website: '',
  })
  const [errMsg, setErrMsg] = useState({
    first_name: '',
    last_name: '',
    short_bio: '',
    long_bio: '',
    socials_instagram: '',
    socials_twitter: '',
    socials_website: '',
    userProfilePicture: '',
  })
  const [onClose, setOnClose] = useState<((status: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);

  const loadingModalRef = useRef<any>()
  const fileInputRef = useRef<any>(null);

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const dispatch = useDispatch()


  const show = useCallback(( cb?: (status: 'success' | 'failed' | 'canceled') => void, education: any=null) => {
    
    if (cb) {
      setOnClose(() => cb);
    }

    setInputValues((prevState: any) => ({
      ...prevState,
      first_name: userData.user_details?.first_name,
      last_name: userData.user_details?.last_name,
      short_bio: userData.user_profile.short_bio,
      long_bio: userData.user_profile.long_bio,
      socials_instagram: userData.user_profile.socials_instagram,
      socials_twitter: userData.user_profile.socials_twitter,
      socials_website: userData.user_profile.socials_website,
    }))
    
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      first_name: '',
      last_name: '',
      short_bio: '',
      long_bio: '',
      socials_instagram: '',
      socials_twitter: '',
      socials_website: '',
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    setUserProfilePictureFile(file)

    console.log('file', file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setUserProfilePicture(reader.result);
        // @ts-ignore
        const base64String = reader.result.split(',')[1];
        // console.log('reader.result', reader.result);
        // console.log('base64String', base64String);
        
        setUserProfilePictureBase64Str(base64String)
      };

      reader.readAsDataURL(file);

      // console.log(' reader.readAsDataURL(file);',  reader.readAsDataURL(file););
      
    }
    
    
    // Perform additional checks here if needed

    // Now you can upload the selected image using Axios or any other method
    // if (file) {
    //   const formData = new FormData();
    //   formData.append('image', file);
    // }
  };

  const validateForm = () => {
    var isValid = true
    // if (!Boolean(userProfilePictureFile)) {
    //   isValid = false
    //   setErrMsg((prevState: any) => ({
    //     ...prevState,
    //     userProfilePicture: "Please provide an image of yourself to be used as your profile picture"
    //   }))
    // }
    if (!Boolean(inputValues.long_bio.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        long_bio: "Please provide a bit more details about your work experience, cool projects you've completed, and your area of expertise."
      }))
    }
    if (!Boolean(inputValues.short_bio.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        short_bio: "Please share a short description about yourself"
      }))
    }
    if (!Boolean(inputValues.first_name.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        first_name: "Please provide us with your first name"
      }))
    }
    if (!Boolean(inputValues.last_name.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        last_name: "Please provide us with your last name"
      }))
    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params: any = {
        ...inputValues,
        profile_picture: null,
      }

      if (Boolean(userProfilePicture)) {
        params['profile_picture'] = {
          base64: userProfilePicture,
          fileName: userProfilePictureFile.name,
          size: userProfilePictureFile.size,
          type: userProfilePictureFile.type,
        }
      }

      const res = await edit_user_profile(params)
      if (res.ok){
        await updateUserData()
        // @ts-ignore
        onClose('success')
        loadingModalRef.current?. hideLoadingModal()
        setIsModalOpen(false)
        setInputValues((prevState: any) => ({
          ...prevState,
          first_name: '',
          last_name: '',
          short_bio: '',
          long_bio: '',
          socials_instagram: '',
          socials_twitter: '',
          socials_website: '',
        }))
      } else {
        const response = await res.json()

        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const updateUserData = async () => {
    try {
      const res = await get_user_details()
      if (res.ok) {
        const response = await res.json()
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))
        dispatch(setActiveWallet(response.data.user_data.wallet))
      } else {
        // const response = await res.json()
        // console.log('response', response);
        // dispatch(logOut({}))
        
      }
    } catch (error) {
      
    }
  }


  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Add Education"
      contentElement={() => {
        return (
          <div className="anyjob-edit-profile-details-modal">
            <div className="anyjob-edit-profile-details-modal-contents">
              <div className="anyjob-edit-profile-details-modal-contents-header">
                <p>Edit Profile</p>
                <div className="anyjob-edit-profile-details-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-edit-profile-details-modal-close-icon"
                  />
                </div>
                
              </div>

              <div className="anyjob-edit-profile-details-modal-contents-sub-form-section">

              

                <div 
                  className="anyjob-edit-profile-details-upload-profile"
                  onDrop={handleButtonClick}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {
                    (Boolean(userProfilePicture) || Boolean(userData.user_profile.profile_picture?.length)) ? (
                      <div
                        onClick={handleButtonClick}
                      >
                        <img
                          src={Boolean(userProfilePicture) ? userProfilePicture : `${BASE_URL}${userData.user_profile.profile_picture}`}
                          alt="Selected"
                          className="anyjob-edit-profile-details-profile-picture"
                        />
                      </div>
                    ) : (
                      <div 
                        className="anyjob-edit-profile-details-profile-icon-container"
                        onClick={handleButtonClick}
                      >
                        <User
                          className="anyjob-edit-profile-details-profile-icon"
                        />
                      </div>
                    )
                  }
                  <div          
                  >
                    <button 
                      onClick={handleButtonClick}
                      className="anyjob-edit-profile-details-upload-profile-picture-btn"
                    >Upload Profile Image</button>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      multiple={false}
                    />
                  </div>
                </div>
                <div className="anyjob-edit-profile-details-err-container">
                  {
                      errMsg.userProfilePicture && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.userProfilePicture}
                        </p>
                      )
                    }
                </div>

                <form className="anyjob-edit-profile-details-modal-form" onSubmit={handleSubmit}>
                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">First Name *</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.first_name}
                      required
                      name="first_name"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    />
                    {
                      errMsg.first_name && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.first_name}
                        </p>
                      )
                    }
                  </span>
                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">Last Name *</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.last_name}
                      required
                      name="last_name"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    />
                    {
                      errMsg.last_name && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.last_name}
                        </p>
                      )
                    }
                  </span>
                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">Headline *</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.short_bio}
                      required
                      name="short_bio"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    />
                    {
                      errMsg.short_bio && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.short_bio}
                        </p>
                      )
                    }
                  </span>

                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">About Me * </p>
                    <textarea 
                      onChange={handleInputChange} 
                      value={inputValues.long_bio}
                      required
                      name="long_bio"
                      className="anyjob-edit-profile-details-modal-form-textarea"
                    />
                    {
                      errMsg.long_bio && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.long_bio}
                        </p>
                      )
                    }
                  </span>


                  {/* <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">Instagram</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.socials_instagram}
                      // required
                      name="socials_instagram"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    />
                    {
                      errMsg.socials_instagram && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.socials_instagram}
                        </p>
                      )
                    }
                  </span>

                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">X (formerly Twitter)</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.socials_twitter}
                      // required
                      name="socials_twitter"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    />
                    {
                      errMsg.socials_twitter && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.socials_twitter}
                        </p>
                      )
                    }
                  </span>

                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">Your website</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.socials_website}
                      // required
                      name="socials_website"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    />
                    {
                      errMsg.socials_website && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.socials_website}
                        </p>
                      )
                    }
                  </span> */}

                  <span className="anyjob-edit-profile-details-modal-form-actions">
                    <div className="anyjob-edit-profile-details-modal-form-actions-cancel" onClick={hide}>
                      Close
                    </div>
                    <input 
                      value="Save"
                      type="submit"
                      className="anyjob-edit-profile-details-modal-form-actions-save"
                    />
                  </span>
                </form>
              </div>

            </div>
          </div>
        )
      }}
    >
      <></>
    </Modal>
    <LoadingModal ref={loadingModalRef} />
    </>
  )
})

export default EditProfileDetails