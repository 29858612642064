import { BASE_URL } from "../env"
import { apiFetch, authApiFetch } from "./utils"


export const create_work_history = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/create_work_history'
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const update_work_history = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/update_work_history'
    return await authApiFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}


export const create_education_history = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/create_education_history'
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const update_education_history = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/update_education_history'
    return await authApiFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const add_multiple_user_skill = async (params: any) => {
  try {
    const url = BASE_URL + '/api/account/add_multiple_user_skill'
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const get_user_payment_details = async (user_id="") => {
  try {
    const url = BASE_URL + `/api/account/get_user_payment_details?user_id=${user_id}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_freelancer_dashboard_data = async (user_id: any, mode: string) => {
  try {
    const url = BASE_URL + `/api/account/get_freelancer_dashboard_data?user_id=${user_id}&mode=${mode}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_user_work_history = async () => {
  try {
    const url = BASE_URL + '/api/account/get_user_work_history'
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_user_education_history = async () => {
  try {
    const url = BASE_URL + '/api/account/get_user_education_history'
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const search_skills = async (query: string) => {
  try {
    const url = BASE_URL + `/api/account/search_skills?query_str=${query}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_banks_via_paystack = async (country: string, is_live: boolean) => {
  try {
    const url = BASE_URL + `/api/account/get_banks_via_paystack?country=${country}&is_live=${is_live}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_banks_via_maplerad = async (country: string, is_live: boolean) => {
  try {
    const url = BASE_URL + `/api/account/get_banks_via_maplerad?country=${country}&is_live=${is_live}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const resolve_nigeria_bank_via_paystack = async (params: {bank_code: string, is_live: boolean, account_number: string}) => {
  try {
    const url = BASE_URL + `/api/account/resolve_nigeria_bank_via_paystack`
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const resolve_nigeria_bank_via_maplerad = async (params: {bank_code: string, is_live: boolean, account_number: string}) => {
  try {
    const url = BASE_URL + `/api/account/resolve_nigeria_bank_via_maplerad`
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const save_account_details = async (params: any) => {
  try {
    const url = BASE_URL + `/api/account/save_account_details`
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const get_supported_kyc_ids = async () => {
  try {
    const url = BASE_URL + `/api/account/get_supported_kyc_ids`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_user_profile_details = async (unique_id: string) => {
  try {
    const url = BASE_URL + `/api/account/get_user_profile_details?unique_id=${unique_id}`
    return await apiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const skip_onboarding = async () => {
  try {
    const url = BASE_URL + `/api/account/skip_onboarding`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const completed_onboarding = async () => {
  try {
    const url = BASE_URL + `/api/account/completed_onboarding`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const process_kyc_v1 = async (params: any) => {
  try {
    const url = BASE_URL + `/api/account/process_kyc_v1`
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const update_user_skills = async (params: any) => {
  try {
    const url = BASE_URL + `/api/account/update_user_skills`
    return await authApiFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const edit_user_profile = async (params: any) => {
  try {
    const url = BASE_URL + `/api/account/edit_user_profile`
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const change_password = async (params: any) => {
  try {
    const url = BASE_URL + `/api/account/change_password`
    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}
