import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';
import { X } from "phosphor-react";
// @ts-ignore
import numeral from 'numeral'

import './CameraComponent.css'

import PauseIcon from '../../assets/svg/Stop_fill.svg'
import PlayIcon from '../../assets/svg/Play_fill.svg'

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
    position: 'absolute'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};

type CameraComponentProps = {
}

export type cameraCallBackParamType = {
  status: 'success' | 'failed' | 'canceled',
  capturedImage?: any,
  recordedVideo?: any,
}


const CameraComponent = forwardRef((props: CameraComponentProps, ref) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [stream, setStream] = useState<any>(null);
  const [videoSrc, setVideoSrc] = useState<any>(null);
  const [recording, setRecording] = useState(false);
  const [recordedTime, setRecordedTime] = useState(0);
  const [timerId, setTimerId] = useState<any>(null);
  const [paused, setPaused] = useState(false);
  const [action, setAction] = useState<'image-capture' | 'video-record'>('image-capture')
  const [onClose, setOnClose] = useState<((params: cameraCallBackParamType) => void) | undefined>(undefined);

  const videoRef = useRef<any>();
  const mediaRecorderRef = useRef<any>();
  const chunksRef = useRef<any>([]);
  const startTimeRef = useRef<any>(0);

  const show = useCallback((action: 'image-capture' | 'video-record', cb?: (params: cameraCallBackParamType) => void) => {
    // console.log('cb', cb);
    setAction(action)
    if (cb) {
      setOnClose(() => cb);
    }
    
    setIsModalOpen(true)
    startCamera()
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    stopCamera()
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const startCamera = () => {
    navigator?.mediaDevices?.getUserMedia({ video: true, audio: true  })
      .then(mediaStream => {
        videoRef.current.srcObject = mediaStream;
        setStream(mediaStream);
      })
      .catch(err => console.error('Error accessing camera:', err));
  };

  const stopCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track: any) => track.stop());
      videoRef.current.srcObject = null;
      setStream(null);
      if (timerId) {
        clearInterval(timerId);
      }      
      setTimerId(null);
      setRecording(false);
      setRecordedTime(0);
      setPaused(false);
    }
  };

  const takePicture = () => {
    const canvas: any = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/png');
    // setImageSrc(dataUrl);
    setIsModalOpen(false)
    stopCamera()
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose({
        status: 'success',
        capturedImage: dataUrl
      })
    } 
  };

  const startRecording = () => {
    if (videoRef.current.srcObject) {
      mediaRecorderRef.current = new MediaRecorder(videoRef.current.srcObject);
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
      mediaRecorderRef.current.onstart = handleStartRecording;
      mediaRecorderRef.current.onstop = handleStopRecording;
      if (!paused) {
        startTimeRef.current = Date.now();
        setRecordedTime(0);
      }
      mediaRecorderRef.current.start();
      setRecording(true);
      setTimerId(setInterval(() => {
        // setRecordedTime(prevTime => prevTime + 1);
        if (!paused) {
          const currentTime = Math.floor((Date.now() - startTimeRef.current) / 1000);
          setRecordedTime(currentTime);
        }
      }, 1000));
    }
  };

  const pauseRecording = () => {
    if (recording) {
      mediaRecorderRef.current.pause();
      setPaused(true);
      clearInterval(timerId);
    }
  };

  const resumeRecording = () => {
    if (paused) {
      mediaRecorderRef.current.resume();
      startTimeRef.current = Date.now() - (recordedTime * 1000); // Adjust start time based on recorded time
      setPaused(false);
      setTimerId(setInterval(() => {
        const currentTime = Math.floor((Date.now() - startTimeRef.current) / 1000);
        setRecordedTime(currentTime);
      }, 1000));
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleDataAvailable = (event: any) => {
    chunksRef.current.push(event.data);
  };

  const handleStop = () => {
    const blob = new Blob(chunksRef.current, { type: 'video/webm' });
    const url = URL.createObjectURL(blob);
    setVideoSrc(url);
    chunksRef.current = [];
  };

  const handleStartRecording = () => {
    setRecordedTime(0);
  };

  const handleStopRecording = () => {
    clearInterval(timerId);
    setTimerId(null);
    const blob = new Blob(chunksRef.current, { type: 'video/webm' });
    const url = URL.createObjectURL(blob);
    setVideoSrc(url);
    chunksRef.current = [];
    setRecording(false);
    setPaused(false);
    setIsModalOpen(false)
    stopCamera()
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose({
        status: 'success',
        recordedVideo: url
      })
    } 
  };


  return (
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Camera"
      contentElement={() => {
        return (
          <div className="anyjob-camera-modal">
            <div className="anyjob-camera-modal-contents">
              <div className="anyjob-camera-modal-contents-header">
                <p>{action === 'image-capture' ? "Capture Image" : "Record Video"}</p>
                <div className="anyjob-camera-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-camera-modal-close-icon"
                  />
                </div>
                
              </div>
              <div>
                
                <video 
                  ref={videoRef} 
                  autoPlay 
                  muted
                  className="anyjob-camera-view" 
                />
                {/* {imageSrc && <img src={imageSrc} alt="Captured" />} */}
                {
                  action === 'image-capture' && (
                    <div className="anyjob-camera-image-capture-container">
                      <button onClick={takePicture} className="anyjob-camera-capture-image-btn">

                      </button>
                    </div>
                  )
                }
                {
                  action === 'video-record' && (
                    <>
                      {
                        !Boolean(recording) && (
                          <div className="anyjob-camera-video-record-container">
                            <button onClick={startRecording} className="anyjob-camera-capture-image-btn">

                            </button>
                          </div>
                        )
                      }
                      {
                        Boolean(recording) && (
                          <div className="anyjob-camera-video-record-container">
                            <div className="anyjob-camera-video-recording-action"/>
                            <div className="anyjob-camera-video-recording-action">
                              {
                                paused ? (
                                  <img 
                                    alt="Resume Recording"
                                    src={PlayIcon}
                                    className="anyjob-camera-video-pause-play-recording"
                                    onClick={resumeRecording}
                                  />
                                ) : (
                                  <img 
                                    alt="Pause Recording"
                                    src={PauseIcon}
                                    className="anyjob-camera-video-pause-play-recording"
                                    onClick={pauseRecording}
                                  />
                                )
                              }
                              <p className="anyjob-camera-video-time">
                                {numeral(recordedTime).format("00:00:00")}
                              </p>
                            </div>

                            <button onClick={stopRecording} className="anyjob-camera-video-pause-btn">
                              <div />
                            </button>
                          </div>
                        )
                      }
                    </>
                    
                  )
                }
                
                
              </div>
            </div>
          </div>
        )
      }}
    >
      <></>
    </Modal>
    
  );
})

export default CameraComponent;
