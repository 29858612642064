import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import './Wallet.css'

import Header from '../../components/Header/Header'
import LoadingModal from '../../components/LoadingModal'
import getErrorMessage from '../../utils/getErrorMessage';
import { DefaultStateType } from '../../redux/types';
import { formatCurrency } from '../../utils/strings';

import WithdrawIcon from '../../assets/svg/card-send.svg'
import { get_latest_transactions } from '../../api/wallet';
import { setActiveWallet } from '../../redux/wallet';
import moment from 'moment';
import ErrorComp from '../../components/ErrorComp/ErrorComp';

export default function Wallet(props: any) {
  const [transactions, setTransactions] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [dataFetchFailed, setDataFetchFailed] = useState(false)
  const [err, setErr] = useState({
    title: '',
    msg: '',
  })

  const wallet = useSelector((state: DefaultStateType) => state.wallet);
  const dispatch = useDispatch()

  const loadingModalRef = useRef<any>()

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchData()
    loadingModalRef.current?. hideLoadingModal()
  }


  const fetchData = async () => {
    try {
      setIsLoading(true)
      const res = await get_latest_transactions()
      const response = await res.json()
      if (res.ok) {
        dispatch(setActiveWallet(response.data.wallet))     
        setTransactions(response.data.latest_transactions)

        setIsLoading(false)
        setDataFetchFailed(false)
        setErr({
          title: '',
          msg: '',
        })
      } else {
        setErr({
          title: response.title,
          msg: response.detail,
        })
      }
    } catch (error) {
      setDataFetchFailed(true)
      setIsLoading(false)
      setErr({
        title: "Oops! Something went wrong.",
        msg: getErrorMessage(error),
      })
    }
  }

  return (
    <div className='anyjob-wallet-container'>
      <Header title={"Wallet"} />

      {
        !dataFetchFailed ? (
          <div className='anyjob-wallet-page-container'>
            <div className='anyjob-wallet-card'>
              <div className='anyjob-wallet-card-content'>
                <p className='anyjob-wallet-card-content-label'>
                  Your Total Balance
                </p>
                <p className='anyjob-wallet-card-content-value'>
                  {wallet.activeWallet?.currency?.symbol} {formatCurrency(wallet.activeWallet?.current_balance)}
                </p>
              </div>
              <div className='anyjob-wallet-card-invisible-divider' />
                <Link className='anyjob-wallet-card-btn' to="/withdrawal">
                  <img 
                    src={WithdrawIcon}
                    alt='Anyjob Withdraw Icon'
                  />
                  <p>
                    Withdraw
                  </p>
                </Link>
            </div>
            <div className='anyjob-wallet-page-divider' />

            <div className='anyjob-wallet-page-transactions-container'>
              <div className='anyjob-wallet-page-transactions-container-header'>
                <p className='anyjob-wallet-page-transactions-container-header-label'>
                  TRANSACTION SUMMARY
                </p>
                <Link className='anyjob-wallet-page-transactions-container-header-btn' to="/transactions">
                  View all
                </Link>
              </div>
              {
                Boolean(transactions?.length) ? (
                  <TableContainer component={Paper} className='anyjob-dashboard-table-container'>
                    <Table sx={{ minWidth: 620 }} aria-label="simple table" className='anyjob-dashboard-table'>
                      <TableHead
                        className='anyjob-dashboard-table-header'
                      >
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Status</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions.map((row: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            // onClick={() => navigate(`/task-details/${row.unique_id}`)}
                            className='anyjob-dashboard-table-item:hover'
                          >
                            <TableCell component="th" scope="row">
                            {row?.transaction_type}
                            </TableCell>
                            <TableCell align="right">{row?.currency?.symbol} {formatCurrency(row?.value)}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">
                            {moment(row.created_at).format("MMM DD,YYYY . hh:mm a")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <></>
                )
              }

            </div>
          </div>
        ) : (
          <ErrorComp 
            title={err.title}
            desc={err.msg}
            onClick={setUp}
          />
        )
      }
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}