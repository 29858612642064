import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';


import './NavBar.css'
import Logo from '../../assets/svg/Logo.svg'
import MenuIcon from '../../assets/svg/vuesax/linear/menu.svg'
import CloseIcon from '../../assets/svg/close-icon.svg'


export default function NavBar(props: any) {
  const [isMenuIconClicked, setMenuIconClicked] = useState(false);
  const location = useLocation()

  const toggleMenu = (value: boolean) => {
    setMenuIconClicked(value);
  };

  const closeMenu = async () => {
    
    setMenuIconClicked(false)
    
    // await sleep(1000)
  }

  return (
    <>
      <nav className='anyjob-nav-bar'>
        <NavHashLink 
          exact to={'/'} 
          smooth
          // className={`${location.pathname}${location.hash}` === '/' ? "anyjob-nav-link-active" : "anyjob-nav-link"}
          onClick={closeMenu}            
        >
          <img 
            src={Logo}
            alt='AnyJob'
            className='anyjob-nav-bar-logo'
          />
        </NavHashLink>

        <div className='anyjob-nav-links'>
          <ul className={isMenuIconClicked ? "menu-list" : "menu-list close"}>
            <li>
              <NavHashLink 
                exact to={'/'} 
                smooth
                className={`${location.pathname}${location.hash}` === '/' ? "anyjob-nav-link-active" : "anyjob-nav-link"}
                onClick={closeMenu}            
              >
                Home
              </NavHashLink>
            </li>
            <li>
              <NavHashLink 
                exact to={'/faq'} 
                smooth
                className={`${location.pathname}${location.hash}` === '/faq' ? "anyjob-nav-link-active" : "anyjob-nav-link"}
                onClick={closeMenu}            
              >
                FAQ
              </NavHashLink>
            </li>
            <li>
              <NavHashLink 
                exact to={'/contact-us'} 
                smooth
                className={`${location.pathname}${location.hash}` === '/contact-us' ? "anyjob-nav-link-active" : "anyjob-nav-link"}
                onClick={closeMenu}            
              >
                Contact Support
              </NavHashLink>
            </li>
          </ul>

          <div className='anyjob-nav-ctas'>
            <NavHashLink 
              exact to={'/login'} 
              smooth
              className={"anyjob-nav-login"}
              onClick={closeMenu}            
            >
              Login
            </NavHashLink>
            <NavHashLink 
              exact to={'/signup'} 
              smooth
              className={"anyjob-nav-signup"}
              onClick={closeMenu}            
            >
              Get Started
            </NavHashLink>
          </div>
        </div>
      </nav>
      <nav className='anyjob-mobile-nav-bar'>
        <div className='anyjob-mobile-nav-bar-controls'>
          <div
            onClick={() => toggleMenu(true)}
            className='anyjob-mobile-nav-bar-menu-icon-container'
          >
            
            <img
              src={MenuIcon}
              alt='Menu'
              className='anyjob-mobile-nav-bar-menu-icon'
              
            />
          </div>
          <NavHashLink 
            exact to={'/'} 
            smooth
            // className={`${location.pathname}${location.hash}` === '/' ? "anyjob-nav-link-active" : "anyjob-nav-link"}
            onClick={closeMenu}            
          >
          <img 
            src={Logo}
            alt='AnyJob'
            // className='anyjob-mobile-nav-bar-logo'
            className='anyjob-nav-bar-logo'
          />
          </NavHashLink>
        </div>

        <NavHashLink 
          exact to={'/signup'} 
          smooth
          className={"anyjob-nav-signup"}
          onClick={closeMenu}            
        >
          Get Started
        </NavHashLink>
      </nav>
      {
        isMenuIconClicked && (
          <div className={isMenuIconClicked ? 'anyjob-mobile-nav-bar-menu' : 'anyjob-mobile-nav-bar-close-menu'}>          
            <div 
              className='anyjob-mobile-nav-bar-close-menu-btn'
            >
              <img
                src={CloseIcon}
                alt='Close Menu'
                className='anyjob-mobile-nav-bar-close-icon'
                onClick={() => toggleMenu(false)}
              />
            </div>
            <ul className='anyjob-mobile-menu-list'>
              <li>
                <NavHashLink 
                  exact to={'/'} 
                  smooth
                  className={`${location.pathname}${location.hash}` === '/' ? "anyjob-nav-menu-item-active" : "anyjob-nav-menu-item"}
                  onClick={closeMenu}            
                >
                  <div>
                    Home
                  </div>
                  
                </NavHashLink>
              </li>
              <li>
                <NavHashLink 
                  exact to={'/faq'} 
                  smooth
                  className={`${location.pathname}${location.hash}` === '/faq' ? "anyjob-nav-menu-item-active" : "anyjob-nav-menu-item"}
                  onClick={closeMenu}            
                >
                  <div>
                    FAQ 
                  </div>
                </NavHashLink>
              </li>
              <li>
                <NavHashLink 
                  exact to={'/contact-us'} 
                  smooth
                  className={`${location.pathname}${location.hash}` === '/support' ? "anyjob-nav-menu-item-active" : "anyjob-nav-menu-item"}
                  onClick={closeMenu}            
                >
                  <div>
                    Contact Support
                  </div>
                </NavHashLink>
              </li>
            </ul>
            <div className='anyjob-mobile-nav-ctas'>
              <NavHashLink 
                exact to={'/login'} 
                smooth
                className={"anyjob-mobile-nav-ctas-login"}
                onClick={closeMenu}            
              >
                Login
              </NavHashLink>
              <NavHashLink 
                exact to={'/signup'} 
                smooth
                className={"anyjob-mobile-nav-ctas-signup"}
                onClick={closeMenu}            
              >
                Get Started
              </NavHashLink>
            </div>
          </div>
        )
      }
    </>
    
  )
}