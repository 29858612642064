import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { useNavigate } from 'react-router';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import './Tasks'
import { TasksStatusType } from './types';
import { fetch_tasks } from '../../api/jobs';
import { CaretLeft, CaretRight } from 'phosphor-react';

import PaperAndPencil from '../,./../../assets/svg/pencil-and-paper.svg'
import { Link } from 'react-router-dom';
import { DefaultStateType } from '../../redux/types';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/strings';

type TasksTabContentProps = {
  dateRange: any,
  loadingModalRef: any,
  status: TasksStatusType,
}

export default function TasksTabContent(props: TasksTabContentProps){
  const [data, setData] = useState<any[]>([])
  const [pageNo, setPageNo] = useState(1);
  const [maxPageNo, setMaxPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const userData = useSelector((state: DefaultStateType) => state.userData);

  const navigate = useNavigate();

  useEffect(() => {
    setUp()
  }, [])

  useEffect(() => {
    setPageNo(1)
    setUp()
  }, [props.dateRange,])

  const setUp = async () => {
    props.loadingModalRef.current.showLoadingModal()
    await fetchTasks()
    props.loadingModalRef.current?. hideLoadingModal()
  }

  const fetchTasks = async () => {    
    try {
      console.log('props.dateRange.endDate', props.dateRange[0].endDate);
      
      const params = {
        status: props.status,
        page: pageNo,
        end_date: moment(props.dateRange[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
        start_date: moment(props.dateRange[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),   
        mode: userData.appMode,      
      }
      const res = await fetch_tasks(params)
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        
        
        if (pageNo === 1) {
          setData(response.results);   
        } else {
          setData((prevState: any) => [...prevState, ...response.results]);   
        }     
        setMaxPageNo(response.count/20)
        setTotalCount(response.count)
        setHasNextPage(response.count > pageNo)      
      } else {

      }
    } catch (error) {
      
    }
  }

  const goToPrev = async () => {
    if (pageNo <= 1) {
      
    } else {
      props.loadingModalRef.current.showLoadingModal()
      setPageNo(prevPage => prevPage - 1);
      await fetchTasks()
      props.loadingModalRef.current?. hideLoadingModal()
    }
  }

  const goToNext = async () => {
    if (maxPageNo > pageNo) {
      setPageNo(prevPage => prevPage + 1);
      props.loadingModalRef.current.showLoadingModal()
      await fetchTasks()
      props.loadingModalRef.current?. hideLoadingModal()
    }    
  };

  const printPaginationPosition = (page: number) => {
    var lowerRange = ((page - 1) * 20)  + 1
    var upperRange =  totalCount < page * 20 ? totalCount : page * 20
    return `${lowerRange} - ${upperRange} of ${totalCount}`
  }

  return (
    Boolean(data.length) ? (
      <>
        <TableContainer component={Paper} className='anyjob-dashboard-table-container'>
          <Table sx={{ minWidth: 620 }} aria-label="simple table" className='anyjob-dashboard-table'>
            <TableHead
              className='anyjob-dashboard-table-header'
            >
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Date Applied</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => navigate(`/task-details/${row.unique_id}`)}
                  className='anyjob-dashboard-table-item:hover'
                >
                  <TableCell component="th" scope="row">
                    {row?.job?.title}
                  </TableCell>
                  <TableCell align="right">{moment(row.date_applied).format('DD MMM YY')}</TableCell>
                  <TableCell align="right">{row?.status}</TableCell>
                  <TableCell align="right">{row?.job?.compensation_currency?.symbol} {formatCurrency(row?.job?.compensation_amount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='anyjob-pagination-controls-container '>
              <p>
                {printPaginationPosition(pageNo)}
              </p>
    
              <CaretLeft
                className='anyjob-pagination-controls-btn'
                onClick={goToPrev}
              />
              <CaretRight
                className='anyjob-pagination-controls-btn'
                onClick={goToNext}
              />
            </div>
      </>
    ) : (
      <div className='anyjob-empty-state-container'>
        <img 
          alt="No proposal submitted yet"
          src={PaperAndPencil}
        />
        <p>
          Nothing here yet
        </p>
        <Link
          to="/jobs"
          className='anyjob-empty-state-container-btn'
        >
          Browse Jobs
        </Link>
      </div>
    )
  )

}