import React from 'react'

import './AddItemCardButton.css'
import { Plus } from 'phosphor-react'

type AddItemCardButtonProps = {
  label?: string,
  onClick: () => void;
}

export function AddItemCardButton(props: AddItemCardButtonProps) {
  return (
    <div className='anyjob-add-item-card-btn' onClick={props.onClick}>
      <span>
        <Plus 
          className='anyjob-add-item-card-btn-icon'
        />
      </span>
      <p>
        {props.label}
      </p>
    </div>
  )
}

export function AddItemCardButtonNoLabel(props: AddItemCardButtonProps) {
  return (
    <div 
      className='anyjob-add-item-card-btn-2' 
      onClick={props.onClick}
    >
      <span>
        <Plus 
          className='anyjob-add-item-card-btn-icon'
        />
      </span>
    </div>
  )
}

AddItemCardButton.defaultProps = {
  label: "Add Item"
}