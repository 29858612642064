import React, { useEffect, useState } from 'react'

import './NotificationBanner.css'

import NotificationBannerIcon from '../../assets/svg/notification_banner_icon.svg'
import {  CaretLeft, CaretRight, X } from 'phosphor-react'
import { notifications_list } from '../../api/notifications'

export default function NotificationBanner(props: any) {
  const [notifications, setNotifications] = useState<any[]>([])
  const [sortedNotifications, setSortedNotifications] = useState<any[]>([])
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0)

  useEffect(() => {
    fetchNotifications()
  }, [])

  const fetchNotifications = async () => {
    try {
      var params = {
        page: 1,
        notification_type: 'NOTICE_BOARD',
      }
      const res = await notifications_list(params)
      if(res.ok) {
        const response = await res.json()   
        setNotifications(response.results)
        sortNotifications(response.results)
      }
    } catch (error) {
      
    }
    
  }

  const  sortNotifications = (notifications: any[]) => {
    notifications.sort((a, b) => {
      // Compare priorities first
      const priorityOrder: any = {
        URGENT: 0,
        HIGH: 1,
        MEDIUM: 2,
        LOW: 3,
      };
  
      const priorityComparison = priorityOrder[a.priority] - priorityOrder[b.priority];
  
      // If priorities are equal, compare date_created
      if (priorityComparison === 0) {
        // Convert date_created to Date objects for comparison
        const dateA: any = new Date(a.date_created);
        const dateB: any = new Date(b.date_created);
  
        // Compare dates
        return dateA - dateB;
      }
  
      return priorityComparison;
      
    });
  
    // return notifications;
    setSortedNotifications(notifications)
    setCurrentNotificationIndex(0)
  }

  const goToPrev = async () => {
    if (currentNotificationIndex > 1) {
      setCurrentNotificationIndex(prevState => prevState - 1);
    }
  }

  const goToNext = async () => {
    if (sortedNotifications.length > (currentNotificationIndex + 1)) {
      setCurrentNotificationIndex(prevState => prevState + 1);
    }
  }


  if (sortedNotifications[currentNotificationIndex] == null || !Boolean(sortedNotifications[currentNotificationIndex])) {
    return(<></>)
  } else {
    return (
      <div className='anyjob-notification-banner-container'>
        {
          (sortedNotifications.length <= 1) ? (
            <div className='anyjob-notification-banner-controls-container'>
              <X className='anyjob-notification-banner-controls-close'/>
            </div>
          ) : (
            <div className='anyjob-notification-banner-controls-container'>
              <CaretLeft 
                className='anyjob-notification-banner-controls-left'
                onClick={goToPrev}
              />
              <CaretRight 
                className='anyjob-notification-banner-controls-right'
                onClick={goToNext}
              />
            </div>
          )
        }
        
        <img 
          src={NotificationBannerIcon}
          alt="Notification Icon"
          className='anyjob-notification-banner-icon'
        />
        <div className='anyjob-notification-banner-contents'>
          <p className='anyjob-notification-banner-title'>
            {sortedNotifications[currentNotificationIndex]?.title}
          </p>
          <p className='anyjob-notification-banner-msg'>
            {sortedNotifications[currentNotificationIndex]?.message}
          </p>
        </div>
      </div>
    )
  }
    
}