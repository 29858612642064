import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import moment from 'moment';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { toast } from "react-toastify";

import './Tasks.css'
import Header from '../../components/Header/Header'
import {  } from '../../api/jobs'
import LoadingModal from '../../components/LoadingModal'
import JobWidget from '../../components/JobWidget/JobWidget';
import getErrorMessage from '../../utils/getErrorMessage';
import { DefaultStateType } from '../../redux/types';
import CalendarIcon from '../../assets/svg/calendar-icon.svg'
import CustomDateRangePicker from '../../components/CustomDateRangePicker/CustomDateRangePicker';
import { TasksStatusType } from './types';
import TasksTabContent from './TasksTabContent';
import NotificationBanner from '../../components/NotificationBanner/NotificationBanner';

export default function Tasks(props: any) {
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -30),
      // endDate: addDays(new Date(), 1),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [activeTab, setActiveTab] = useState<TasksStatusType>("All")
  

  const userData = useSelector((state: DefaultStateType) => state.userData);

  const loadingModalRef = useRef<any>()
  const customDateRangePickerRef = useRef<any>()




  const getRangeDisplayStr = () => {
    var startDateStr = moment(dateRange[0].startDate).format("MMM DD")
    var endDateStr = moment(dateRange[0].endDate).format("MMM DD")

    return `${startDateStr} - ${endDateStr}`
  }

  return (
    <div className='anyjob-tasks-page-container'>
      <LoadingModal ref={loadingModalRef} />
      <Header title={"Tasks"} />
      <NotificationBanner />
      <div className='anyjob-tasks-page-container'>
        <div className='anyjob-tasks-page-content-hero'>
          <div className='anyjob-tasks-page-hero-headings'>
            <p className='anyjob-proposal-hero-title'>
              Keep it up, {userData.user_details?.first_name}
            </p>
            <p className='anyjob-proposal-hero-desc'>
              Here is your job application status from {getRangeDisplayStr()}
            </p>
          </div>    
          <div 
            className='anyjob-tasks-page-hero-date-btn'
            onClick={() => customDateRangePickerRef.current.show(dateRange, (value: any) => setDateRange(value))}
          >
            <p className='anyjob-tasks-page-hero-date-btn-date-range'>
              {getRangeDisplayStr()}
            </p>
            <img 
              alt="Date Range Button"
              src={CalendarIcon}
            />
          </div>      
        </div>
        <div className='anyjob-tasks-container'>
          <div className='anyjob-tasks-status-tab-header'>
            <div 
              className={activeTab === "All" ? 'anyjob-tasks-status-tab-header-active-item' : 'anyjob-tasks-status-tab-header-item'}
              onClick={() => setActiveTab("All")}
            >
              <p>
                All
              </p>
            </div>
            <div 
              className={activeTab === "Ongoing" ? 'anyjob-tasks-status-tab-header-active-item' : 'anyjob-tasks-status-tab-header-item'}
              onClick={() => setActiveTab("Ongoing")}
            >
              <p>
                Ongoing
              </p>
            </div>
            <div 
              className={activeTab === "In Review" ? 'anyjob-tasks-status-tab-header-active-item' : 'anyjob-tasks-status-tab-header-item'}
              onClick={() => setActiveTab("In Review")}
            >
              <p>
                In Review 
              </p>
            </div>
            <div 
              className={activeTab === "Assessment" ? 'anyjob-tasks-status-tab-header-active-item' : 'anyjob-tasks-status-tab-header-item'}
              onClick={() => setActiveTab("Assessment")}
            >
              <p>
                Assessment 
              </p>
            </div>
            <div 
              className={activeTab === "Completed" ? 'anyjob-tasks-status-tab-header-active-item' : 'anyjob-tasks-status-tab-header-item'}
              onClick={() => setActiveTab("Completed")}
            >
              <p>
              Completed
              </p>
            </div>
            <div 
              className={activeTab === "Rejected" ? 'anyjob-tasks-status-tab-header-active-item' : 'anyjob-tasks-status-tab-header-item'}
              onClick={() => setActiveTab("Rejected")}
            >
              <p>
                Rejected 
              </p>
            </div>
          </div>

          {
            activeTab === 'All' && (
              <TasksTabContent 
                loadingModalRef={loadingModalRef}
                status={activeTab}
                dateRange={dateRange}
              />
            )
          }

          {
            activeTab === 'Assessment' && (
              <TasksTabContent 
                loadingModalRef={loadingModalRef}
                status={activeTab}
                dateRange={dateRange}
              />
            )
          }
          
          {
            activeTab === 'In Review' && (
              <TasksTabContent 
                loadingModalRef={loadingModalRef}
                status={activeTab}
                dateRange={dateRange}
              />
            )
          }
          {
            activeTab === 'Ongoing' && (
              <TasksTabContent 
                loadingModalRef={loadingModalRef}
                status={activeTab}
                dateRange={dateRange}
              />
            )
          }
          
          {
            activeTab === 'Completed' && (
              <TasksTabContent 
                loadingModalRef={loadingModalRef}
                status={activeTab}
                dateRange={dateRange}
              />
            )
          }
          
          
          {
            activeTab === 'Rejected' && (
              <TasksTabContent 
                loadingModalRef={loadingModalRef}
                status={activeTab}
                dateRange={dateRange}
              />
            )
          }
          

        </div>


      </div>
      
      <CustomDateRangePicker ref={customDateRangePickerRef} />
    </div>
  )
}