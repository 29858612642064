import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './NotificationPage.css'
import Header from '../../components/Header/Header'
import LoadingModal from '../../components/LoadingModal'
import { notifications_list } from '../../api/notifications'
import { CaretLeft, CaretRight } from 'phosphor-react'
import moment from 'moment'


export default function NotificationPage(props: any) {
  const [notifications, setNotifications] = useState<any[]>([])
  const [pageNo, setPageNo] = useState(1);
  const [maxPageNo, setMaxPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const loadingModalRef = useRef<any>()
  const navigate = useNavigate()

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchNotifications()
    loadingModalRef.current?. hideLoadingModal()
  }

  const fetchNotifications = async () => {    
    try {
      
      const params = {
        page: pageNo,
        notification_type: 'GENERAL'
      }
      const res = await notifications_list(params)
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        
        
        if (pageNo === 1) {
          setNotifications(response.results);   
        } else {
          setNotifications((prevState: any) => [...prevState, ...response.results]);   
        }     
        setMaxPageNo(response.count/20)
        setTotalCount(response.count)
        setHasNextPage(response.count > pageNo)      
      } else {

      }
    } catch (error) {
      
    }
  }

  const goToPrev = async () => {
    if (pageNo <= 1) {
      
    } else {
      loadingModalRef.current.showLoadingModal()
      setPageNo(prevPage => prevPage - 1);
      await fetchNotifications()
      loadingModalRef.current?. hideLoadingModal()
    }
  }

  const goToNext = async () => {
    if (maxPageNo > pageNo) {
      setPageNo(prevPage => prevPage + 1);
      loadingModalRef.current.showLoadingModal()
      await fetchNotifications()
      loadingModalRef.current?. hideLoadingModal()
    }    
  };

  const printPaginationPosition = (page: number) => {
    var lowerRange = ((page - 1) * 20)  + 1
    var upperRange =  totalCount < page * 20 ? totalCount : page * 20
    return `${lowerRange} - ${upperRange} of ${totalCount}`
  }

  return (
    <div className='anyjob-notifications-page-container'>
      <Header title={"Notifications"} />
      <>
      <div className='anyjob-notifications-page-notification-list'>
        {
          notifications.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className='anyjob-notifications-page-notification-item'
              >
                <div className='anyjob-notifications-page-notification-item-heading'>
                  <p className='anyjob-notifications-page-notification-item-title'>
                    {item.title}
                  </p>
                  <p className='anyjob-notifications-page-notification-item-date'>
                    {moment(item.date_created).format("hh:mm a dddd MMM YYYY")}
                  </p>
                </div>
                <p className='anyjob-notifications-page-notification-item-message'>
                  {item.message}
                </p>
              </div>
            )
          })
        }
      </div>
      <div className='anyjob-pagination-controls-container'>
        <p>
          {printPaginationPosition(pageNo)}
        </p>

        <CaretLeft
          className='anyjob-pagination-controls-btn'
          onClick={goToPrev}
        />
        <CaretRight
          className='anyjob-pagination-controls-btn'
          onClick={goToNext}
        />
      </div>
      </>
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}