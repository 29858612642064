import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { addDays } from 'date-fns';
// @ts-ignore
import Modal from 'react-modal';
// @ts-ignore
import { DateRangePicker } from 'react-date-range';

import './CustomDateRangePicker.css'
import { X } from 'phosphor-react';

type CustomDateRangePickerProps = {

}

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};


const CustomDateRangePicker = forwardRef((props: CustomDateRangePickerProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [onClose, setOnClose] = useState<((dateRange: any) => void) | undefined>(undefined);

  const show = useCallback((currentDateRange: any, cb?: (dateRange: any) => void) => {
    
    if (cb) {
      setOnClose(() => cb);
    }
    setDateRange(currentDateRange)
    
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))
  
  return (
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Select Date Range"
      contentElement={() => {
        return (
          <div className='anyjob-custom-date-range-picker-modal'>
            <div className="anyjob-custom-date-range-picker-contents">
              <div className="anyjob-custom-date-range-picker-contents-header">
                <p>Select a range</p>
                <div className="anyjob-custom-date-range-picker-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-custom-date-range-picker-close-icon"
                  />
                </div>
                
              </div>
              <DateRangePicker
                onChange={(item: any) => setDateRange([item.selection])}
                // showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={dateRange}
                direction="horizontal"
                rangeColors={["#F6C000"]}
                className="anyjob-custom-date-range-picker"
              />
              <div className='anyjob-custom-date-range-picker-ctas'>
                <p 
                  className='anyjob-custom-date-range-picker-close-btn'
                  onClick={hide}
                >
                  Cancel
                </p>
                <p 
                  className='anyjob-custom-date-range-picker-done-btn'                  
                  onClick={() => {
                    if (Boolean(onClose)) {
                      // @ts-ignore
                      onClose(dateRange)
                    }
                    hide()
                  }}
                >
                  Done
                </p>
              </div>
            </div>
          </div>
        )
      }}
    >

    </Modal>
  )
})

export default CustomDateRangePicker