import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";

import './WithdrawalPage.css'

import Header from '../../components/Header/Header'
import LoadingModal from '../../components/LoadingModal'
import getErrorMessage from '../../utils/getErrorMessage';
import { DefaultStateType } from '../../redux/types';
import { formatCurrency } from '../../utils/strings';
import moment from 'moment';
import ErrorComp from '../../components/ErrorComp/ErrorComp';
import { get_user_payment_details } from '../../api/profile';
import PaymentDetailsModal from '../../components/PaymentDetailsModal/PaymentDetailsModal';
import PinModal from '../../components/PinModal/PinModal';
import { request_withdrawal } from '../../api/wallet';


export default function WithdrawalPage(props: any){
  const [paymentData, setPaymentData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dataFetchFailed, setDataFetchFailed] = useState(false)
  const [displayType, setDisplayType] = useState<"form" | "summary">("form")
  const [inputValues, setInputValues] = useState({
    amount: "",    
  })
  const [err, setErr] = useState({
    amount: "",
  })

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const activeWallet = useSelector((state: DefaultStateType) => state.wallet.activeWallet);
  const dispatch = useDispatch()

  const loadingModalRef = useRef<any>()
  const paymentDetailsModalRef = useRef<any>()
  const pinModalRef = useRef<any>()


  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchPaymentData()
    loadingModalRef.current?. hideLoadingModal()
  }

  const fetchPaymentData = async () => {    
    try {
      setIsLoading(true)
      const res = await get_user_payment_details()
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        setPaymentData(response.data.user_payment_details)
        setIsLoading(false)
        setDataFetchFailed(false)
      } else {
        setPaymentData(null)
      }
      
    } catch (error) {
      setIsLoading(false)
      setPaymentData(null)
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
    setErr({
      ...inputValues,
      [name]: ''
    });
  }

  const validateForm = () => {
    var isValid = true
    if (!Boolean(inputValues.amount.length)) {
      isValid = false
      setErr((prevState: any) => ({
        ...prevState,
        amount: "Please enter an amount you wish to withdraw"
      }))
    } else if (parseFloat(inputValues.amount) <= 0) {
      isValid = false
      setErr((prevState: any) => ({
        ...prevState,
        amount: "Please enter an amount greater than 0"
      }))
      // @ts-ignore
    } else if (parseFloat(inputValues.amount) > activeWallet?.current_balance) {
      
      isValid = false
      setErr((prevState: any) => ({
        ...prevState,
        amount: "Insufficient wallet balance"
      }))
    }

    return isValid
  }

  const handleViewSummary = (event: any) => {
    try {
      event.preventDefault();
      // @ts-ignore
      console.log('parseFloat(activeWallet?.current_balance)', (activeWallet?.current_balance).toString());
      console.log('parseFloat(inputValues.amount)', parseFloat(inputValues.amount));
      
      
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      setDisplayType("summary")
      loadingModalRef.current?. hideLoadingModal()
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const confirmTransaction = () => {
    try {
      pinModalRef.current.show(submitTransaction)
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const submitTransaction = async (pin:string, status:  'success' | 'failed' | 'canceled') => {
    try {
      loadingModalRef.current.showLoadingModal()
      var params = {
        pin,
        amount: inputValues.amount,
      }

      const res = await request_withdrawal(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
      }

      
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      loadingModalRef.current?. hideLoadingModal()
    }
  }


  return (
    <div className='anyjob-withdrawal-page-container'>
      <Header title={"Withdrawal"} />
      {
        displayType === 'form' && (
          <div className='anyjob-withdrawal-page-container'>
            <div className='anyjob-withdrawal-page-title-container'>
              <p className='anyjob-withdrawal-page-title'>
                Withdraw to Bank
              </p>
              <p className='anyjob-withdrawal-page-sub-title'>
                Enter the amount you want to withdraw
              </p>
            </div>

            <div className='anyjob-withdrawal-page-content'>
              <span className="anyjob-auth-page-input-span">
                <label className="anyjob-auth-page-input-label">Enter Amount</label>
                <input 
                  onChange={handleInputChange} 
                  type="number"
                  value={inputValues.amount}
                  required
                  name="amount"
                  className="anyjob-auth-page-input"
                  placeholder='How much would you like to withdraw? '
                />
                {
                  Boolean(err.amount.length) && (
                    <p className="anyjob-auth-input-err-msg">
                      {err.amount}
                    </p>
                  )
                }
              </span>
              <label className="anyjob-auth-page-input-label">Recipient</label>
              {
                Boolean(paymentData) ? (
                  <div className='anyjob-withdrawal-payment-details-container'>
                    <div className='anyjob-withdrawal-payment-details-row'>
                      <div className='anyjob-withdrawal-payment-details-item'>
                        <label className="anyjob-withdrawal-label">Account Name</label>
                        <p className='anyjob-withdrawal-payment-details-data'>{paymentData.account_name}</p>
                      </div>
                      <div className='anyjob-withdrawal-payment-details-item'>
                        <label className="anyjob-withdrawal-label">Account Number</label>
                        <p className='anyjob-withdrawal-payment-details-data'>******{paymentData.account_number_last_4}</p>
                      </div>
                    </div>
                    <div className='anyjob-withdrawal-payment-details-row'>
                      <div className='anyjob-withdrawal-payment-details-item'>
                        <label className="anyjob-withdrawal-label">Bank Name</label>
                        <p className='anyjob-withdrawal-payment-details-data'>{paymentData.bank_name}</p>
                      </div>
                      <div className='anyjob-withdrawal-payment-details-item'>
                        <div className='anyjob-withdrawal-payment-details-btn' 
                          onClick={() => paymentDetailsModalRef.current.show()}
                        >
                          <p>Change Payment Details</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='anyjob-withdrawal-payment-details-container'>
                    <div className='anyjob-withdrawal-no-payment-details-container'>
                      <p className='anyjob-withdrawal-no-payment-details-container-title'>
                        No Bank Information!
                      </p>
                      <p className='anyjob-withdrawal-no-payment-details-container-sub-title'>
                        Kindly provide the banking information to which you  would receive the withdrawal
                      </p>
                      <div className='anyjob-withdrawal-payment-details-btn' 
                        onClick={() => paymentDetailsModalRef.current.show()}
                      >
                        <p>Provide Bank Details</p>
                      </div>
                    </div>
                  </div>
                )
              }
              <button 
                className={Boolean(paymentData) ? 'anyjob-withdrawal-page-btn-1' : 'anyjob-withdrawal-page-btn-0'} 
                disabled={!Boolean(paymentData)} 
                onClick={handleViewSummary}
              >
                <p>Continue</p>
              </button>
            </div>
          </div>
        )
      }
      {
        displayType === 'summary' && (
          <div className='anyjob-withdrawal-page-container'>
            <div className='anyjob-withdrawal-page-title-container'>
              <p className='anyjob-withdrawal-page-title'>
                Confirm Withdrawal
              </p>
              <p className='anyjob-withdrawal-page-sub-title'>
                Withdrawal could take a up to 2 working days
              </p>
            </div>

            <div className='anyjob-withdrawal-page-content'>
              <div className='anyjob-withdrawal-selected-amount-container' onClick={() => setDisplayType('form')}>
                <p>{activeWallet?.currency?.symbol} {formatCurrency(inputValues.amount)}</p>
              </div>
              <div className='anyjob-withdrawal-payment-details-container anyjob-withdrawal-payment-summary'>
                <div className='anyjob-withdrawal-payment-summary-item'>
                  <p className='anyjob-withdrawal-payment-summary-item-label'>Date & Time</p>
                  <p className='anyjob-withdrawal-payment-summary-item-value'>{moment().format("DD/MM/YYYY hh:mm:ss")}</p>
                </div>
                <div className='anyjob-withdrawal-payment-summary-item'>
                  <p className='anyjob-withdrawal-payment-summary-item-label'>Amount Receivable</p>
                  <p className='anyjob-withdrawal-payment-summary-item-value'>{activeWallet?.currency?.symbol} {formatCurrency(inputValues.amount)}</p>
                </div>
                <div className='anyjob-withdrawal-payment-summary-item'>
                  <p className='anyjob-withdrawal-payment-summary-item-label'>Transaction Fee</p>
                  <p className='anyjob-withdrawal-payment-summary-item-value'>{activeWallet?.currency?.symbol} {formatCurrency("0")}</p>
                </div>
                <div className='anyjob-withdrawal-payment-summary-item'>
                  <p className='anyjob-withdrawal-payment-summary-item-label'>Recipient Name</p>
                  <p className='anyjob-withdrawal-payment-summary-item-value'>{paymentData.account_name}</p>
                </div>
                <div className='anyjob-withdrawal-payment-summary-item'>
                  <p className='anyjob-withdrawal-payment-summary-item-label'>Account Number</p>
                  <p className='anyjob-withdrawal-payment-summary-item-value'>******{paymentData.account_number_last_4}</p>
                </div>
              </div>
              <button 
                className={'anyjob-withdrawal-page-btn-2'} 
                disabled={!Boolean(paymentData)} 
                onClick={confirmTransaction}
              >
                <p>Confirm</p>
              </button>
            </div>
          </div>
        )
      }
      
      <LoadingModal ref={loadingModalRef} />
      <PaymentDetailsModal 
        ref={paymentDetailsModalRef} 
        onDone={fetchPaymentData}
      />
      <PinModal 
        ref={pinModalRef} 
      />
    </div>
  )

}