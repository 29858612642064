import React, { useRef, useState } from 'react'
import './FAQPage.css'
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import FAQItem from './FAQItem';


const FaqList = [
  {
    "title": "What is AnyJob?",
    "content": "AnyJob is an online platform that facilitates the connection between freelancers and clients. It serves as a marketplace where clients can find skilled freelancers for various tasks and projects.",
  },
  {
    "title": "How does AnyJob work?",
    "content": "Clients post their job requirements on AnyJob, and freelancers can browse and apply for thosejobs. Clients then choose the freelancer they believe is the best fit for the task.",
  },
]



const faqs = [
  {
    title: "What is your platform about?",
    content: "Our platform connects users with zero-skill-based tasks to earn money."
  },
  {
    title: "How can I earn money on your platform?",
    content: "You can earn money by completing tasks posted by clients on our platform."
  },
  {
    title: "What kind of tasks are available?",
    content: "Tasks can vary but generally require no specific skills, such as data entry or surveys."
  },
  {
    title: "Do I need any special skills to complete tasks?",
    content: "No, the tasks available on our platform are designed to be completed without any special skills."
  },
  {
    title: "How do I sign up?",
    content: `You can sign up by click "Get Started" above and following the registration process.`
  },
  {
    title: "Is it free to sign up?",
    content: "Yes, signing up on our platform is completely free."
  },
  {
    title: "How do I get paid?",
    content: "You can withdraw your earnings through various payment methods available on our platform."
  },
  {
    title: "Is there a minimum payout amount?",
    content: "Yes, there is a minimum payout amount, which you can find on our platform."
  },
  {
    title: "How often can I withdraw my earnings?",
    content: "You can withdraw your earnings as often as you meet the minimum payout amount."
  },
  {
    title: "Are there any fees for withdrawing earnings?",
    content: "There may be fees associated with withdrawing earnings, depending on the payment method chosen."
  },
  {
    title: "How many tasks can I complete in a day?",
    content: "The number of tasks you can complete in a day is not limited, but it depends on task availability."
  },
  {
    title: "Can I choose which tasks to complete?",
    content: "Yes, you can choose which tasks to complete based on your preferences and availability."
  },
  {
    title: "Are the tasks safe and legitimate?",
    content: "Yes, all tasks on our platform are screened to ensure they are safe and legitimate."
  },
  {
    title: "What should I do if I encounter any issues with a task?",
    content: "You can contact our support team for assistance with any issues you encounter."
  },
  // {
  //   title: "Is there a rating system for task posters and task completers?",
  //   content: "Yes, there is a rating system to provide feedback on task posters and completers."
  // },
  {
    title: "Can I cancel a task after accepting it?",
    content: "In some cases, you may be able to cancel a task after accepting it, but it depends on the situation."
  },
  {
    title: "Is there a referral program available?",
    content: "Yes, we have a referral program that rewards you for referring new users to our platform."
  }
];

export default function FAQPage(props: any) {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  


  return (
    <>
      <NavBar />
      <div className='anyjob-faq-page-container'>
        <section className='anyjob-faq-page-header-sec'>
          <p className='anyjob-faq-page-header-title' >Frequently Asked Questions</p>
          <p className='anyjob-faq-page-header-sub-title' >Let’s help you content most of your questions</p>
        </section>
        <section className='anyjob-faq-page-content-sec'>
          {
            faqs.map((item: {title: string, content: string}, index: number) => {
              return (
                <FAQItem 
                  item={item}
                  isActive={Boolean(index===activeItemIndex)}
                  setActiveItemIndex={setActiveItemIndex}
                  key={index}
                  index={index}
                />
              )
            })
          }
        </section>
        <Footer />
      </div>
      
    </>
  )
}