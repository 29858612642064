import React, { useEffect, useState, useRef } from 'react'
import EmojiPicker from 'emoji-picker-react';
// @ts-ignore
import Modal from 'react-modal';
// @ts-ignore
import { w3cwebsocket as W3CWebSocket } from "websocket"

import './MessageContainer.css'

import EmojiIcon from '../../assets/svg/smile.svg'
import PaperClip from '../../assets/svg/attach-paperclip.svg'
import SendArrowIcon from '../../assets/svg/send-arrow.svg'
import { BASE_WEBSOCKET } from '../../env';
import { getPlainToken, getToken } from '../../utils/handleStorage';
import { sleep } from '../../utils/sleep';
import { get_messages } from '../../api/chats';
import getErrorMessage from '../../utils/getErrorMessage';
import { useSelector } from 'react-redux';
import { DefaultStateType } from '../../redux/types';
import Avatar from '../Avatar/Avatar';
import moment from 'moment';

const MyMessageContainer = (props: any) => {
  return (
    <div className='anyjob-sender-message-container-wrapper'>
      <div className='anyjob-sender-message-container'>
        <p className='anyjob-message-text'>
          {props.messageObj?.message}
        </p>
        
      </div>
      <p className='anyjob-message-timestamp'>
        {moment(props.messageObj?.sent_time).fromNow()}
      </p>
    </div>
  )
}

const SenderMessageContainer = (props: any) => {
  return (
    <div className='anyjob-receiver-message-container-wrapper'>
      <div className='anyjob-receiver-message-container'>
        <p className='anyjob-message-text'>
          {props.messageObj?.message}
        </p>
      
      </div>
      <p className='anyjob-message-timestamp'>
      {moment(props.messageObj?.sent_time).fromNow()}
      </p>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    zIndex: 999999999,
    transform: 'translate(-50%, -50%)',
    borderRadius: 24,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)',
    zIndex: 99999999,
  },
};

export function convertMsgTZ(date: any, tzString: any) {
	var msg_date = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   

	var hour = msg_date.getHours()
	var minute = msg_date.getMinutes()

	if (minute < 10) {
    // @ts-ignore
		minute = '0' + minute
	}
	
	return `${hour}:${minute}`
}

export function calculateMaxPageNo(noOfItemsPerPage: number, totalCount: number) {
	return Math.ceil(totalCount/noOfItemsPerPage)
}

export default function MessageContainer(props: any) {
  const [newMessage, setNewMessage] = useState('');
  const [textareaHeight, setTextareaHeight] = useState('42px');
  const [emojiModalIsOpen, setIsEmojiModalOpen] = useState(false);
  const [isInitialMessageReady, setIsInitialMessageReady] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [maxPageNo, setMaxPageNo] = useState<any>(null);
  const [pageNo, setPageNo] = useState(1);
  const [messages, setMessages] = useState<any[]>([])
  const [messageGroupID, setMessageGroupID] = useState<any>(null)
  const [token, setToken] = useState(null);

  const messagingEndpoint  = BASE_WEBSOCKET + '/ws/chats'

  const socketRef = useRef<any>(null);

  const userData = useSelector((state: DefaultStateType) => state.userData);


  useEffect(() => {
    const fetchData = async () => {
      const token = await getPlainToken();
      setToken(token);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!token) return;

    const socketUrl = `${messagingEndpoint}?token=${token}`;
    socketRef.current = new WebSocket(socketUrl);

    socketRef.current.onopen = () => {
      console.log('WebSocket connected');
      // initiate_group()

      get_messages(props.activeChatMetaData?.user_details?.public_unique_id, pageNo)
      .then((response: any) => response.json())
      .then((res: any) => {
        setPageNo(pageNo + 1)
        console.log('res.results', res.results[0]);
        
        setMessages(res.results)
        setMaxPageNo(calculateMaxPageNo(20, res.count))
      })
      .catch((error: any) => console.log('error', error))
    };

    socketRef.current.onmessage = (event: MessageEvent) => {
      var res = JSON.parse(event.data)              
      console.log(res)

      switch (res.type) {
        case 'group.initiated':
          console.log('group.initiated')
          setMessageGroupID(res.data.messageGroupID)
          
          
          
          break;

        case 'echo.message':
          setMessages([res.data, ...messages])
          if (res.data.sender != userData.user_details.id) {
            console.log(res.data.sender)
            send_seen(res.data.id, res.data.sender)
          }          

          break;
        
        case 'message.seen':
          setMessages([res.data, ...messages])
          // if (messages[0].id === res.data.id){
          //   console.log(messages[0].id)
          //   console.log(res.data.id)
          //   let messages = [...this.state.messages]
          //   let message = {
          //     ...messages[0]
          //   }

          //   message.is_read = true

          //   messages[0] = message

          //   this.setState(state => ({
          //     ...state,
          //     messages: messages
          //   }))
          // }
          break
        default:
          break;
      }
    };

    

    socketRef.current.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [messagingEndpoint, token]);


  const send_seen = (id: any, sender: any) => {
    socketRef.current.send(JSON.stringify({
      type: 'send.seen',
      data: {
        message_id: id,
        messageGroupID: messageGroupID,
        sender: sender,
      }
    }))
  }

  const initiate_group = () => {
    socketRef.current.send(JSON.stringify({
      type: 'initiate.group',
      data: {
        user2_public_unique_id: props.activeChatMetaData?.user_details?.public_unique_id,
      }
    }));
  }

  const handleSubmit = () => {
    if (newMessage.length > 0) {      
      socketRef.current.send(JSON.stringify({
        type: 'send.message',
        data: {
          message: newMessage,
          // messageGroupID: messageGroupID,
          sender: userData?.user_details.id,
          user2_public_unique_id: props.activeChatMetaData?.user_details?.public_unique_id,
        }
      }))

      setNewMessage('')
    }
  }

  
  const handleLoadMore = async () => {
    try {
      if (!isLoadingMore && isInitialMessageReady) {
        console.log(maxPageNo)
        console.log(pageNo)
        
        if (maxPageNo >= pageNo ) {
          setIsLoadingMore(true)
    
          await sleep(10)
          const response = await get_messages(messageGroupID, pageNo)
          const res = await response.json()
          console.log(res.results)
  
          setMessages([...messages, ...res.results])
          setIsLoadingMore(false)
          setPageNo(pageNo+1)
        
        }
      } else {
        setIsInitialMessageReady(true)
      }
    } catch (error) {
      
    }
    
  }



  const handleTextareaChange = (e: any) => {
    setNewMessage(e.target.value);
    const lineHeight = parseInt(window.getComputedStyle(e.target).lineHeight);
    const lines = Math.min(4, Math.ceil(e.target.scrollHeight / lineHeight));
    setTextareaHeight(`${lines * lineHeight}px`);
    // const lineHeight = parseInt(window.getComputedStyle(e.target).lineHeight);
    // const lines = Math.min(4, Math.ceil(e.target.scrollHeight / lineHeight));
    // setTextareaHeight(`${lines * lineHeight}px`);
  };

  const handleBlur = (e: any) => {
    setTextareaHeight('42px'); // Reset height to initial height on blur
  };

  const handleEmojiClick = (emojiObj: any) => {
    // console.log('emoji', emojiObj);
    setNewMessage(newMessage + emojiObj.emoji)
  }


  return (
    <div className='anyjob-message-container'>
      <div className='anyjob-message-conversation-container'>
        <div className='anyjob-message-conversation-container-header'>
          <div className='anyjob-message-conversation-container-header-avatar'>
          <Avatar 
            uri={props.activeChatMetaData?.profile_details?.profile_picture_url || ''}
          />
          </div>
          <div className='anyjob-message-conversation-recipient-short-details'>
            <p className='anyjob-message-conversation-recipient-name'>
            {props.activeChatMetaData?.user_details?.is_client ? props.activeChatMetaData?.profile_details?.business_name : `${props.activeChatMetaData?.user_details.last_name} ${props.activeChatMetaData?.user_details.first_name}`}
            </p>
            <p className='anyjob-message-conversation-recipient-available'>
              {props?.activeChatMetaData?.user_details?.is_online ?  `Online` : "Offline"}
            </p>
          </div>
        </div>
        <div className='anyjob-message-conversation-content'>
          { 
            Boolean(messages?.length) &&
            messages.map((item: any, index: number) => {
              if (item.sender?.public_unique_id === props.activeChatMetaData?.user_details?.public_unique_id) {
                return (
                  <SenderMessageContainer 
                    messageObj={item}
                    key={index}
                  />
                )
              } else {
                return (
                  <MyMessageContainer 
                    messageObj={item}
                    key={index}
                  />
                )
              }
            })
          }
          
        </div>
        <div className='anyjob-message-conversation-input-container'>
          <img 
            src={EmojiIcon}
            alt="Emojis"
            className='anyjob-message-conversation-input-container-icons'
            onClick={() => setIsEmojiModalOpen(true)}
          />
          <img 
            src={PaperClip}
            alt="Attach File"
            className='anyjob-message-conversation-input-container-icons'
            style={{
              marginLeft: 20,
            }}
          />
          <textarea 
            value={newMessage}
            className='anyjob-message-conversation-input-field'
            placeholder='Start typing here'
            onChange={handleTextareaChange}
            style={{ height: textareaHeight }}
            onBlur={handleBlur}
          />
          <img 
            src={SendArrowIcon}
            alt="Send Message"
            className='anyjob-message-conversation-input-container-icons'
            onClick={handleSubmit}
          />
        </div>
      </div>
      
      <Modal
        isOpen={emojiModalIsOpen}
        onRequestClose={() => setIsEmojiModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <EmojiPicker 
            onEmojiClick={handleEmojiClick}
          />
        </div>
    </Modal>
    </div>
  )
}

