import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';

import './ChangePassword.css'

import { Check, User, X } from "phosphor-react";
import LoadingModal from "../LoadingModal";
import getErrorMessage from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DefaultStateType } from "../../redux/types";
import { change_password, } from "../../api/profile";
import { get_user_details } from "../../api/auth";
import { storeUserData } from "../../redux/userData";
import { BASE_URL } from "../../env";
import { containsNumber, containsSpecialChars, hasLowerCase, hasUpperCase } from "../../utils/strings";
import { saveSecureData } from "../../utils/handleStorage";
import { setActiveWallet } from "../../redux/wallet";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};

const ChangePassword = forwardRef((props: any, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
  })
  const [errMsg, setErrMsg] = useState({
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
  })
  const [onClose, setOnClose] = useState<((status: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);
  const [isNewPasswordFocus, setIsNewPasswordFocus] = useState(false)
  const [isNewPassword2Focus, setIsNewPassword2Focus] = useState(false)
  const [isOldPasswordFocus, setIsOldPasswordFocus] = useState(false)
  const [passwordChecks, setPasswordChecks] = useState({
    upperCase: false,
    lowerCase: false,
    digit: false,
    specialChar: false,
    length: false,
  })
  const [showPassword, setShowPassword] = useState(false);

  const loadingModalRef = useRef<any>()

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const dispatch = useDispatch()


  const show = useCallback(( cb?: (status: 'success' | 'failed' | 'canceled') => void, education: any=null) => {
    
    if (cb) {
      setOnClose(() => cb);
    }

    setInputValues((prevState: any) => ({
      ...prevState,
      oldPassword: '',
      newPassword: ''
    }))
    
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      oldPassword: '',
      newPassword: ''
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

    setErrMsg({
      ...errMsg,
      [name]: ""
    });

    if (name === 'newPassword') {
      evalPassword(value)
    }


  }

  

  const validateForm = () => {
    var isValid = true
    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()

      if (!evalPassword(inputValues.newPassword, true)) {
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      if (inputValues.newPassword !== inputValues.newPassword2) {
        toast.warn("Passwords does not match", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadingModalRef.current?. hideLoadingModal()
        return
      }




      var params = {
        email: userData.user_details?.email,
        old_password: inputValues.oldPassword,
        new_password: inputValues.newPassword,
      }

      const res = await change_password(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        
        saveSecureData("token", response.data.token || "")           
        setIsModalOpen(false)
        loadingModalRef.current?. hideLoadingModal()
 
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setInputValues((prevState: any) => ({
          ...prevState,
          bankName: '',
          accountNo: ''
        }))
        // props.increaseTabProgress()
        // props.goToNext('account-verification')
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response?.message || response?.detail, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const updateUserData = async () => {
    try {
      const res = await get_user_details()
      if (res.ok) {
        const response = await res.json()
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))
        dispatch(setActiveWallet(response.data.user_data.wallet))
      } else {
        // const response = await res.json()
        // console.log('response', response);
        // dispatch(logOut({}))
        
      }
    } catch (error) {
      
    }
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const evalPassword = (password: string, showErrorMsg = false) => {
    if (hasLowerCase(password)) {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        lowerCase: true
      }))
    } else {
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        lowerCase: false
      }))

      if (showErrorMsg) {
        toast.warn("Your password must include at least one lowercase letter", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (hasUpperCase(password)) {     
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        upperCase: true
      }))
    } else {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        upperCase: false
      }))
      if (showErrorMsg) {
        toast.warn("Your password must include at least one uppercase letter", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (containsNumber(password)) {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        digit: true
      }))
    } else {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        digit: false
      }))
      if (showErrorMsg) {
        toast.warn("Your password must include at least one number", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (containsSpecialChars(password)) {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        specialChar: true
      }))
    } else {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        specialChar: false
      }))

      if (showErrorMsg) {
        toast.warn("Your password must include at least one special character", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    if (password.length >= 8) {
      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        length: true
      }))
    } else {      
      setPasswordChecks((prevState: typeof passwordChecks) => ({
        ...prevState,
        length: false
      }))

      if (showErrorMsg) {
        toast.warn("Your password must contains at least 8 letters", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false
      }
    }

    return true
  }

  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Add Education"
      contentElement={() => {
        return (
          <div className="anyjob-edit-profile-details-modal">
            <div className="anyjob-edit-profile-details-modal-contents">
              <div className="anyjob-edit-profile-details-modal-contents-header">
                <p>Change Password </p>
                <div className="anyjob-edit-profile-details-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-edit-profile-details-modal-close-icon"
                  />
                </div>
                
              </div>

              <div className="anyjob-edit-profile-details-modal-contents-sub-form-section">
                <form className="anyjob-edit-profile-details-modal-form" onSubmit={handleSubmit}>
                <span className="anyjob-auth-page-input-span" style={{marginTop: 40}}>
                  <label className="anyjob-auth-page-input-label">Old Password</label>
                  <span className={isOldPasswordFocus ? "anyjob-auth-page-password-input-focus" : "anyjob-auth-page-password-input"}>
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.oldPassword}
                      required
                      name="oldPassword"
                      autoComplete='off'
                      onBlur={() => setIsOldPasswordFocus(false)}
                      onFocus={() => setIsOldPasswordFocus(true)}
                      // className="anyjob-auth-page-input"
                    />
                    <p onClick={handleTogglePassword} className="anyjob-auth-page-toggle-password">
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>                                    
                </span>
                {
                  Boolean(errMsg.oldPassword.length) && (
                    <p className="anyjob-auth-input-err-msg">
                      {errMsg.oldPassword}
                    </p>
                  )
                }
                <span className="anyjob-auth-page-input-span">
                  <label className="anyjob-auth-page-input-label">New Password</label>
                  <span className={isNewPasswordFocus ? "anyjob-auth-page-password-input-focus" : "anyjob-auth-page-password-input"}>
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.newPassword}
                      required
                      name="newPassword"
                      autoComplete='off'
                      onBlur={() => setIsNewPasswordFocus(false)}
                      onFocus={() => setIsNewPasswordFocus(true)}
                      // className="anyjob-auth-page-input"
                    />
                    <p onClick={handleTogglePassword} className="anyjob-auth-page-toggle-password">
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>                                    
                </span>
                <div className="anyjob-auth-page-password-validations-group">
                  <div className={passwordChecks.length ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>8 characters</p>
                  </div>
                  <div className={passwordChecks.lowerCase ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 lowercase letter</p>
                  </div>
                  <div className={passwordChecks.upperCase ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 uppercase letter</p>
                  </div>

                  <div className={passwordChecks.specialChar ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 symbol</p>
                  </div>
                  <div className={passwordChecks.digit ? "anyjob-auth-page-password-validation-active" : "anyjob-auth-page-password-validation"}>
                    <Check size={12} color={passwordChecks.length ? "#06BE54" : "#555555"}/>
                    <p>1 number</p>
                  </div>
                </div>
                {
                  Boolean(errMsg.newPassword.length) && (
                    <p className="anyjob-auth-input-err-msg">
                      {errMsg.newPassword}
                    </p>
                  )
                }
                <span className="anyjob-auth-page-input-span" style={{marginTop: 30}}>
                  <label className="anyjob-auth-page-input-label">New Password Again</label>
                  <span className={isNewPassword2Focus ? "anyjob-auth-page-password-input-focus" : "anyjob-auth-page-password-input"}>
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.newPassword2}
                      required
                      name="newPassword2"
                      autoComplete='off'
                      onBlur={() => setIsNewPassword2Focus(false)}
                      onFocus={() => setIsNewPassword2Focus(true)}
                      // className="anyjob-auth-page-input"
                    />
                    <p onClick={handleTogglePassword} className="anyjob-auth-page-toggle-password">
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>                                    
                </span>
                {
                  Boolean(errMsg.oldPassword.length) && (
                    <p className="anyjob-auth-input-err-msg">
                      {errMsg.oldPassword}
                    </p>
                  )
                }

                  <span className="anyjob-edit-profile-details-modal-form-actions">
                    <div className="anyjob-edit-profile-details-modal-form-actions-cancel" onClick={hide}>
                      Close
                    </div>
                    <input 
                      value="Save"
                      type="submit"
                      className="anyjob-edit-profile-details-modal-form-actions-save"
                    />
                  </span>
                </form>
              </div>

            </div>
          </div>
        )
      }}
    >
      <></>
    </Modal>
    <LoadingModal ref={loadingModalRef} />
    </>
  )
})

export default ChangePassword