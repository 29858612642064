
import { BASE_URL } from '../env'
import { authApiFetch, } from './utils'


export const get_messages = async (user2_public_unique_id: any, page_no:any) => {
  var url = `${BASE_URL}/api/chats/get_messages?user2_public_unique_id=${user2_public_unique_id}&page=${page_no}`

  const response = await authApiFetch(url, {
    method: 'GET',
    headers: {      
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  })

  return response
}

export const get_all_ongoing_chats = async () => {
  var url = BASE_URL + '/api/chats/get_all_ongoing_chats'

  const response = await authApiFetch(url, {
    method: 'GET',
    headers: {      
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  })

  return response
}