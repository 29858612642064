import {combineReducers} from 'redux'

import { userDataReducer } from './userData'
import { walletReducer } from './wallet'


const reducer = combineReducers({
	userData: userDataReducer,
	wallet: walletReducer,
})

export default reducer