import * as React from "react"
import { IconType } from "./types"

const JobsIcon = (props: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill={props.isActive ? "#1A1A1A" : "#ACACAC"}
      d="m18.568 17.683-1.588-1.587c.328-.495.521-1.085.521-1.721a3.129 3.129 0 0 0-3.125-3.125 3.129 3.129 0 0 0-3.125 3.125 3.129 3.129 0 0 0 3.125 3.125c.636 0 1.227-.193 1.72-.52l1.588 1.587a.624.624 0 1 0 .884-.884ZM12.5 14.375c0-1.034.841-1.875 1.875-1.875s1.875.841 1.875 1.875a1.877 1.877 0 0 1-1.875 1.875 1.877 1.877 0 0 1-1.875-1.875ZM1.251 7.043V6.25c0-.69.56-1.25 1.25-1.25h11.875c.69 0 1.25.56 1.25 1.25v.79c-.832.139-1.666.254-2.5.346v-.824a.313.313 0 0 0-.313-.312h-.625a.313.313 0 0 0-.312.313v.945a42.88 42.88 0 0 1-7.5-.052v-.894a.313.313 0 0 0-.313-.312h-.625a.313.313 0 0 0-.312.313v.752c-.626-.078-1.25-.17-1.875-.272Z"
    />
    <path
      fill={props.isActive ? "#1A1A1A" : "#ACACAC"}
      d="M10.512 15.624H2.5c-.69 0-1.25-.56-1.25-1.25V7.99c.624.1 1.25.19 1.875.267v.804c0 .173.14.313.312.313h.625c.173 0 .313-.14.313-.313V8.4c2.678.249 5.003.244 7.5.047v.615c0 .173.14.313.313.313h.624c.173 0 .313-.14.313-.313v-.733c.835-.09 1.668-.205 2.5-.34v2.521a4.052 4.052 0 0 0-1.59-.183c-1.94.162-3.546 1.768-3.708 3.708a4.052 4.052 0 0 0 .184 1.59ZM5.001 4.063V2.5c0-.69.56-1.25 1.25-1.25h4.375c.69 0 1.25.56 1.25 1.25v1.563h-1.25V2.5H6.251v1.563h-1.25Z"
    />
  </svg>
)
export default JobsIcon