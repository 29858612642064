import React, { useEffect, useRef, useState }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { X, PencilSimple, CaretLeft, Plus } from "phosphor-react";

import './Onboarding.css'
import { DefaultStateType } from "../../redux/types";
import { PersonalInfoProps } from "./types";
import LoadingModal from '../../components/LoadingModal'
import { toast } from "react-toastify";
import getErrorMessage from "../../utils/getErrorMessage";
import { AddItemCardButton, AddItemCardButtonNoLabel } from "../../components/AddItemCardButton/AddCardItemButton";
import AddWorkExperience from "../../components/AddWorkExperience/AddWorkExperience";
import { add_multiple_user_skill, get_user_education_history, get_user_work_history, search_skills } from "../../api/profile";
import AddEducation from "../../components/AddEducation/AddEducation";
import { sleep } from "../../utils/sleep";

type SkillType = {id: number, title: string}

export default function ProfessionalInfo(props: PersonalInfoProps) {
  const [workExperiences, setWorkExperiences] = useState<any[]>([])
  const [educations, setEducations] = useState<any[]>([])
  const [inputValues, setInputValues] = useState({
    skillsSearchQuery: ''
  })
  const [isSkillsSearchFocused, setIsSkillsSearchFocused] = useState(false)
  const [skillsSuggestions, setSkillsSuggestions] = useState([])
  const [showSkillsSuggestions, setShowSkillsSuggestions] = useState(false)
  const [selectedSkills, setSelectedSkills] = useState<SkillType[]>([])
  const [errMsg, setErrMsg] = useState({
    workExperience: '',
    education: '',
    skills: '',
  })


  const userData = useSelector((state: DefaultStateType) => state.userData);

  const dispatch = useDispatch()
  const addWorkExperienceRef = useRef<any>()
  const addEducationRef = useRef<any>()
  const loadingModalRef = useRef<any>()

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    try {
      await fetchWorkExperiences()
      await fetchEducation()
    } catch (error) {
      
    }
  }

  const fetchWorkExperiences = async () => {
    try {
      const res = await get_user_work_history()
      const response = await res.json()
      if (res.ok) {
        setWorkExperiences(response.data.work_histories)
      }
    } catch (error) {
      
    }
  }

  const fetchEducation = async () => {
    try {
      const res = await get_user_education_history()
      const response = await res.json()
      if (res.ok) {
        setEducations(response.data.education_histories)
      }
    } catch (error) {
      
    }
  }

  const onCloseWorkModal = async (status: 'success' | 'failed' | 'canceled') => {
    switch (status) {
      case 'success':
        await fetchWorkExperiences()
        break;
    
      default:
        break;
    }
  }

  const onCloseEducationModal = async (status: 'success' | 'failed' | 'canceled') => {
    switch (status) {
      case 'success':
        await fetchEducation()
        break;
    
      default:
        break;
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

    if (name === "skillsSearchQuery") {
      if (value.length % 2 == 0) {
        searchSkills(value)
      }
    }

  }

  const searchSkills = async (query: string) => {
    try {
      const res = await search_skills(query)
      const response = await res.json()
      if (res.ok) {
        setSkillsSuggestions(response.data.skills)
        if (Boolean(response.data.skills.length)) {
          setShowSkillsSuggestions(true)
        }
        
      }
    } catch (error) {
      
    }
  }

  const isSkillTypeExist = (newSkillType: SkillType): boolean => {
    return selectedSkills.some(
      (existingSkillType) =>
        existingSkillType.id === newSkillType.id &&
        existingSkillType.title === newSkillType.title
    );
  };

  const selectSkill = async (skill: SkillType) => {
    console.log('selectedSkills.includes(skill)', selectedSkills.includes(skill));
    
    if (!isSkillTypeExist(skill)) {
      setSelectedSkills((prevState: SkillType[]) => ([
        ...prevState,
        skill
      ]))
    }    
    await sleep(100)
    

    setShowSkillsSuggestions(false)
    setInputValues({
      ...inputValues,
      skillsSearchQuery: ''
    });
  }

  const removeSkill = (skillToRemove: SkillType): void => {
    const updatedSkills = selectedSkills.filter(
      (skill) => skill.id !== skillToRemove.id
    );

    // Update the state with the filtered array
    setSelectedSkills(updatedSkills);
  };

  const validateProfessionalInfo = () => {
    var isValid = true

    if (workExperiences.length <= 0) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        workExperience: "You need to provide at least one work experience"
      }))
    }

    if (educations.length <= 0) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        education: "You need to provide at least one relevant education"
      }))
    }

    if (selectedSkills.length < 3 ) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        skills: "You need to provide at least 3 relevant skills"
      }))
    }

    if (selectedSkills.length > 20 ) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        skills: "You not allowed to have more than 20 skills, please select your top 20 skills"
      }))
    }

    return isValid
  }

  const nextTab = async () => {
    loadingModalRef.current.showLoadingModal()
    if (!validateProfessionalInfo()) {
      loadingModalRef.current?. hideLoadingModal()
      return 
    }

    try {
      var params = {
        skills: selectedSkills,
      }

      const res = await add_multiple_user_skill(params)
      const response = await res.json()

      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
        props.goToNext('payment-info')
        toast.success("Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div className="anyjob-onboarding-page-tab-content">
      <>
        <div className="anyjob-onboarding-page-tab-content-headings-2">
          <p 
            className="anyjob-onboarding-page-tab-content-headings-title"
            style={{
              textAlign: 'left'
            }}
          >
            Work Experience
          </p>
          <p 
            className="anyjob-onboarding-page-tab-content-headings-desc"
            style={{
              textAlign: 'left'
            }}
          >
            If you have relevant work experience, add it here.
          </p>
        </div>
        <div
          className="anyjob-onboarding-page-tab-content-experience-container"
        >
          {
            workExperiences.length ? (
              <>
                <AddItemCardButtonNoLabel 
                  onClick={() => {
                    addWorkExperienceRef.current.show('add', onCloseWorkModal)
                  }}
                />
                <div className="anyjob-onboarding-page-tab-content-experience-items-container">
                  {
                    workExperiences.map((item: any, index: number) => {
                      return (
                        <div className="anyjob-onboarding-page-tab-content-experience-item">
                          <div className="anyjob-onboarding-page-tab-content-experience-item-top-section">
                            <span className="anyjob-onboarding-page-tab-content-experience-item-headings">
                              <p className="anyjob-onboarding-page-tab-content-experience-item-title">
                                {item.role}
                              </p>
                              <span />
                              <p className="anyjob-onboarding-page-tab-content-experience-item-sub-title">
                                {item.company}
                              </p>
                            </span>    
                            <span className="anyjob-onboarding-page-tab-content-experience-item-close-btn">
                              <X className="anyjob-onboarding-page-tab-content-experience-item-close-icon" />
                            </span>                                             
                          </div>
                          <p className="anyjob-onboarding-page-tab-content-experience-item-desc">
                            {item.start_date_month} {item.start_date_year} - {item.is_still_ongoing ? "Till Date" :  `${item.end_date_month} ${item.end_date_year}`}
                          </p>
                          <p className="anyjob-onboarding-page-tab-content-experience-item-desc">
                            {item.location} {item.country}
                          </p>
                          <span className="anyjob-onboarding-page-tab-content-experience-item-actions">
                            <span 
                              className="anyjob-onboarding-page-tab-content-experience-item-btn"
                              onClick={() => {
                                addWorkExperienceRef.current.show('edit', onCloseWorkModal, item)
                              }}
                            >
                              <PencilSimple className="anyjob-onboarding-page-tab-content-experience-item-btn-icon" />
                            </span>
                          </span>
                        </div>
                      )
                    })
                  }
                </div>
              </>
            ) : (
              <AddItemCardButton 
                onClick={() => {
                  addWorkExperienceRef.current.show('add', onCloseWorkModal)
                }}
                label="Add Experience"
              />
            )
          }
          
        </div>
        <div 
          className="anyjob-onboarding-page-add-new-item-container"
          onClick={() => {
            addWorkExperienceRef.current.show('add', onCloseEducationModal)
          }}
        >
          <Plus className="anyjob-onboarding-page-add-new-item-icon" />
          <p className="anyjob-onboarding-page-add-new-item-text">
            Add New
          </p>
        </div>
        {
          Boolean(errMsg.workExperience.length) && (
            <div className="anyjob-onboarding-professional-err-msg">
              {errMsg.workExperience}
            </div>
          ) 
        }
        
      </>
      <div className="anyjob-onboarding-section-divider"/>
      <>
        <div className="anyjob-onboarding-page-tab-content-headings-2">
          <p 
            className="anyjob-onboarding-page-tab-content-headings-title"
            style={{
              textAlign: 'left'
            }}
          >
            Education
          </p>
          <p 
            className="anyjob-onboarding-page-tab-content-headings-desc"
            style={{
              textAlign: 'left'
            }}
          >
            Adding any relevant education helps make your profile more visible
          </p>
        </div>
        <div
          className="anyjob-onboarding-page-tab-content-experience-container"
        >
          {
            educations.length ? (
              <>
                <AddItemCardButtonNoLabel 
                  onClick={() => {
                    addEducationRef.current.show('add', onCloseEducationModal)
                  }}
                />
                <div className="anyjob-onboarding-page-tab-content-experience-items-container">
                  {
                    educations.map((item: any, index: number) => {
                      return (
                        <div className="anyjob-onboarding-page-tab-content-experience-item">
                          <div className="anyjob-onboarding-page-tab-content-experience-item-top-section">
                            <span className="anyjob-onboarding-page-tab-content-experience-item-headings">
                              <p className="anyjob-onboarding-page-tab-content-experience-item-title">
                                {item.school}
                              </p>
                              <span />
                              <p className="anyjob-onboarding-page-tab-content-experience-item-sub-title">
                                {item.course}
                              </p>
                            </span>    
                            <span className="anyjob-onboarding-page-tab-content-experience-item-close-btn">
                              <X className="anyjob-onboarding-page-tab-content-experience-item-close-icon" />
                            </span>                                             
                          </div>
                          <p className="anyjob-onboarding-page-tab-content-experience-item-desc">
                            {item.degree}
                          </p>
                          <p className="anyjob-onboarding-page-tab-content-experience-item-desc">
                            {item.start_year} - {item.is_still_ongoing ? "Till Date" :  item?.end_year} 
                          </p>
                          <span className="anyjob-onboarding-page-tab-content-experience-item-actions">
                            <span 
                              className="anyjob-onboarding-page-tab-content-experience-item-btn"
                              onClick={() => {
                                addEducationRef.current.show('edit', onCloseEducationModal, item)
                              }}
                            >
                              <PencilSimple className="anyjob-onboarding-page-tab-content-experience-item-btn-icon" />
                            </span>
                          </span>
                        </div>
                      )
                    })
                  }
                </div>
              </>
            ) : (
              <AddItemCardButton 
                onClick={() => {
                  addEducationRef.current.show('add', onCloseEducationModal)
                }}
                label="Add Education"
              />
            )
          }
          
        </div>
        <div 
          className="anyjob-onboarding-page-add-new-item-container"
          onClick={() => {
            addEducationRef.current.show('add', onCloseEducationModal)
          }}
        >
          <Plus className="anyjob-onboarding-page-add-new-item-icon" />
          <p className="anyjob-onboarding-page-add-new-item-text">
            Add New
          </p>
        </div>
        {
          Boolean(errMsg.education.length) && (
            <div className="anyjob-onboarding-professional-err-msg">
              {errMsg.education}
            </div>
          ) 
        }
      </>
      <div className="anyjob-onboarding-section-divider"/>
      <div className="anyjob-onboarding-page-skills-section">
        <div className="anyjob-onboarding-page-tab-content-headings-2">
          <p 
            className="anyjob-onboarding-page-tab-content-headings-title"
            style={{
              textAlign: 'left'
            }}
          >
            Skills
          </p>
          <p 
            className="anyjob-onboarding-page-tab-content-headings-desc"
            style={{
              textAlign: 'left'
            }}
          >
            List the skills related to the services you're offering.
          </p>
        </div>

        <p className="anyjob-onboarding-page-skills-auto-complete-label">
          Your Skills
        </p>
        <div 
          className={isSkillsSearchFocused ? "anyjob-onboarding-page-skills-auto-complete-container-focused" : "anyjob-onboarding-page-skills-auto-complete-container"}
        >
          
          <input 
            type="text"
            placeholder="Add Skill e.g. Visual Design"
            className="anyjob-onboarding-page-skills-auto-complete-input-field"
            name="skillsSearchQuery"
            value={inputValues.skillsSearchQuery}
            onBlur={() => setIsSkillsSearchFocused(false)}
            onFocus={() => setIsSkillsSearchFocused(true)}
            onChange={handleInputChange} 
            disabled={selectedSkills.length >= 20}
          />
        </div>
        <p 
          className="anyjob-onboarding-page-skill-warning"
        >
          Min 3 and max 20 skills
        </p>
        {
          Boolean(errMsg.skills.length) && (
            <div className="anyjob-onboarding-professional-err-msg">
              {errMsg.skills}
            </div>
          ) 
        }
        {
          showSkillsSuggestions && (
            <div className="anyjob-onboarding-page-skills-suggestions-container">
              {
                skillsSuggestions.map((item: SkillType, index: number) => {
                  return (
                    <p 
                      className="anyjob-onboarding-page-skills-suggestion"
                      onClick={() => selectSkill(item)}
                      key={index}
                    >
                      {item.title}
                    </p>
                  )
                })
              }
            </div>
          )
        }

        {
          Boolean(selectedSkills.length) && (
            <div className="anyjob-onboarding-page-selected-skills">
              {
                selectedSkills.map((item: SkillType, index: number) => {
                  return (
                    <div
                      key={index}
                      className="anyjob-onboarding-page-selected-skill"
                    >
                      <p>{item.title}</p>
                      <div className="anyjob-onboarding-page-selected-skill-remove-btn" onClick={() => removeSkill(item)}>
                      <X className="anyjob-onboarding-page-selected-skill-remove-btn-icon"
                       />
                      </div>
                      
                    </div>
                  )
                })
              }
            </div>
          )
        }
        
      </div>
      <div className="anyjob-onboarding-page-controls">
        <div className="anyjob-onboarding-page-back-btn"
          onClick={() => props.goToNext("personal-info")}
        >
          <CaretLeft className="anyjob-onboarding-page-back-btn-icon" />
          Back 
        </div>
        <button 
          className="anyjob-onboarding-page-cont-btn"
          onClick={nextTab}
        >Continue </button>
      </div>
      
      

    <AddWorkExperience 
      ref={addWorkExperienceRef}
      goToNext={props.goToNext} 
      increaseTabProgress={props.increaseTabProgress}  
    />

    <AddEducation 
      ref={addEducationRef}
      goToNext={props.goToNext} 
      increaseTabProgress={props.increaseTabProgress}  
    />
    <LoadingModal ref={loadingModalRef} />
    </div>
  )
}