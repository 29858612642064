import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';

import './PaymentDetailsModal.css'
import { X } from "phosphor-react";
import countries from "../../utils/countries";
import LoadingModal from "../LoadingModal";
import getErrorMessage from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { create_work_history, get_banks_via_maplerad, get_banks_via_paystack, resolve_nigeria_bank_via_maplerad, resolve_nigeria_bank_via_paystack, save_account_details, update_work_history } from "../../api/profile";
import { ActiveTabType } from "../../pages/Onboarding/types";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};


type PaymentDetailsModalProps = {
  onDone: () => void,
}

const PaymentDetailsModal = forwardRef((props: PaymentDetailsModalProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    bankName: '',
    accountNo: ''
  })
  const [errMsg, setErrMsg] = useState({
    bankName: '',
    accountNo: ''
  })

  const [onClose, setOnClose] = useState<((status: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);
  const [action, setAction] = useState<'add' | 'edit'>('add')
  const [banks, setBanks] = useState<any[]>([])
  const [isAccountVerified, setIsAccountVerified] = useState(false)
  const [accountDetails, setAccountDetails] = useState<any>()
  const [isAcctNoFocused, setIsAcctNoFocused] = useState(false)

  const loadingModalRef = useRef<any>()

  const IS_PAYSTACK_LIVE = true


  const show = useCallback(( cb?: (status: 'success' | 'failed' | 'canceled') => void, experience: any=null) => {    
    if (cb) {
      setOnClose(() => cb);
    }

    fetch_banks()
    
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      bankName: '',
      accountNo: ''
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const fetch_banks =async () => {
    try {
      const res = await get_banks_via_paystack('nigeria', IS_PAYSTACK_LIVE)
      console.log('res status', res.status);            
      const response = await res.json()
      console.log('response', response);

      if (res.ok) {   
        setBanks(response.data)
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
    } catch (error) {
      
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
    
    setErrMsg({
      ...errMsg,
      [name]: ""
    });
    setIsAccountVerified(false)

  }



  const validateForm = () => {
    var isValid = true
    if (!Boolean(inputValues.bankName.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        bankName: "Please select a bank"
      }))
    }
    if (!Boolean(inputValues.accountNo.length) || inputValues.accountNo.length < 10) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        accountNo: "Invalid account number"
      }))
    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      if (!isAccountVerified) {
        loadingModalRef.current?. hideLoadingModal()
        setErrMsg((prevState: any) => ({
          ...prevState,
          accountNo: "Please verify your account number"
        }))
        return 
      }

      var params = {
        account_details: {
          bank_name: inputValues.bankName,
          account_number: accountDetails.account_number,
          account_name: accountDetails.account_name,
          bank_code: (banks.find(obj => obj.name === inputValues.bankName)).code,
        }        
      }

      console.log('params', params);
      

      const res = await save_account_details(params)
      
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
 
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props?.onDone()
        hide()
        // props.goToNext('account-verification')
      } else {
        const response = await res.json()
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  const verifyAccountNo = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!Boolean(inputValues.accountNo.length) || inputValues.accountNo.length < 10) {
        console.log('inputValues.accountNo.length', inputValues.accountNo.length);
        
        setErrMsg((prevState: any) => ({
          ...prevState,
          accountNo: "Invalid account number"
        }))
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        is_live: IS_PAYSTACK_LIVE,
        account_number: inputValues.accountNo,
        bank_code: (banks.find(obj => obj.name === inputValues.bankName)).code,
      }
      

      const res = await resolve_nigeria_bank_via_paystack(params)
      const response = await res.json()
      
      if (res.ok) {
        setAccountDetails(response.data)
        setIsAccountVerified(true)
        loadingModalRef.current?. hideLoadingModal()
        toast.success("Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setErrMsg((prevState:any) => ({
          ...prevState,
          accountNo: response.message
        }))
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Bank Information"
      contentElement={() => {
        return (
          <div className="anyjob-add-experience-modal">
            <div className="anyjob-add-experience-modal-contents">
              <div className="anyjob-add-experience-modal-contents-header">
                <p>Bank Account Information </p>
                <div className="anyjob-add-experience-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-add-experience-modal-close-icon"
                  />
                </div>
                
              </div>

              <form className="anyjob-add-experience-modal-form" onSubmit={handleSubmit}>
                <div className="anyjob-onboarding-page-input-group">          
                  <p className="anyjob-onboarding-page-input-group-desc">
                  Bank Name
                  </p>
                  <select
                    onChange={handleInputChange} 
                    value={inputValues.bankName}
                    required
                    name="bankName"
                    className="anyjob-onboarding-page-input-group-input-field"
                    placeholder="e.g. Access Bank"
                  >
                    <option value="" disabled>-- Select Bank Name --</option>
                    {
                      banks.map((item: any, index: number) => {
                        return (
                          <option value={item.name} key={index}>{item.name}</option>
                        )
                      })
                    }
                  </select> 
                  {
                    errMsg.bankName && (
                      <p className="anyjob-onboarding-page-input-err-msg">
                        {errMsg.bankName}
                      </p>
                    )
                  }
                </div>
                <div className="anyjob-onboarding-page-input-group">          
                  <p className="anyjob-onboarding-page-input-group-desc">
                  Account Number
                  </p>
                  <span className={isAcctNoFocused ? "anyjob-onboarding-page-acct-no-group-focused" : "anyjob-onboarding-page-acct-no-group"}>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.accountNo}
                      required
                      name="accountNo"
                      className="anyjob-onboarding-page-acct-no-field"
                      placeholder="e.g. 0123456789"
                      onFocus={() => setIsAcctNoFocused(true)}
                      onBlur={() => setIsAcctNoFocused(false)}
                    />
                    <button 
                      className={isAccountVerified? "anyjob-onboarding-page-acct-no-group-verified-btn" : "anyjob-onboarding-page-acct-no-group-verify-btn"}
                      onClick={verifyAccountNo}
                      disabled={isAccountVerified}
                    >
                      <p>{isAccountVerified ? "Verified" : "Verify"}</p>
                    </button>
                  </span>
                  {
                    isAccountVerified && (
                      <span className="anyjob-onboarding-page-account-name">
                        {accountDetails.account_name}
                      </span>
                    )
                  }
                  
                  {
                    errMsg.accountNo && (
                      <p className="anyjob-onboarding-page-input-err-msg">
                        {errMsg.accountNo}
                      </p>
                    )
                  }
                </div>
                {/* <span className="anyjob-add-experience-modal-form-input-group">
                  <p className="anyjob-add-experience-modal-form-input-group-label">Role</p>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.role}
                    required
                    name="role"
                    className="anyjob-add-experience-modal-form-input-group-field"
                    placeholder="e.g. Product Designer"
                  />
                </span>
                {
                  errMsg.role && (
                    <p className="anyjob-add-experience-modal-form-err-msg">
                      {errMsg.role}
                    </p>
                  )
                } */}
               
                <span className="anyjob-add-experience-modal-form-actions">
                  <div className="anyjob-add-experience-modal-form-actions-cancel" onClick={hide}>
                    Cancel
                  </div>
                  <input 
                    value="Save"
                    type="submit"
                    className="anyjob-add-experience-modal-form-actions-save"
                  />
                </span>
              </form>
            </div>
          </div>
        )
      }}
    >
      <></>
      
    </Modal>
    
    <LoadingModal ref={loadingModalRef} />
    </>
  )
})

export default PaymentDetailsModal

PaymentDetailsModal.defaultProps = {
  onDone: () => {}
}
