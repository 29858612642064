import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  useNavigate,
  useLocation,
} from "react-router-dom";
import './Onboarding.css'
import { OnboardingMessageProps } from './types'

import InnovativeImg from '../../assets/svg/Innovation.svg'
import WelDoneImg from '../../assets/svg/Stickers.svg'
import { DefaultStateType } from '../../redux/types'
import { completed_onboarding, skip_onboarding } from '../../api/profile'
import { storeUserData } from '../../redux/userData';
import { setActiveWallet } from '../../redux/wallet';


export default function OnboardingMessage(props: OnboardingMessageProps) {
  const userData = useSelector((state: DefaultStateType) => state.userData);

  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch()

  const skipOnboarding = async () => {
    try {
      const res = await skip_onboarding()
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        })) 
        dispatch(setActiveWallet(response.data.user_data.wallet))
        navigate(`/jobs`)
      }
    } catch (error) {
      
    }
  }

  const completeOnboarding = async () => {
    try {
      const res = await completed_onboarding()
      const response = await res.json()
      if (res.ok) {
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        })) 
        dispatch(setActiveWallet(response.data.user_data.wallet))
        navigate(`/jobs`)
      }
    } catch (error) {
      
    }
  }

  if (props.activeTab === 'intro') {
    return (
      <div className='anyjob-onboarding-message-container'>       
        <img 
          src={InnovativeImg}
          alt='Start earning'
          className='anyjob-onboarding-message-image'
        />
  
        <p className='anyjob-onboarding-message-title'>
          Hello {userData.user_details?.first_name}! Let’s help you stand out better
        </p>
        <p className='anyjob-onboarding-message-desc'>
          It only takes 2-5 minutes or you can skip and edit it later.
        </p>

        <div className='anyjob-onboarding-message-buttons'>
          
          <button 
            className='anyjob-onboarding-message-skip-btn'
            onClick={skipOnboarding}
          >
            Skip
          </button>
          <button 
            className='anyjob-onboarding-message-primary-btn'
            onClick={() => {
              props.startOnboarding && props.startOnboarding() 
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    )
  } else if (props.activeTab === 'final-msg') {
    return (
      <div className='anyjob-onboarding-message-container'>       
        <img 
          src={InnovativeImg}
          alt='Start earning'
          className='anyjob-onboarding-message-image'
        />
  
        <p className='anyjob-onboarding-message-title'>
          Nice work, {userData.user_details?.first_name} Your profile’s ready.
        </p>
        <p className='anyjob-onboarding-message-desc'>
        Congratulations! Let’s start job hunting.
        </p>

        <div className='anyjob-onboarding-message-buttons'>

          <button 
            className='anyjob-onboarding-message-primary-btn'
            onClick={completeOnboarding}
          >
            Browse Jobs
          </button>
        </div>
      </div>
    )
  }
  return (
    <div className='anyjob-onboarding-message-container'>
      
      
    </div>
  )
}