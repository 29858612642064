import { BASE_URL } from "../env"
import { FetchJobsActionType } from "../pages/Jobs/types"
import { ProposalStatusType } from "../pages/Proposals/types"
import { TasksStatusType } from "../pages/Tasks/types"
import { authApiFetch } from "./utils"

type FetchJobsParamsType = {
  page: number,
  action: FetchJobsActionType,
  query?: string
  mode: string
}

export const fetch_jobs = async (params: FetchJobsParamsType) => {
  try {
    var url = BASE_URL + `/api/jobs/jobs_list?action=${params.action}&page=${params.page}&mode=${params.mode}`

    if (params.action == 'search-only') {
      url = url + `&query=${params.query}`
    }

    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

type FetchProposalsParamsType = {
  page: number,
  status: ProposalStatusType
  start_date: any
  end_date: any
  mode: string
}

export const fetch_proposals = async (params: FetchProposalsParamsType) => {
  try {
    var url = BASE_URL + `/api/jobs/proposals?status=${params.status}&page=${params.page}&start_date=${params.start_date}&end_date=${params.end_date}&mode=${params.mode}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

type FetchTaskParamsType = {
  page: number,
  status: TasksStatusType
  start_date: any
  end_date: any
  mode: string
}

export const fetch_tasks = async (params: FetchTaskParamsType) => {
  try {
    var url = BASE_URL + `/api/jobs/get_tasks?status=${params.status}&page=${params.page}&start_date=${params.start_date}&end_date=${params.end_date}&mode=${params.mode}`
    console.log('url', url);
    
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_job = async (job: string) => {
  try {
    var url = BASE_URL + `/api/jobs/get_job?job=${job}`
    console.log('get_job url', url);
    
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

export const get_task = async (task: string) => {
  try {
    var url = BASE_URL + `/api/jobs/get_task?task=${task}`
    console.log('get_task url', url);
    
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

type SendProposalParams = {
  cover_letter: string,
  unique_id: string,
  attachment?: any,
}

export const send_proposal = async (params: any) => {
  try {
    var url = BASE_URL + `/api/jobs/send_proposal`

    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const submit_task = async (params: any) => {
  try {
    var url = BASE_URL + `/api/jobs/submit_task`

    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const update_task = async (params: any) => {
  try {
    var url = BASE_URL + `/api/jobs/update_task`

    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const send_task_comment = async (params: any) => {
  try {
    var url = BASE_URL + `/api/jobs/send_task_comment`

    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const get_task_comments = async (unique_id: string) => {
  try {
    var url = BASE_URL + `/api/jobs/get_task_comments?unique_id=${unique_id}`

    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}