import React from 'react'

import './ErrorComp.css'

import ErrorIcon from '../../assets/svg/error.svg'

type ErrorCompProps = {
  title?: string,
  desc?: string,
  btnText?: string,
  onClick?: () => void,
}

export default function ErrorComp(props: ErrorCompProps) {
  return (
    <div className='anyjob-error-comp-container'>
      <img 
        src={ErrorIcon}
        alt="Error icon"
      />
      <p className='anyjob-error-comp-container-title'>
        {props?.title || "Oops! Something went wrong."}
      </p>
      <p className='anyjob-error-comp-container-desc'>
        {props?.desc || "We apologize and are fixing the problem. Please try again in a few minute."}
      </p>
      <div className='anyjob-error-comp-btn' onClick={props.onClick}>
        {props.btnText || "Refresh Page"}
      </div>
    </div>
  )
}

ErrorComp.defaultProps = {
  onClick: () => console.log('do nothing'),
}