import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';

import './AddEducation.css'
import { X } from "phosphor-react";
import countries from "../../utils/countries";
import LoadingModal from "../LoadingModal";
import getErrorMessage from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { create_education_history, create_work_history, update_education_history, update_work_history } from "../../api/profile";
import { ActiveTabType } from "../../pages/Onboarding/types";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};


type AddEducationProps = {
  goToNext?: (tabType: ActiveTabType) => void,
  increaseTabProgress?: () => void,
}

const AddEducation = forwardRef((props: AddEducationProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    school: '',
    start_year: '',
    end_year: '',
    degree: '',
    course: '',
    isActiveRole: false,
    description: '',
  })
  const [errMsg, setErrMsg] = useState({
    school: '',
    start_year: '',
    end_year: '',
    degree: '',
    course: '',
    isActiveRole: '',
    description: '',
  })
  const [onClose, setOnClose] = useState<((status: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);
  const [action, setAction] = useState<'add' | 'edit'>('add')
  const [educationID, setEducationID] = useState<any>()

  const loadingModalRef = useRef<any>()


  const show = useCallback((action: 'add' | 'edit', cb?: (status: 'success' | 'failed' | 'canceled') => void, education: any=null) => {
    // console.log('cb', cb);
    setAction(action)
    if (cb) {
      setOnClose(() => cb);
    }

    if (action === 'edit') {
      setInputValues((prevState: any) => ({
        ...prevState,
        school: education?.school || '',
        start_year: education?.start_year || '',
        end_year: education?.end_year || '',
        degree: education?.degree || '',
        course: education?.course || '',
        description: education?.description || '',
        isActiveRole: education?.is_still_ongoing || false,
      }))
      setEducationID(education.id)
    }
    
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      school: '',
      start_year: '',
      end_year: '',
      degree: '',
      course: '',
      isActiveRole: false,
      description: '',
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

    if (name === 'isActiveRole') {
      setInputValues({
        ...inputValues,
        end_year: '',
        [name]: value
      });
    }

  }

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = currentYear; year >= 1960; year--) {
      years.push(year.toString());
    }

    return years
  }

  const validateForm = () => {
    var isValid = true

    if (Boolean(errMsg.school.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        school: "Kindly state the school you attended"
      }))
      isValid = false
    }

    if (Boolean(errMsg.start_year.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        start_year: "Please provide the start year"
      }))
      isValid = false
    }

    if (Boolean(errMsg.degree.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        degree: "Please state the degree acquired"
      }))
      isValid = false
    }

    if (Boolean(errMsg.course.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        course: "Please state your field of study"
      }))
      isValid = false
    }
    if (Boolean(errMsg.description.length)) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        description: "Please provide a description of your role in this position"
      }))
      isValid = false
    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      if (action === 'add') {
        await createEducation()
      } else {
        await editEducation()
      }
    } catch (error) {
    
    }
  }

  const createEducation = async () => {
    try {
      var params = {
        school: inputValues.school,
        start_year: inputValues.start_year,
        end_year: inputValues.end_year,
        degree: inputValues.degree,
        is_still_ongoing: inputValues.isActiveRole,
        course: inputValues.course, 
        description: inputValues.description,
      }

      const res = await create_education_history(params)
      const response = await res.json()

      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsModalOpen(false)
        setInputValues((prevState: any) => ({
          ...prevState,
          school: '',
          start_year: '',
          end_year: '',
          degree: '',
          course: '',
          isActiveRole: false,
          description: '',
        }))
        // @ts-ignore
        onClose('success')
        // @ts-ignore
        props.increaseTabProgress()
        
      } else {
        console.log('response', response);
        
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const editEducation = async () => {
    try {
      var params = {
        school: inputValues.school,
        start_year: inputValues.start_year,
        end_year: inputValues.end_year,
        degree: inputValues.degree,
        is_still_ongoing: inputValues.isActiveRole,
        course: inputValues.course, 
        description: inputValues.description,
        education_id: educationID,
      }

      const res = await update_education_history(params)
      const response = await res.json()

      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsModalOpen(false)
        setInputValues((prevState: any) => ({
          ...prevState,
          school: '',
          start_year: '',
          end_year: '',
          degree: '',
          course: '',
          isActiveRole: false,
          description: '',
        }))
        // @ts-ignore
        onClose('success')
        
      } else {
        console.log('response', response);
        
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  

  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Add Education"
      contentElement={() => {
        return (
          <div className="anyjob-add-experience-modal">
            <div className="anyjob-add-experience-modal-contents">
              <div className="anyjob-add-experience-modal-contents-header">
                <p>{action === 'add' ? "Add Education" : "Edit Education"}</p>
                <div className="anyjob-add-experience-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-add-experience-modal-close-icon"
                  />
                </div>
                
              </div>

              <form className="anyjob-add-experience-modal-form" onSubmit={handleSubmit}>
                <span className="anyjob-add-experience-modal-form-input-group">
                  <p className="anyjob-add-experience-modal-form-input-group-label">School</p>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.school}
                    required
                    name="school"
                    className="anyjob-add-experience-modal-form-input-group-field"
                    placeholder="e.g. University of Lagos"
                  />
                </span>
                {
                  errMsg.school && (
                    <p className="anyjob-add-experience-modal-form-err-msg">
                      {errMsg.school}
                    </p>
                  )
                }
                <span className="anyjob-add-experience-modal-form-input-group">
                  <p className="anyjob-add-experience-modal-form-input-group-label">Course</p>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.course}
                    required
                    name="course"
                    className="anyjob-add-experience-modal-form-input-group-field"
                    placeholder="e.g. Computer Science"
                  />
                </span>
                {
                  errMsg.course && (
                    <p className="anyjob-add-experience-modal-form-err-msg">
                      {errMsg.course}
                    </p>
                  )
                }
                <div className="anyjob-add-experience-modal-form-input-row-group">
                  <span className="anyjob-add-experience-modal-form-input-group anyjob-add-experience-location">
                    <p className="anyjob-add-experience-modal-form-input-group-label">Degree</p>
                    <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.degree}
                      required
                      name="degree"
                      placeholder="e.g. Bachelor of Science (BSc)"
                      className="anyjob-add-experience-modal-form-input-group-field"
                    />
                    {
                      errMsg.degree && (
                        <p className="anyjob-add-experience-modal-form-err-msg">
                          {errMsg.degree}
                        </p>
                      )
                    }
                  </span>
                </div>
                <div className="anyjob-add-experience-modal-form-input-row-group">
                  <span className="anyjob-add-experience-modal-form-input-group anyjob-add-experience-start-date">
                    <p className="anyjob-add-experience-modal-form-input-group-label">Start Year</p>
                    <span className="anyjob-add-experience-date-group">                      
                      <select 
                        onChange={handleInputChange} 
                        value={inputValues.start_year}
                        required
                        name="start_year"
                        placeholder="Year"
                        className="anyjob-add-experience-modal-form-input-group-field"
                      >
                        <option value="" disabled>-- Year --</option>
                        {
                          getYears().map((item: any, index: number) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                      </select>
                    </span>
                    {
                      errMsg.start_year && (
                        <p className="anyjob-add-experience-modal-form-err-msg">
                          {errMsg.start_year}
                        </p>
                      )
                    }
                  </span>
                  <span className="anyjob-add-experience-modal-form-input-group anyjob-add-experience-start-date">
                    <p className="anyjob-add-experience-modal-form-input-group-label">End Year</p>
                    <span className="anyjob-add-experience-date-group">
                     
                      <select 
                        onChange={handleInputChange} 
                        value={inputValues.end_year}
                        // required
                        name="end_year"
                        placeholder="Year"
                        className="anyjob-add-experience-modal-form-input-group-field"
                        disabled={inputValues.isActiveRole}
                      >
                        <option value="" disabled>-- Year -- </option>
                        {
                          getYears().map((item: any, index: number) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                      </select>
                    </span>
                    {
                      errMsg.end_year && (
                        <p className="anyjob-add-experience-modal-form-err-msg">
                          {errMsg.end_year}
                        </p>
                      )
                    }
                  </span>
                </div>
                <span className="anyjob-add-experience-modal-form-input-check">
                  <input 
                    type="checkbox"
                    onChange={handleInputChange} 
                    // @ts-ignore
                    value={inputValues.isActiveRole}
                    name="isActiveRole"
                  />
                  <p>
                    I’m currently attending this school
                  </p>
                </span>

                <span className="anyjob-add-experience-modal-form-input-group">
                  <p className="anyjob-add-experience-modal-form-input-group-label">Description</p>
                  <textarea 
                    onChange={handleInputChange} 
                    value={inputValues.description}
                    required
                    name="description"
                    className="anyjob-add-experience-modal-form-textarea"
                  />
                  {
                    errMsg.description && (
                      <p className="anyjob-add-experience-modal-form-err-msg">
                        {errMsg.description}
                      </p>
                    )
                  }
                </span>
                
                <span className="anyjob-add-experience-modal-form-actions">
                  <div className="anyjob-add-experience-modal-form-actions-cancel" onClick={hide}>
                    Cancel
                  </div>
                  <input 
                    value="Save"
                    type="submit"
                    className="anyjob-add-experience-modal-form-actions-save"
                  />
                </span>
              </form>
            </div>
          </div>
        )
      }}
    >
      <></>
      
    </Modal>
    
    <LoadingModal ref={loadingModalRef} />
    </>
  )
})

export default AddEducation
