import { BASE_URL } from "../env"
import { apiFetch, authApiFetch } from "./utils"

export const get_latest_transactions = async () => {
  try {
    const url = BASE_URL + '/api/wallet/get_latest_transactions'
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

type FetchTransactionsParamsType = {
  page: number,
}

export const fetch_transactions = async (params: FetchTransactionsParamsType) => {
  try {
    var url = BASE_URL + `/api/wallet/transactions?page=${params.page}`

    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
  })
  } catch (error) {
    throw error
  }
}

type RequestWithdrawalParams = {
  amount: string,
  pin: string,
}

export const request_withdrawal = async (params: RequestWithdrawalParams) => {
  try {
    var url = BASE_URL + `/api/wallet/request_withdrawal`

    return await authApiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
  } catch (error) {
    throw error
  }
}