import { decryptVal, encryptVal } from "./encrypt";

export const saveData = async (key: string, value: string) => {
  if (key && value) {
    try {
      await localStorage.setItem(key, value);
    } catch (error) {
      console.log(error)
    }
  }
  
};

export const saveObject = async (key: any, value: any) => {
  try {
    const jsonValue = JSON.stringify(value)
    await localStorage.setItem(key, jsonValue)
  } catch (error) {
    console.log(error);
  }
}

export const saveSecureData = async (key: string, value: string) => {
 
  
  if (key && value) {
    try {
      console.log(key, value);
      console.log(key, encryptVal(value));
      await localStorage.setItem(key, encryptVal(value));
    } catch (error) {
      console.log(error)
    }
  }
  
};

export const saveSecureObject = async (key: any, value: string) => {
  try {
    const jsonValue = JSON.stringify(value)
    await localStorage.setItem(key, encryptVal(jsonValue))
  } catch (error) {
    console.log(error);
  }
}

export const getObject = async (key: string) => {
  
  try {
    const jsonValue = await localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log(e);
  }
};

export const getData = async (key: string) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.log(`${key} not found`);
  }
  
};

export const getSecureObject = async (key: string) => {
  
  try {
    const jsonValue = await localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(decryptVal(jsonValue)) : null;
  } catch (e) {
    console.log(e);
  }
};

export const getSecureData = async (key: string) => {
  try {
    const value = await localStorage.getItem(key);    
    
    if (value !== null) {
      return decryptVal(value);
    }
  } catch (e) {
    console.log(`${key} not found`);
  }
  
};


export const removeData = async (key: string) => {
  try {
    await localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
  
}


export const getToken = async () => {
  var token = await getSecureData('token')
  return `Token ${token}`
}

export const getPlainToken = async () => {
  return await getSecureData('token')
}