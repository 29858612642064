import * as React from "react"
import { IconType } from "./types"

const ProfileIcon = (props: IconType) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={props.size} 
    height={props.size}  
    viewBox="0 0 16 16" 
    fill="none"
  >
    <g fill={props.isActive ? "#1A1A1A" : "#ACACAC"} clipPath="url(#a)">
      <path d="M8.872 8.675a4.195 4.195 0 0 0 3.065-1.27 4.195 4.195 0 0 0 1.27-3.066c0-1.19-.427-2.222-1.27-3.065A4.196 4.196 0 0 0 8.872.004a4.195 4.195 0 0 0-3.066 1.27 4.195 4.195 0 0 0-1.27 3.065c0 1.191.428 2.223 1.27 3.066a4.196 4.196 0 0 0 3.066 1.27ZM16.458 13.846c-.025-.351-.074-.734-.146-1.138a8.952 8.952 0 0 0-.28-1.143 5.645 5.645 0 0 0-.47-1.067 4.027 4.027 0 0 0-.709-.924 3.125 3.125 0 0 0-1.018-.64 3.52 3.52 0 0 0-1.3-.235c-.184 0-.362.076-.705.3a95.76 95.76 0 0 1-.734.472c-.236.15-.555.291-.95.419a3.772 3.772 0 0 1-1.161.188c-.386 0-.777-.064-1.162-.188a4.196 4.196 0 0 1-.949-.418c-.273-.175-.52-.334-.735-.474-.342-.223-.52-.299-.704-.299-.468 0-.905.08-1.3.236a3.123 3.123 0 0 0-1.018.64 4.025 4.025 0 0 0-.709.923 5.659 5.659 0 0 0-.47 1.067 8.98 8.98 0 0 0-.28 1.143c-.072.404-.121.787-.145 1.138-.024.345-.036.702-.036 1.063 0 .94.298 1.7.887 2.261.582.554 1.352.835 2.288.835h8.667c.936 0 1.705-.281 2.287-.835.59-.56.888-1.321.888-2.261 0-.363-.012-.72-.036-1.063Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .004h18v18H0z" />
      </clipPath>
    </defs>
</svg>
)


export default ProfileIcon