import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import Modal from 'react-modal';
import { X } from "phosphor-react";
import OtpInput from 'react-otp-input';

import './VerifyOTPModal.css'

import getErrorMessage from "../../utils/getErrorMessage";
import PINImage from '../../assets/svg/pin.svg'
import { DefaultStateType } from '../../redux/types';
import { initiate_otp_challenge, set_pin, verify_email_or_phone } from '../../api/auth';
import { storeUserData } from '../../redux/userData';
import { removeData, saveSecureData } from '../../utils/handleStorage';

type DisplayType = 'verify-pin' | 'set-pin' | 'verify-otp' | 'set-pin-success'

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};

const VerifyOTPModal =  forwardRef((props: any, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [isOtpResendable, setIsOtpResendable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tmpID, setTempID] = useState("");
  const [onClose, setOnClose] = useState<((status?: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);
  const [inputValues, setInputValues] = useState({
    otp: '',
  })
  const [errMsg, setErrMsg] = useState({
    otp: '',
  })

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const dispatch = useDispatch()

  const show = useCallback(( cb: (status?: 'success' | 'failed' | 'canceled') => void, tempID: string) => {    
    if (cb) {
      setOnClose(() => cb);
    }

    setInputValues((prevState: any) => ({
      ...prevState,
      otp: '',
    }))
    setTempID(tempID)
    setIsModalOpen(true)
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      otp: '',
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const handleSubmit = async () => {
    try {
      if (inputValues.otp.length < 6) {
        setErrMsg((prevState: any) => ({
          ...prevState,
          setPin: "OTP Incomplete"
        }))
        return
      }

      setIsSubmitting(true)

      var params = {
        code: inputValues.otp,
        temp_id: tmpID,
        channel: 'phone-number'
      }
      // @ts-ignore
      const res = await verify_email_or_phone(params)
      const response = await res.json()
      
      if (res.ok) {
        setIsSubmitting(false)
        if (Boolean(onClose)) {
          // @ts-ignore
          onClose('success')
        } 
      } else {
        setErrMsg((prevState: any) => ({
          ...prevState,
          otp: response.message
        }))
      }

      setIsSubmitting(false)
    } catch (error) {
      setErrMsg((prevState: any) => ({
        ...prevState,
        setPinAgain: getErrorMessage(error)
      }))
      setIsSubmitting(false)
    }
  }


  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="OTP Code"
      contentElement={() => {
        return (
          <div className='anyjob-pin-modal'>
            <div className="anyjob-pin-modal-container">
              <div className="anyjob-pin-modal-close-btn" onClick={hide}>
                <X 
                  className="anyjob-pin-modal-close-icon"
                />
              </div>

              <div className='anyjob-pin-modal-contents'>
                <img 
                  src={PINImage}
                  className='anyjob-pin-modal-img-0'
                  alt='PIN illustration'
                />
                <p className='anyjob-pin-modal-title'>
                  Verify OTP Code
                </p>
                <p className='anyjob-pin-modal-sub-title'>
                  Please provide the OTP code sent to your registered email address to complete PIN set up process
                </p>

                <div className='anyjob-pin-modal-input-container'>
                  <p className='anyjob-pin-modal-input-label'>
                    OTP Code
                  </p>
                  <OtpInput
                    value={inputValues.otp}
                    // value="****"
                    onChange={(value: string) => {
                      setInputValues((prevState: any) => ({
                        ...prevState,
                        otp: value
                      }))
                    }}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="anyjob-pin-modal-otp-input"
                    containerStyle="anyjob-pin-modal-otp-input-container"
                    shouldAutoFocus
                    skipDefaultStyles
                    placeholder="000000"
                  />
                  <p className='anyjob-pin-modal-err'>{errMsg.otp}</p>
                </div>
                <button className='anyjob-pin-modal-btn' onClick={handleSubmit} disabled={isSubmitting}>
                  Submit
                </button>
                <span className='anyjob-pin-modal-resend-otp-container'>
                  <button 
                    className={isOtpResendable ? 'anyjob-pin-modal-btn-1' : 'anyjob-pin-modal-btn-1-disabled'}
                    disabled={!isOtpResendable}
                  >
                    Resend OTP Code
                  </button>
                  <p>4:36</p>
                </span>
              </div>
            </div>
          </div>
        )
      }}
    >
      <></>
    </Modal>
    </>
  )
})

export default VerifyOTPModal