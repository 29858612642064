import React, { useEffect, useRef, useState }  from "react";
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';
import 'react-tabs/style/react-tabs.css';

import './Onboarding.css'

import Logo from '../../assets/svg/Logo.svg'
import { ActiveTabType } from "./types";
import PersonalInfo from "./PersonalInfo";
import ProfessionalInfo from "./ProfessionalInfo";
import PaymentInfo from "./PaymentInfo";
import AccountVerification from "./AccountVerification";
import OnboardingMessage from "./OnboardingMessage";

export default function Onboarding (props: any) {
  const [activeTab, setActiveTab] = useState<ActiveTabType>('intro');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tabProgressWidth, setTabProgressWidth] = useState(0)
  const [completedActions, setCompletedActions] = useState(1)

  const totalActions = 5

  useEffect(() => {
    calTabProgressWidth()
  }, [])

  useEffect(() => {
    calTabProgressWidth()
  }, [activeTab, ])

  // Effect to add resize event listener and remove it on component unmount
  useEffect(() => {
    // Initial window width
    setWindowWidth(window.innerWidth);

    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Event handler function to update window width on resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const calTabProgressWidth = () => {
    console.log('calTabProgressWidth', calTabProgressWidth);
    
    
    var progressWidthPerAction = windowWidth/totalActions
    console.log('progressWidthPerAction', progressWidthPerAction);
    switch (activeTab) {
      case "personal-info":
        setTabProgressWidth(progressWidthPerAction*1)
        break;      
      case "professional-info":
        setTabProgressWidth(progressWidthPerAction*2)
        break;
      case "payment-info":
        setTabProgressWidth(progressWidthPerAction*3)
        break;  
      case "account-verification":
        setTabProgressWidth(progressWidthPerAction*4)
        break;
    
      default:
        break;
    }
    

  }

  const goToNext = (tabType: ActiveTabType) => {
    setActiveTab(tabType)
  }

  const increaseTabProgress = () => {
    // setCompletedActions(completedActions + 1)
  }

  return (
    <div className="anyjob-onboarding-page-container">
      <div className="anyjob-onboarding-page-sticky-top">
        <div className="anyjob-onboarding-page-header">    
          <img 
            src={Logo}
            alt="Anyjob"
            className="anyjob-onboarding-page-logo"
          />
        </div>
        {
          activeTab === "intro" || activeTab === 'final-msg' ? (
            <></>
          ) : (
            <>
              <div className="anyjob-onboarding-page-tab-header">        
                <div 
                  className={activeTab === 'personal-info' ? "anyjob-onboarding-page-tab-header-active-item" : "anyjob-onboarding-page-tab-header-item"}
                  // onClick={() => setActiveTab('personal-info')}
                >
                  <div>
                    1
                  </div>
                  <p>
                    Personal Info
                  </p>
                </div>
                {/* <div 
                  className={activeTab === 'professional-info' ? "anyjob-onboarding-page-tab-header-active-item" : "anyjob-onboarding-page-tab-header-item"}
                  // onClick={() => setActiveTab('professional-info')}
                >
                  <div>
                    2
                  </div>
                  <p>
                    Professional Info
                  </p>
                </div> */}
                {/* <div 
                  className={activeTab === "payment-info" ? "anyjob-onboarding-page-tab-header-active-item" : "anyjob-onboarding-page-tab-header-item"}
                  
                >
                  <div>
                    3
                  </div>
                  <p>
                    Payment Info
                  </p>
                </div> */}
                <div 
                  className={activeTab === "account-verification" ? "anyjob-onboarding-page-tab-header-active-item" : "anyjob-onboarding-page-tab-header-item"}
                  // onClick={() => setActiveTab('professional-info')}
                >
                  <div>
                    2
                  </div>
                  <p>
                    Account Verification
                  </p>
                </div>
              </div>
              <div className="anyjob-onboarding-page-tab-progress-bar">
                <div className="anyjob-onboarding-page-tab-progress-indicator" style={{width: tabProgressWidth}}/>
              </div>
            </>
          )
        }
        
      </div>

      {
        activeTab === 'intro' && (
          <OnboardingMessage
            activeTab={activeTab} 
            startOnboarding={() => {
              setActiveTab("personal-info")
            }}  
          />
        )
      }

      {
        activeTab === 'final-msg' && (
          <OnboardingMessage
            activeTab={activeTab} 
            startOnboarding={() => {
              setActiveTab("personal-info")
            }}  
          />
        )
      }

      {
        activeTab === 'personal-info' && (
          <PersonalInfo
            goToNext={goToNext} 
            increaseTabProgress={increaseTabProgress}  
          />
        )
      }

      {
        activeTab === 'professional-info' && (
          <ProfessionalInfo
            goToNext={goToNext} 
            increaseTabProgress={increaseTabProgress}  
          />
        )
      }

      {
        activeTab === 'payment-info' && (
          <PaymentInfo
            goToNext={goToNext} 
            increaseTabProgress={increaseTabProgress}  
          />
        )
      }

      {
        activeTab === "account-verification" && (
          <AccountVerification
            goToNext={goToNext} 
            increaseTabProgress={increaseTabProgress}  
          />
        )
      }
    </div>
  )
}