import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify';
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';

import './Footer.css'

import Logo from '../../assets/svg/Logo-white-icon.svg'
import FaceBookIcon from '../../assets/svg/Facebook Icon.svg'
import InstagramIcon from '../../assets/svg/Instagram Icon.svg'
import LinkedinIcon from '../../assets/svg/LinkendIn Icon.svg'
import TwitterIcon from '../../assets/svg/Twitter Icon.svg'
import { validateEmail } from '../../utils/validateEmail'
import LoadingModal from '../LoadingModal'
import { subscribe_to_newsletters } from '../../api/notifications'

export default function Footer(props: any) {
  const [email, setEmail] = useState("")  

  const [err, setErr] = useState({
    email: "",
  })

  const loadingModalRef = useRef<any>()


  const handleEmailChange = (event: any) => {
    const target = event.target;
    setEmail(target.value)
  }

  const validateForm = () => {
    var isValid = true

    if (Boolean(email.length)) {
      if (!validateEmail(email)) {
        isValid = false
        setErr((prevState: any) => ({
          ...prevState,
          full_name: "Kindly provide a valid email address"
        }))
      }
    } else {
      isValid = false
      setErr((prevState: any) => ({
        ...prevState,
        full_name: "Kindly provide a valid email address"
      }))
    }


    return isValid
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      loadingModalRef.current.showLoadingModal()
     
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        email,
      }

      const res = await subscribe_to_newsletters(params) 

      if (res.ok){
        const response = await res.json()
        setEmail("")
        loadingModalRef.current?. hideLoadingModal()
        toast.success(response.message || "Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const response = await res.json()
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message || "Oops... Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
      
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error("Oops... Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  return (
    <>
    <section className='anyjob-homepage-subscription'>
      <p className='anyjob-homepage-heading anyjob-homepage-subscription-heading'>
      Subscribe to our Newsletter
      </p>
      <p className='anyjob-homepage-desc'>
      Subscribing is quick and easy – just enter your email below and click "Subscribe" to start receiving the best of AnyJob directly in your inbox.
      </p>
      <div className='anyjob-homepage-subscription-input-container'>
        
        <input 
          type='email' 
          value={email} 
          name='email'
          placeholder='Email'
          onChange={handleEmailChange} 
          autoComplete='email'
          required             
        />
        <button onClick={handleSubmit}>
          Subscribe
        </button>
      </div>
      <p className='anyjob-homepage-desc'>
      We will never spam you. Only useful mails with promo and events
      </p>
    </section>
    <div className='anyjob-footer-container'>
      <div className='anyjob-footer-company-info-section'>
        <img 
          src={Logo}
          alt="Anyjob"
          className='anyjob-footer-logo'
        />
        <p className='anyjob-footer-desc-01'>
          AnyJob is the ultimate platform empowering freelancers to flourish in their respective fields.
        </p>
        <div className='anyjob-footer-contact-details'>
          <p className='anyjob-footer-desc-02'>
            Address: Lagos, Nigeria.
          </p>
          <span className='anyjob-footer-divider-01' />
          <p className='anyjob-footer-desc-02'>
            Telephone:   +234 0804 743 6676
          </p>
          <span className='anyjob-footer-divider-01' />
          <p className='anyjob-footer-desc-02'>
            Email: contact@anyjob.com
          </p>
        </div>
        <p className='anyjob-footer-copyright'>
          © Copyright 2023, All Right Reserved Anyjob Global Services LTD.
        </p>
        
      </div>
      <div className='anyjob-footer-ctas'>
      <NavHashLink
        className='anyjob-footer-primary-btn'
        exact to={'/contact-us'} 
        smooth
      >
        Contact Support
      </NavHashLink>
        <div className='anyjob-footer-socials-actions'>
          <a href="#">
            <img 
              src={LinkedinIcon}
              className='anyjob-footer-socials-btn'
              alt='LinkedIn'
            />
          </a>
          <a href="#">
            <img 
              src={FaceBookIcon}
              className='anyjob-footer-socials-btn'
              alt='Facebook'
            />
          </a>
          <a href="#">
            <img 
              src={TwitterIcon}
              className='anyjob-footer-socials-btn'
              alt='TwitterIcon'
            />
          </a>
          <a href="#">
            <img 
              src={InstagramIcon}
              className='anyjob-footer-socials-btn'
              alt='Instagram'
            />
          </a>
        </div>
        
      </div>
    </div>
    <LoadingModal ref={loadingModalRef} />
    </>
  )
}