import { BASE_URL } from "../env"
import { apiFetch, authApiFetch } from "./utils"

export const get_supported_countries = async () => {
  try {
    const url = BASE_URL + '/api/locations/get_supported_countries2'
    
    
    return await apiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
  } catch (error) {
    throw error
  }
}