// @ts-ignore
import { AES, enc } from 'crypto-js';
import { SECRET_KEY } from '../env';

export const encryptVal= (value: string) => {
  const encryptedToken = AES.encrypt(value, SECRET_KEY).toString();
  return encryptedToken;
};

export const decryptVal= (value: string) => {
  const decryptedToken = AES.decrypt(value, SECRET_KEY).toString(enc.Utf8);
  return decryptedToken;
};
