import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
// @ts-ignore
import Modal from 'react-modal';


import { User, X } from "phosphor-react";
import LoadingModal from "../LoadingModal";
import getErrorMessage from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DefaultStateType } from "../../redux/types";
import { edit_user_profile, get_banks_via_maplerad, get_banks_via_paystack, resolve_nigeria_bank_via_maplerad, resolve_nigeria_bank_via_paystack, save_account_details } from "../../api/profile";
import { get_user_details } from "../../api/auth";
import { storeUserData } from "../../redux/userData";
import { BASE_URL } from "../../env";
import { setActiveWallet } from "../../redux/wallet";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)'
  },
};

const EditPaymentDetails = forwardRef((props: any, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    bankName: '',
    accountNo: ''
  })
  const [errMsg, setErrMsg] = useState({
    bankName: '',
    accountNo: ''
  })
  const [banks, setBanks] = useState<any[]>([])
  const [isAccountVerified, setIsAccountVerified] = useState(false)
  const [accountDetails, setAccountDetails] = useState<any>()
  const [isAcctNoFocused, setIsAcctNoFocused] = useState(false)
  const [onClose, setOnClose] = useState<((status: 'success' | 'failed' | 'canceled') => void) | undefined>(undefined);

  const loadingModalRef = useRef<any>()
  const fileInputRef = useRef<any>(null);

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const dispatch = useDispatch()


  const show = useCallback(( cb?: (status: 'success' | 'failed' | 'canceled') => void, education: any=null) => {
    
    if (cb) {
      setOnClose(() => cb);
    }

    setInputValues((prevState: any) => ({
      ...prevState,
      bankName: '',
    accountNo: ''
    }))
    
    setIsModalOpen(true)
    fetch_banks()
  }, [])

  const hide = () => {
    setIsModalOpen(false)
    setInputValues((prevState: any) => ({
      ...prevState,
      bankName: '',
      accountNo: ''
    }))
    if (Boolean(onClose)) {
      // @ts-ignore
      onClose('canceled')
    } 
    
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }))

  const IS_PAYSTACK_LIVE = false

  const fetch_banks =async () => {
    try {
      const res = await get_banks_via_maplerad('NG', IS_PAYSTACK_LIVE)
      const response = await res.json()
      if (res.ok) {   
        setBanks(response.data)
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
    } catch (error) {
      
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

    setErrMsg({
      ...errMsg,
      [name]: ""
    });
    setIsAccountVerified(false)

  }

  

  const validateForm = () => {
    var isValid = true
    if (!Boolean(inputValues.bankName.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        bankName: "Please select a bank"
      }))
    }
    if (!Boolean(inputValues.accountNo.length) || inputValues.accountNo.length < 10) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        accountNo: "Invalid account number"
      }))
    }

    return isValid
  }

  const verifyAccountNo = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!Boolean(inputValues.accountNo.length) || inputValues.accountNo.length < 10) {
        console.log('inputValues.accountNo.length', inputValues.accountNo.length);
        
        setErrMsg((prevState: any) => ({
          ...prevState,
          accountNo: "Invalid account number"
        }))
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        is_live: IS_PAYSTACK_LIVE,
        account_number: inputValues.accountNo,
        bank_code: (banks.find(obj => obj.name === inputValues.bankName)).code,
      }
      

      const res = await resolve_nigeria_bank_via_maplerad(params)
      const response = await res.json()
      
      if (res.ok) {
        setAccountDetails(response.data)
        setIsAccountVerified(true)
        loadingModalRef.current?. hideLoadingModal()
        toast.success("Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setErrMsg((prevState:any) => ({
          ...prevState,
          accountNo: response.message
        }))
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      if (!isAccountVerified) {
        loadingModalRef.current?. hideLoadingModal()
        setErrMsg((prevState: any) => ({
          ...prevState,
          accountNo: "Please verify your account number"
        }))
        return 
      }

      var params = {
        account_details: {
          bank_name: inputValues.bankName,
          account_number: accountDetails.account_number,
          account_name: accountDetails.account_name,
          bank_code: (banks.find(obj => obj.name === inputValues.bankName)).code,
        }        
      }

      const res = await save_account_details(params)
      
      if (res.ok) {
        
        await updateUserData()
        // @ts-ignore
        onClose('success')
        setIsModalOpen(false)
        loadingModalRef.current?. hideLoadingModal()
 
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setInputValues((prevState: any) => ({
          ...prevState,
          bankName: '',
          accountNo: ''
        }))
        // props.increaseTabProgress()
        // props.goToNext('account-verification')
      } else {
        const response = await res.json()
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const updateUserData = async () => {
    try {
      const res = await get_user_details()
      if (res.ok) {
        const response = await res.json()
        dispatch(storeUserData({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))
        dispatch(setActiveWallet(response.data.user_data.wallet))
      } else {
        // const response = await res.json()
        // console.log('response', response);
        // dispatch(logOut({}))
        
      }
    } catch (error) {
      
    }
  }


  return (
    <>
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Add Education"
      contentElement={() => {
        return (
          <div className="anyjob-edit-profile-details-modal">
            <div className="anyjob-edit-profile-details-modal-contents">
              <div className="anyjob-edit-profile-details-modal-contents-header">
                <p>Bank Account Information </p>
                <div className="anyjob-edit-profile-details-modal-close-btn" onClick={hide}>
                  <X 
                    className="anyjob-edit-profile-details-modal-close-icon"
                  />
                </div>
                
              </div>

              <div className="anyjob-edit-profile-details-modal-contents-sub-form-section">
                <form className="anyjob-edit-profile-details-modal-form" onSubmit={handleSubmit}>
                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">Bank Name *</p>
                    <select 
                      onChange={handleInputChange} 
                      value={inputValues.bankName}
                      required
                      name="bankName"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    >
                      <option value="" disabled>-- Select Bank Name --</option>
                      {
                        banks.map((item: any, index: number) => {
                          return (
                            <option value={item.name} key={index}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                    {
                      errMsg.bankName && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.bankName}
                        </p>
                      )
                    }
                  </span>
                  <span className="anyjob-edit-profile-details-modal-form-input-group">
                    <p className="anyjob-edit-profile-details-modal-form-input-group-label">Account Number *</p>
                    {/* <input 
                      onChange={handleInputChange} 
                      type="text"
                      value={inputValues.accountNo}
                      required
                      name="accountNo"
                      className="anyjob-edit-profile-details-modal-form-input-group-field"
                      // placeholder="e.g. University of Lagos"
                    /> */}
                    <span className={isAcctNoFocused ? "anyjob-edit-payment-acct-no-group-focused" : "anyjob-edit-payment-acct-no-group"}>
                      <input 
                        onChange={handleInputChange} 
                        type="text"
                        value={inputValues.accountNo}
                        required
                        name="accountNo"
                        className="anyjob-edit-payment-acct-no-field"
                        placeholder="e.g. 0123456789"
                        onFocus={() => setIsAcctNoFocused(true)}
                        onBlur={() => setIsAcctNoFocused(false)}
                      />
                      <button 
                        className={isAccountVerified? "anyjob-edit-payment-acct-no-group-verified-btn" : "anyjob-edit-payment-acct-no-group-verify-btn"}
                        onClick={verifyAccountNo}
                        disabled={isAccountVerified}
                      >
                        <p>{isAccountVerified ? "Verified" : "Verify"}</p>
                      </button>
                    </span>
                    {
                      isAccountVerified && (
                        <span className="anyjob-edit-payment-account-name">
                          {accountDetails.account_name}
                        </span>
                      )
                    }
                    {
                      errMsg.accountNo && (
                        <p className="anyjob-edit-profile-details-modal-form-err-msg">
                          {errMsg.accountNo}
                        </p>
                      )
                    }
                  </span>

                  <span className="anyjob-edit-profile-details-modal-form-actions">
                    <div className="anyjob-edit-profile-details-modal-form-actions-cancel" onClick={hide}>
                      Close
                    </div>
                    <input 
                      value="Save"
                      type="submit"
                      disabled={!isAccountVerified}
                      className={isAccountVerified ?  "anyjob-edit-profile-details-modal-form-actions-save" : "anyjob-edit-profile-details-modal-form-actions-save-disabled"} 
                    />
                  </span>
                </form>
              </div>

            </div>
          </div>
        )
      }}
    >
      <></>
    </Modal>
    <LoadingModal ref={loadingModalRef} />
    </>
  )
})

export default EditPaymentDetails