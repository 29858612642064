import React, { useEffect, useRef, useState }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { CaretLeft, User } from "phosphor-react";

import './Onboarding.css'
import { DefaultStateType } from "../../redux/types";
import { update_personal_info } from "../../api/auth";
import LoadingModal from '../../components/LoadingModal'
import { toast } from "react-toastify";
import getErrorMessage from "../../utils/getErrorMessage";
import { PersonalInfoProps } from "./types";
import { get_banks_via_maplerad, get_banks_via_paystack, resolve_nigeria_bank_via_maplerad, resolve_nigeria_bank_via_paystack, save_account_details } from "../../api/profile";


export default function PaymentInfo(props: PersonalInfoProps) {
  
  const [inputValues, setInputValues] = useState({
    bankName: '',
    accountNo: ''
  })
  const [errMsg, setErrMsg] = useState({
    bankName: '',
    accountNo: ''
  })

  const [banks, setBanks] = useState<any[]>([])
  const [isAccountVerified, setIsAccountVerified] = useState(false)
  const [accountDetails, setAccountDetails] = useState<any>()
  const [isAcctNoFocused, setIsAcctNoFocused] = useState(false)

  const userData = useSelector((state: DefaultStateType) => state.userData);

  const dispatch = useDispatch()
  const loadingModalRef = useRef<any>()

  const IS_PAYSTACK_LIVE = false

  useEffect(() => {
    fetch_banks()
  }, [])

  const fetch_banks =async () => {
    try {
      const res = await get_banks_via_maplerad('NG', IS_PAYSTACK_LIVE)
      const response = await res.json()
      if (res.ok) {   
        setBanks(response.data)
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
    } catch (error) {
      
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
    
    setErrMsg({
      ...errMsg,
      [name]: ""
    });
    setIsAccountVerified(false)

  }

  const validateForm = () => {
    var isValid = true
    if (!Boolean(inputValues.bankName.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        bankName: "Please select a bank"
      }))
    }
    if (!Boolean(inputValues.accountNo.length) || inputValues.accountNo.length < 10) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        accountNo: "Invalid account number"
      }))
    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      if (!isAccountVerified) {
        loadingModalRef.current?. hideLoadingModal()
        setErrMsg((prevState: any) => ({
          ...prevState,
          accountNo: "Please verify your account number"
        }))
        return 
      }

      var params = {
        account_details: {
          bank_name: inputValues.bankName,
          account_number: accountDetails.account_number,
          account_name: accountDetails.account_name,
          bank_code: (banks.find(obj => obj.name === inputValues.bankName)).code,
        }        
      }

      const res = await save_account_details(params)
      
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
 
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.increaseTabProgress()
        props.goToNext('account-verification')
      } else {
        const response = await res.json()
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const verifyAccountNo = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!Boolean(inputValues.accountNo.length) || inputValues.accountNo.length < 10) {
        console.log('inputValues.accountNo.length', inputValues.accountNo.length);
        
        setErrMsg((prevState: any) => ({
          ...prevState,
          accountNo: "Invalid account number"
        }))
        loadingModalRef.current?. hideLoadingModal()
        return
      }

      var params = {
        is_live: IS_PAYSTACK_LIVE,
        account_number: inputValues.accountNo,
        bank_code: (banks.find(obj => obj.name === inputValues.bankName)).code,
      }
      

      const res = await resolve_nigeria_bank_via_maplerad(params)
      const response = await res.json()
      
      if (res.ok) {
        setAccountDetails(response.data)
        setIsAccountVerified(true)
        loadingModalRef.current?. hideLoadingModal()
        toast.success("Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setErrMsg((prevState:any) => ({
          ...prevState,
          accountNo: response.message
        }))
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  return (
    <div className="anyjob-onboarding-page-tab-content">
      <div className="anyjob-onboarding-page-tab-content-headings">
        <p className="anyjob-onboarding-page-tab-content-headings-title">
          Bank Account Information 
        </p>
        <p className="anyjob-onboarding-page-tab-content-headings-desc">
        Kindly provide your banking information
        </p>
      </div>
    
      <form className="anyjob-onboarding-page-personal-info-form" onSubmit={handleSubmit}> 

        <div className="anyjob-onboarding-page-input-group">          
          <p className="anyjob-onboarding-page-input-group-desc">
          Bank Name
          </p>
          <select
            onChange={handleInputChange} 
            value={inputValues.bankName}
            required
            name="bankName"
            className="anyjob-onboarding-page-input-group-input-field"
            placeholder="e.g. Access Bank"
          >
            <option value="" disabled>-- Select Bank Name --</option>
            {
              banks.map((item: any, index: number) => {
                return (
                  <option value={item.name} key={index}>{item.name}</option>
                )
              })
            }
          </select> 
          {
            errMsg.bankName && (
              <p className="anyjob-onboarding-page-input-err-msg">
                {errMsg.bankName}
              </p>
            )
          }
        </div>
        <div className="anyjob-onboarding-page-input-group">          
          <p className="anyjob-onboarding-page-input-group-desc">
          Account Number
          </p>
          <span className={isAcctNoFocused ? "anyjob-onboarding-page-acct-no-group-focused" : "anyjob-onboarding-page-acct-no-group"}>
            <input 
              onChange={handleInputChange} 
              type="text"
              value={inputValues.accountNo}
              required
              name="accountNo"
              className="anyjob-onboarding-page-acct-no-field"
              placeholder="e.g. 0123456789"
              onFocus={() => setIsAcctNoFocused(true)}
              onBlur={() => setIsAcctNoFocused(false)}
            />
            <button 
              className={isAccountVerified? "anyjob-onboarding-page-acct-no-group-verified-btn" : "anyjob-onboarding-page-acct-no-group-verify-btn"}
              onClick={verifyAccountNo}
              disabled={isAccountVerified}
            >
              <p>{isAccountVerified ? "Verified" : "Verify"}</p>
            </button>
          </span>
          {
            isAccountVerified && (
              <span className="anyjob-onboarding-page-account-name">
                {accountDetails.account_name}
              </span>
            )
          }
          
          {
            errMsg.accountNo && (
              <p className="anyjob-onboarding-page-input-err-msg">
                {errMsg.accountNo}
              </p>
            )
          }
        </div>

        {/* <input 
          type="submit"
          value="Continue"
          className={isAccountVerified ?  "anyjob-onboarding-page-form-submit-btn" : "anyjob-onboarding-page-form-submit-btn-disabled"} 
          disabled={!isAccountVerified}
        /> */}
        
      </form>
        <div className="anyjob-onboarding-page-controls">
          <div className="anyjob-onboarding-page-back-btn"
            onClick={() => props.goToNext("professional-info")}
          >
            <CaretLeft className="anyjob-onboarding-page-back-btn-icon" />
            Back 
          </div>
          <button 
            className={isAccountVerified ?  "anyjob-onboarding-page-form-submit-btn" : "anyjob-onboarding-page-form-submit-btn-disabled"} 
            disabled={!isAccountVerified}
            onClick={handleSubmit}
          >Continue </button>
        </div>
        
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}