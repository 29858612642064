import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';

import './Profile.css'
import Header from '../../components/Header/Header'
import LoadingModal from '../../components/LoadingModal'
import { SubmitProposalProps } from './types';
import getErrorMessage from '../../utils/getErrorMessage';

import DefaultCoverImage from '../../assets/images/default_cover_img.png'
import { BASE_URL } from '../../env';
import { DefaultStateType } from '../../redux/types';

import EditIcon from '../../assets/images/Edit.png'
import EditIcon2 from '../../assets/svg/EditButton.svg'
import LogOutIcon from '../../assets/svg/Sign_out_squre_light.svg'
import KeyIcon from '../../assets/svg/key.svg'
import VerifiedIcon from '../../assets/svg/Shield_check_duotone_line.svg'
import UnVerifiedIcon from '../../assets/svg/un-verified-icon.svg'
import { update_cover_image } from '../../api/auth';
import { logOut, storeUserData } from '../../redux/userData';
import { sleep } from '../../utils/sleep';
import { get_user_payment_details, get_user_profile_details, search_skills, update_user_skills } from '../../api/profile';
import { User, X } from 'phosphor-react';
import AddNewIcon from '../../assets/svg/AddNewIcon.svg'
import ExperienceIcon from '../../assets/svg/suitcase 1.svg'
import EducationIcon from '../../assets/svg/mortarboard 1.svg'
import AddWorkExperience from '../../components/AddWorkExperience/AddWorkExperience';
import AddEducation from '../../components/AddEducation/AddEducation';
import EditProfileDetails from '../../components/EditProfileDetails/EditProfileDetails';
import InstagramIcon from '../../assets/svg/smaill-insta.svg'
import TwitterIcon from '../../assets/svg/twitter (3) 1.svg'
import WebIcon from '../../assets/svg/Web Icon.svg'
import NotificationBanner from '../../components/NotificationBanner/NotificationBanner';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import EditPaymentDetails from '../../components/EditPaymentDetails/EditPaymentDetails';
import VerifyAccount from '../../components/VerifyAccount/VerifyAccount';
import { isValidUrl } from '../../utils/strings';
import { setActiveWallet } from '../../redux/wallet';

const Pin = () => (
  <svg style={{marginRight: 10}} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M11.9999 13.9314C13.723 13.9314 15.1199 12.5345 15.1199 10.8114C15.1199 9.08828 13.723 7.69141 11.9999 7.69141C10.2768 7.69141 8.87988 9.08828 8.87988 10.8114C8.87988 12.5345 10.2768 13.9314 11.9999 13.9314Z" stroke="#767676" stroke-width="1.5"/>
    <path d="M3.6202 8.99C5.5902 0.330002 18.4202 0.340003 20.3802 9C21.5302 14.08 18.3702 18.38 15.6002 21.04C13.5902 22.98 10.4102 22.98 8.3902 21.04C5.6302 18.38 2.4702 14.07 3.6202 8.99Z" stroke="#767676" stroke-width="1.5"/>
    </svg> 
)

const Truncatable = (props: {text: string}) => {
  const [shouldTruncate, setShouldTruncate] = useState(true)

  return (
    <>
      <p
        className={
          shouldTruncate ? 'anyjob-profile-page-truncated-text' : "anyjob-profile-page-truncatable-text"
        }
      >
        {props.text}
      </p>
      <p 
        className='anyjob-profile-page-truncate-control'
        onClick={() => setShouldTruncate(!shouldTruncate)}
      >
        {shouldTruncate ? "Show more" : "Show less"}
      </p>
    </>
  )
}

type SkillType = {id: number, title: string}

export default function Profile(props: any) {
  const [data, setData] = useState<any>(null)
  const [paymentData, setPaymentData] = useState<any>(null)
  const [attachment, setAttachment] = useState<any>(null)
  const [attachmentBase64Str, setAttachmentBase64Str] = useState<any>(null)
  const [attachmentFile, setAttachmentFile] = useState<any>(null)
  const [itMe, setItMe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPaymentData, setIsLoadingPaymentData] = useState(false)
  const [dataFetchFailed, setDataFetchFailed] = useState(false)
  const [showAllWorkHistories, setShowAllWorkHistories] = useState(false)
  const [showAllEducation, setShowAllEducation] = useState(false)
  const [isEditingSkills, setIsEditingSkills] = useState(false)

  const [skillsSuggestions, setSkillsSuggestions] = useState([])
  const [showSkillsSuggestions, setShowSkillsSuggestions] = useState(false)
  const [selectedSkills, setSelectedSkills] = useState<SkillType[]>([])
  const [inputValues, setInputValues] = useState({
    skillsSearchQuery: ''
  })

  const userData = useSelector((state: DefaultStateType) => state.userData);
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const [errMsg, setErrMsg] = useState({
    coverLetter: '',
    attachment: '',
  })

  const { profile_id } = useParams();

  const loadingModalRef = useRef<any>()
  const addWorkExperienceRef = useRef<any>()
  const addEducationRef = useRef<any>()
  const editProfileDetailsRef = useRef<any>()
  const editPaymentDetailsRef = useRef<any>()
  const changePasswordRef = useRef<any>()
  const verifyAccountRef = useRef<any>()
  const fileInputRef = useRef<any>(null);

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchData()
    loadingModalRef.current?. hideLoadingModal()
  }

  const OnDoneEditingProfile = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchData()
    loadingModalRef.current?. hideLoadingModal()
  }

  const fetchData = async () => {    
    try {
      setIsLoading(true)
      const res = await get_user_profile_details(profile_id || '')
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        setData(response.data)
        if (response.data?.user_profile.user.email === userData?.user_details?.email) {
          setItMe(true)
          await fetchPaymentData()
        } else {
          setItMe(false)
        }
        setIsLoading(false)
        setDataFetchFailed(false)
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDataFetchFailed(true)
        setIsLoading(false)
      }
      
    } catch (error) {
      setDataFetchFailed(true)
      setIsLoading(false)
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const fetchPaymentData = async () => {    
    try {
      setIsLoadingPaymentData(true)
      const res = await get_user_payment_details(profile_id || '')
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        setPaymentData(response.data.user_payment_details)
        setIsLoadingPaymentData(false)
        setDataFetchFailed(false)
      } else {
        setPaymentData(null)
        // toast.error(response.message, {
        //   position: "top-right",
        //   autoClose: 10000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        // setDataFetchFailed(true)
      }
      
    } catch (error) {
      setIsLoadingPaymentData(false)
      setPaymentData(null)
      // toast.error(getErrorMessage(error), {
      //   position: "top-right",
      //   autoClose: 10000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }


  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    setAttachmentFile(file)

    console.log('file', file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setAttachment(reader.result);
        // @ts-ignore
        const base64String = reader.result.split(',')[1];
        // console.log('reader.result', reader.result);
        // console.log('base64String', base64String);
        
        setAttachmentBase64Str(base64String)
      };

      reader.readAsDataURL(file);
      await sleep(1000)
      updateCoverImage()

      
      
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const updateCoverImage = async () => {
    try {
      loadingModalRef.current.showLoadingModal()
      var params = {
        cover_image: {
          base64: attachment,
          fileName: attachmentFile.name,
          size: attachmentFile.size,
          type: attachmentFile.type,
        },
      }
      

      const res = await update_cover_image(params)
      const response = await res.json()
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()

        dispatch(storeUserData({
          user_details: response.data?.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))    
        dispatch(setActiveWallet(response.data.user_data.wallet))
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const onCloseWorkModal = async (status: 'success' | 'failed' | 'canceled') => {
    switch (status) {
      case 'success':
        loadingModalRef.current.showLoadingModal()
        await fetchData()
        loadingModalRef.current?. hideLoadingModal()
        break;
    
      default:
        break;
    }
  }

  const onCloseEducationModal = async (status: 'success' | 'failed' | 'canceled') => {
    switch (status) {
      case 'success':
        loadingModalRef.current.showLoadingModal()
        await fetchData()
        loadingModalRef.current?. hideLoadingModal()
        break;
    
      default:
        break;
    }
  }

  const startSkillsEditing = () => {
    setIsEditingSkills(true)
    for (let user_skill of data?.user_skills) {
      console.log(user_skill);
      setSelectedSkills((prevState: SkillType[]) => ([
        ...prevState,
        user_skill.skill,
      ]))
    }
  }

  const stopSkillsEditing = () => {
    setIsEditingSkills(false)
    setSelectedSkills([])
  }


  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

    if (name === "skillsSearchQuery") {
      if (value.length % 2 == 0) {
        searchSkills(value)
      }
    }

  }

  const searchSkills = async (query: string) => {
    try {
      const res = await search_skills(query)
      const response = await res.json()
      if (res.ok) {
        setSkillsSuggestions(response.data?.skills)
        if (Boolean(response.data?.skills.length)) {
          setShowSkillsSuggestions(true)
        }
        
      }
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const isSkillTypeExist = (newSkillType: SkillType): boolean => {
    return selectedSkills.some(
      (existingSkillType) =>
        existingSkillType.id === newSkillType.id &&
        existingSkillType.title === newSkillType.title
    );
  };

  const selectSkill = async (skill: SkillType) => {
    console.log('selectedSkills.includes(skill)', selectedSkills.includes(skill));
    
    if (!isSkillTypeExist(skill)) {
      setSelectedSkills((prevState: SkillType[]) => ([
        ...prevState,
        skill
      ]))
    }    
    await sleep(100)
    

    setShowSkillsSuggestions(false)
    setInputValues({
      ...inputValues,
      skillsSearchQuery: ''
    });
  }

  const removeSkill = (skillToRemove: SkillType): void => {
    const updatedSkills = selectedSkills.filter(
      (skill) => skill.id !== skillToRemove.id
    );

    // Update the state with the filtered array
    setSelectedSkills(updatedSkills);
  };

  const saveUserSkills = async () => {
    try {
      loadingModalRef.current.showLoadingModal()
      var params = {
        current_user_skills: data?.user_skills,
        new_skills: selectedSkills,
      }

      const res = await update_user_skills(params)
      const response = await res.json()
      if (res.ok) {
        
        await fetchData()
        setIsEditingSkills(false)
        setSelectedSkills([])
        loadingModalRef.current?. hideLoadingModal()
        toast.success("Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleLogout = () => {
    const isConfirmed = window.confirm('Are you sure you want to log out?');

    if (isConfirmed) {
      // Perform the logout logic here
      console.log('Logging out...');
      // Add your logout logic (e.g., clearing user session, redirecting, etc.)
    } else {
      // User clicked "Cancel" in the confirmation dialog
      console.log('Logout cancelled');
      return 
    }

    // @ts-ignore
    dispatch(logOut())
    navigate('/home')
  };

  function openInNewTab(url: string) {
    if (isValidUrl(url)) {
      window.open(url, '_blank');
    }    
  }

  return (
    <div className='anyjob-profile-container'>
      <Header title={"Profile"} />
      <NotificationBanner />
      {
        !isLoading && (
          <div className='anyjob-profile-page-container'>
            <div className='anyjob-profile-page-content'>
              <div className='anyjob-profile-page-hero-section'>
                <img 
                  alt='Cover Image'
                  src={Boolean(data?.user_profile.cover_image) ? `${BASE_URL}/${data?.user_profile.cover_image}` :  DefaultCoverImage}
                  className='anyjob-profile-page-cover-image'
                />
                {/* {
                  itMe && (
                    <>
                      <img 
                        src={EditIcon}
                        alt="Edit Cover Image"
                        className='anyjob-profile-page-edit-cover-image'
                        onClick={handleButtonClick}
                      />
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          multiple={false}
                        />
                    </>
                  )
                } */}
                
                <div className='anyjob-profile-page-hero-personal-data'>
                  {
                    Boolean(data?.user_profile.profile_picture_url.length) ? (
                      <img 
                        src={data?.user_profile.profile_picture_url}
                        // src={`${BASE_URL}${data?.user_profile.profile_picture}`}
                        alt='Profile Picture'
                        className='anyjob-profile-page-profile-image'
                      />
                    ) : (
                      <div 
                        className='anyjob-profile-page-profile-default-image'
                        // onClick={handleButtonClick}
                      >
                        <User
                          className="anyjob-onboarding-page-profile-default-icon"
                        />
                      </div>
                    )
                  }
                  
                  <div className='anyjob-profile-page-hero-personal-bio'>
                    <div>
                      <div className='anyjob-profile-page-name-verification-status'>
                        <p  className='anyjob-profile-page-hero-full-name'>
                          {data?.user_profile.user?.first_name} {data?.user_profile.user?.last_name}
                        </p>
                        {
                          Boolean(data?.user_profile.is_kyc_verified) ? (
                            <img 
                              alt='This freelancer is verified'
                              src={VerifiedIcon}
                              className='anyjob-profile-page-verified-icon'
                            />
                          ) : (
                            itMe ? (
                              <div 
                                className='anyjob-profile-page-verify-btn'
                                onClick={() => verifyAccountRef.current?.show()}
                              >
                                <img 
                                  alt='This freelancer is verified'
                                  src={UnVerifiedIcon}
                                  className='anyjob-profile-page-verified-icon'
                                />
                                <p>Verify Your Account</p>
                              </div>
                            ) : (
                              <></>
                            )
                          )
                        }
                      </div>
                      <p  className='anyjob-profile-page-hero-short-bio'>
                        {userData?.user_profile?.short_bio} 
                      </p>
                      <p  className='anyjob-profile-page-hero-short-bio'>
                        <Pin /> {userData?.user_profile?.street_address} 
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        if (itMe) {
                          editProfileDetailsRef.current.show(OnDoneEditingProfile)
                        } else {
                          // copy profile link
                        }
                      }}
                    >
                      {
                        itMe ? "Edit Profile" : "Share Profile"
                      }
                    </button>
                  </div>
                </div>
                
              </div>
              <div className='anyjob-profile-page-about-me-section'>
                <div className='anyjob-profile-page-about-me-section-header'>
                  <p className='anyjob-profile-page-about-me-section-title'>
                    About Me</p>
                  {
                    itMe && (
                      <img 
                        alt="Edit About Me"
                        src={EditIcon2}
                        className='anyjob-profile-page-about-me-section-edit-btn'
                        onClick={() => editProfileDetailsRef.current.show(OnDoneEditingProfile)}
                      />
                    )
                  }
                  
                </div>
                {/* <p className='anyjob-profile-page-about-me-section-bio'>
                  {userData?.user_profile.long_bio}
                </p> */}
                <Truncatable text={data?.user_profile.long_bio} />
              </div>

              {/* Termporarily Disable Work experience */}
              {/* <div className='anyjob-profile-page-about-me-section'>
                <div className='anyjob-profile-page-about-me-section-header'>
                  <p className='anyjob-profile-page-about-me-section-title'>
                    Experiences                
                  </p>
                  {
                    itMe && (
                      <img 
                        alt="Add new work experience"
                        src={AddNewIcon}
                        className='anyjob-profile-page-about-me-section-edit-btn'
                        onClick={() => {
                          addWorkExperienceRef.current.show('add', onCloseWorkModal)
                        }}
                      />
                    )
                  }
                </div>
                {
                  Boolean(data?.work_histories.length) ? (
                    data?.work_histories.map((item:any, index: number) => {
                      if (!showAllWorkHistories && index > 1) {
                        return (<></>)
                      }
                      return (
                        <>
                        <div className='anyjob-profile-page-experience-item-container' key={index}>
                          <img 
                            alt='Experience Icon'
                            src={ExperienceIcon}
                            className='anyjob-profile-page-experience-item-icon'
                          />
                          <div className='anyjob-profile-page-experience-item-contents'>
                            <div className='anyjob-profile-page-experience-item-contents-heading'>
                              <p className='anyjob-profile-page-experience-item-role'>
                                {item.role}
                              </p>
                              {
                                itMe && (
                                  <img 
                                    alt="Edit Experiences"
                                    src={EditIcon2}
                                    className='anyjob-profile-page-about-me-section-edit-btn'
                                    onClick={() => {
                                      addWorkExperienceRef.current.show('edit', onCloseWorkModal, item)
                                    }}
                                  />
                                )
                              }
                            </div>
                            <div className='anyjob-profile-page-experience-item-details-01'>
                              <p className='anyjob-profile-page-experience-item-details-company-name'>
                                {item.company}
                              </p>
                              <span className='anyjob-profile-page-experience-item-details-01-divider' />
                              <p  className='anyjob-profile-page-experience-item-details-01-desc'>
                                {item.start_date_month} {item.start_date_year}
                              </p>
                              <span className='anyjob-profile-page-experience-item-details-01-divider' />
                              <p  className='anyjob-profile-page-experience-item-details-01-desc'>
                                {item.is_still_ongoing ? "Present" : `${item.end_date_month} ${item.end_date_year}`}                                
                              </p>
                            </div>
                            <p className='anyjob-profile-page-experience-item-details-location'>
                              {item.location}
                            </p>
                            <Truncatable text={item.description} />
                          </div>
                        </div>
                        {
                          (index + 1 < data?.work_histories.length) && (
                            <div className='anyjob-profile-page-experience-item-divider'/>
                          )
                        }
                        </>
                      )
                    })
                  ) : (
                    <div>
                    </div>
                  )
                }
                {
                  (data?.work_histories.length > 2) && (
                    <p 
                      className='anyjob-profile-page-show-more-btn'
                      onClick={() => setShowAllWorkHistories(!showAllWorkHistories)}
                    >
                      {
                        showAllWorkHistories ? 
                          `Show less`
                        : `Show ${data?.work_histories.length - 2} more experiences`
                      }
                      
                    </p>
                  )
                }
                
              </div> */}
              

              {/* Termporarily Disable Education */}
              {/* <div className='anyjob-profile-page-about-me-section'>
                <div className='anyjob-profile-page-about-me-section-header'>
                  <p className='anyjob-profile-page-about-me-section-title'>
                    Education                
                  </p>
                  {
                    itMe && (
                      <img 
                        alt="Add new education"
                        src={AddNewIcon}
                        className='anyjob-profile-page-about-me-section-edit-btn'
                        onClick={() => {
                          addEducationRef.current.show('add', onCloseEducationModal)
                        }}
                      />
                    )
                  }
                </div>
                {
                  Boolean(data?.education_histories.length) ? (
                    data?.education_histories.map((item:any, index: number) => {
                      if (!showAllEducation && index > 1) {
                        return (<></>)
                      }
                      return (
                        <>
                        <div className='anyjob-profile-page-experience-item-container' key={index}>
                          <img 
                            alt='Education Icon'
                            src={EducationIcon}
                            className='anyjob-profile-page-experience-item-icon'
                          />
                          <div className='anyjob-profile-page-experience-item-contents'>
                            <div className='anyjob-profile-page-experience-item-contents-heading'>
                              <p className='anyjob-profile-page-experience-item-role'>
                                {item.school}
                              </p>
                              {
                                itMe && (
                                  <img 
                                    alt="Edit Education"
                                    src={EditIcon2}
                                    className='anyjob-profile-page-about-me-section-edit-btn'
                                    onClick={() => {
                                      addEducationRef.current.show('edit', onCloseEducationModal, item)
                                    }}
                                  />
                                )
                              }
                            </div>
                            <div className='anyjob-profile-page-experience-item-details-01'>
                              <p  className='anyjob-profile-page-experience-item-details-01-desc'>
                                {item.degree} 
                              </p>
                              <span className='anyjob-profile-page-experience-item-details-01-divider' />
                              <p className='anyjob-profile-page-experience-item-details-company-name'>
                                {item.course}
                              </p>
                            </div>
                            <p className='anyjob-profile-page-experience-item-details-location'>
                              {item.start_year} - {item.end_year}
                            </p>
                            <Truncatable text={item.description} />
                          </div>
                        </div>
                        {
                          (index + 1 < data?.education_histories.length) && (
                            <div className='anyjob-profile-page-experience-item-divider'/>
                          )
                        }
                        </>
                      )
                    })
                  ) : (
                    <div>
                    </div>
                  )
                }
                {
                  (data?.education_histories.length > 2) && (
                    <p 
                      className='anyjob-profile-page-show-more-btn'
                      onClick={() => setShowAllEducation(!showAllEducation)}
                    >
                      {
                        showAllEducation ? 
                          `Show less`
                        : `Show ${data?.education_histories.length - 2} more education`
                      }
                      
                    </p>
                  )
                }
                
              </div> */}

              {/* Termporarily Disable User Skils */}
              {/* <div className='anyjob-profile-page-about-me-section'>
                <div className='anyjob-profile-page-about-me-section-header'>
                  <p className='anyjob-profile-page-about-me-section-title'>
                    Skills                
                  </p>
                  {
                    itMe && (
                      isEditingSkills ? (
                        <div className='anyjob-profile-page-skills-actions'>
                          <p className='anyjob-profile-page-skills-cancel-changes' onClick={stopSkillsEditing}>
                            Cancel
                          </p>
                          <button className='anyjob-profile-page-skills-save-changes' onClick={saveUserSkills}>
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className='anyjob-profile-page-skills-actions'>                          
                          <img 
                            alt="Edit existing skills"
                            src={EditIcon2}
                            className='anyjob-profile-page-about-me-section-edit-btn'
                            onClick={startSkillsEditing}
                          />
                        </div>
                      )                      
                    )
                  }
                </div>
                {
                  isEditingSkills ? (
                    <div className='anyjob-profile-page-skills-editing-section'>
                      <input 
                        type="text"
                        placeholder="Add Skill e.g. Visual Design"
                        className='anyjob-profile-page-skills-editing-section-input-field'
                        name="skillsSearchQuery"
                        value={inputValues.skillsSearchQuery}
                        onChange={handleInputChange} 
                        disabled={selectedSkills.length >= 20}

                      />
                      {
                        showSkillsSuggestions && (
                          <div className="anyjob-profile-page-skills-suggestions-container">
                            {
                              skillsSuggestions.map((item: SkillType, index: number) => {
                                return (
                                  <p 
                                    className="anyjob-profile-page-skills-suggestion"
                                    onClick={() => selectSkill(item)}
                                    key={index}
                                  >
                                    {item.title}
                                  </p>
                                )
                              })
                            }
                          </div>
                        )
                      }
                      {
                        Boolean(selectedSkills.length) && (
                          <div className="anyjob-profile-page-selected-skills">
                            {
                              selectedSkills.map((item: SkillType, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className="anyjob-profile-page-selected-skill"
                                  >
                                    <p>{item.title}</p>
                                    <div className="anyjob-profile-page-selected-skill-remove-btn" onClick={() => removeSkill(item)}>
                                    <X className="anyjob-profile-page-selected-skill-remove-btn-icon"
                                    />
                                    </div>
                                    
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      }
        
                    </div>
                  ) 
                  : (
                    <div className='anyjob-profile-page-current-skills'>
                      {
                        data?.user_skills.map((item: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="anyjob-profile-page-current-skill"
                            >
                              <p>{item.skill.title}</p>                                                            
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                }
                
              </div> */}
              
            </div>
            <div className='anyjob-profile-page-meta-data'>
              {/* Termporarily Disable Socials */}
              {/* <div className='anyjob-profile-page-socials-section'>
                <div className='anyjob-profile-page-about-me-section-header'>
                  <p className='anyjob-profile-page-about-me-section-title'>
                    Socials
                  </p>
                  {
                    itMe && (
                      <img 
                        alt="Edit About Me"
                        src={EditIcon2}
                        className='anyjob-profile-page-about-me-section-edit-btn'
                        onClick={() => editProfileDetailsRef.current.show(OnDoneEditingProfile)}
                      />
                    )
                  }
                  
                </div>

                <div className='anyjob-profile-page-socials-item'>
                  <img 
                    alt="Instagram Link"
                    src={InstagramIcon}
                  />
                  <span className='anyjob-profile-page-socials-item-details'>
                    <p className='anyjob-profile-page-socials-item-label'>
                      Instagram
                    </p>
                    <p className='anyjob-profile-page-socials-item-handles' onClick={() =>openInNewTab(data?.user_profile?.socials_instagram)}>
                      {data?.user_profile?.socials_instagram}
                    </p>
                  </span>
                </div>

                <div className='anyjob-profile-page-socials-item'>
                  <img 
                    alt="X - Twitter Link"
                    src={TwitterIcon}
                  />
                  <span className='anyjob-profile-page-socials-item-details'>
                    <p className='anyjob-profile-page-socials-item-label'>
                      X - formerly Twitter
                    </p>
                    <p 
                      className='anyjob-profile-page-socials-item-handles'
                      onClick={() =>openInNewTab(data?.user_profile?.socials_twitter)}
                    >
                      {data?.user_profile?.socials_twitter}
                    </p>
                  </span>
                </div>

                <div className='anyjob-profile-page-socials-item'>
                  <img 
                    alt="Website Link"
                    src={WebIcon}
                  />
                  <span className='anyjob-profile-page-socials-item-details'>
                    <p className='anyjob-profile-page-socials-item-label'>
                      Website
                    </p>
                    <p 
                      className='anyjob-profile-page-socials-item-handles'
                      onClick={() =>openInNewTab(data?.user_profile?.socials_website)}
                    >
                      {data?.user_profile?.socials_website}
                    </p>
                  </span>
                </div>
              </div> */}
              {/* Payment Details */}
              {
                Boolean(paymentData) && (
                  <div className='anyjob-profile-page-socials-section'>
                    <div className='anyjob-profile-page-about-me-section-header'>
                      <p className='anyjob-profile-page-about-me-section-title'>
                        Bank Details
                      </p>
                      {
                        itMe && (
                          <img 
                            alt="Edit About Me"
                            src={EditIcon2}
                            className='anyjob-profile-page-about-me-section-edit-btn'
                            onClick={() => editPaymentDetailsRef.current.show(OnDoneEditingProfile)}
                          />
                        )
                      }
                      
                    </div>

                    <div className='anyjob-profile-page-socials-item'>
                      <span className='anyjob-profile-page-socials-item-details'>
                        <p className='anyjob-profile-page-socials-item-label'>
                          Bank Name
                        </p>
                        <p className='anyjob-profile-page-socials-item-handles'>
                          {paymentData?.bank_name}
                        </p>
                      </span>
                    </div>
                    <div className='anyjob-profile-page-socials-item'>
                      <span className='anyjob-profile-page-socials-item-details'>
                        <p className='anyjob-profile-page-socials-item-label'>
                          Account Name
                        </p>
                        <p className='anyjob-profile-page-socials-item-handles'>
                          {paymentData?.account_name}
                        </p>
                      </span>
                    </div>
                    <div className='anyjob-profile-page-socials-item'>
                      <span className='anyjob-profile-page-socials-item-details'>
                        <p className='anyjob-profile-page-socials-item-label'>
                          Account Number
                        </p>
                        <p className='anyjob-profile-page-socials-item-handles'>
                          ******{paymentData?.account_number_last_4}
                        </p>
                      </span>
                    </div>
                  </div>
                )
              }
              {/* Payment Details */}
              {
                Boolean(itMe) && (
                  <div className='anyjob-profile-page-socials-section'>
                    <div className='anyjob-profile-page-socials-item' onClick={() => changePasswordRef.current.show()}>
                      <img 
                        alt="Key Icon"
                        src={KeyIcon}
                      />
                      <span className='anyjob-profile-page-socials-item-details'>
                        <p className='anyjob-profile-page-socials-item-label'>
                          Change Password
                        </p>
                      </span>
                    </div>
                    <div className='anyjob-profile-page-socials-item' onClick={handleLogout}>
                      <img 
                        alt="Log Out Icon"
                        src={LogOutIcon}
                      />
                      <span className='anyjob-profile-page-socials-item-details'>
                        <p className='anyjob-profile-page-socials-item-label'>
                          Log Out
                        </p>
                      </span>
                    </div>
                  </div>
                )
              }

              
              
            </div>
            
          </div>
        )
      }

      <AddWorkExperience
        ref={addWorkExperienceRef}
        goToNext={() => console.log('do nothing')} 
        increaseTabProgress={() => console.log('do nothing')} 
      />

      <AddEducation
        ref={addEducationRef}
        goToNext={() => console.log('do nothing')} 
        increaseTabProgress={() => console.log('do nothing')} 
      />

      <ChangePassword
        ref={changePasswordRef}
      />
      <EditProfileDetails
        ref={editProfileDetailsRef}
      />
      <EditPaymentDetails
        ref={editPaymentDetailsRef}
      />
      <VerifyAccount
        ref={verifyAccountRef}
      />
      
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}