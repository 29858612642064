import React from 'react'
import { Link } from "react-router-dom";

import './JobWidget.css'
import { formatCurrency } from '../../utils/strings'

type JobWidgetProps = {
  job: any,
}

export default function JobWidget(props: JobWidgetProps){
  return (
    <div className='anyjob-job-widget-container'>
      <div className='anyjob-job-widget-details'>
        <p className='anyjob-job-widget-details-heading'>
          Job Details
        </p>
        <p className='anyjob-job-widget-details-title'>
          {props?.job?.title}
        </p>

        <span>
          <p className='anyjob-job-widget-details-sub-title'>
            {props?.job?.client?.business_name}
          </p>
          <span className='anyjob-job-widget-details-divider-1' />
          <p className='anyjob-job-widget-details-sub-title'>
            {props?.job?.location}
          </p>
          <span className='anyjob-job-widget-details-divider-2' />
          <p className='anyjob-job-widget-details-pill'>
            {props?.job?.location_type}
          </p>
          {/* <p className='anyjob-job-widget-details-sub-title-2'>
            {props?.job?.compensation_currency?.symbol} {formatCurrency(props?.job?.compensation_amount)}
          </p> */}
        </span>
        <div dangerouslySetInnerHTML={{ __html: props?.job?.instructions }} className="anyjob-job-widget-job-description" />
        {
          Boolean(props?.job?.required_skills.length) && (
            <>  
              <p className='anyjob-job-widget-meta-data-title'>
                Skills:
              </p>
              
              <div className='anyjob-job-widget-skills'>
                {
                  props?.job?.required_skills.map((item: any, index: number) => {
                    return (
                      <p 
                        key={index}
                        className='anyjob-job-widget-skill'
                      >
                        {item.title}
                      </p>
                    )
                  })
                }
              </div>
            </>
          )
        }
       
        <Link
          className='anyjob-job-widget-view-job-details'
          to={`/job-details/${props.job?.unique_id}`}
        >
          View Job Posting
        </Link>
      </div>
      {/* <div className='anyjob-job-widget-divider' />
      <div className='anyjob-job-widget-meta-data'>
        <p className='anyjob-job-widget-meta-data-title'>
          Previous Submissions
        </p>
        <p className='anyjob-job-widget-meta-data-value'>
        {props.job?.project_duration_count} {props.job?.project_duration_unit}
        </p>
        <p className='anyjob-job-widget-meta-data-title'>
          Job Level:
        </p>
        <p className='anyjob-job-widget-meta-data-value'>
        {props.job?.job_level}
        </p>
        <p className='anyjob-job-widget-meta-data-title'>
          Employment Type:
        </p>
        <p className='anyjob-job-widget-meta-data-value'>
        {props.job?.employment_type}
        </p>
      </div> */}
      <Link
          className='anyjob-job-widget-view-job-details-2'
          to={`/job-details/${props.job?.unique_id}`}
        >
          View Job Posting
        </Link>
    </div>
  )
}