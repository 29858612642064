import React, { useEffect, useState, useRef } from 'react'

import './Avatar.css'
import { User } from 'phosphor-react'

type AvatarProps = {
  size: 'xs' | 's' | 'm' | 'l' | 'xl',
  uri: string,
  isOnline?: boolean,
  imageAlt?: string,
}

export default function Avatar(props: AvatarProps) {
  if (Boolean(props.uri.length)) {
    return (
      <div className={`anyjob-avatar-container anyjob-${props.size}`}>
        <img 
          alt={props.imageAlt || ''}
          src={props.uri}
          className={`anyjob-avatar-img anyjob-${props.size}`}
        />
      </div>
    )
  }
  return (
    <div className={`anyjob-avatar-container anyjob-${props.size}`}>
      <User 
        className='anyjob-avatar-user-icon'
      />
    </div>
  )
}

Avatar.defaultProps = {
  size: 'm',
  url: '',
  isOnline: false
}