import * as React from "react"
import { IconType } from "./types"

const WalletIcon = (props: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 20 20" 
    fill="none"
    {...props}
  >
    <path
      fill={props.isActive ? "#1A1A1A" : "#ACACAC"}
      d="M12.374 3.302V6.47h-1.25V3.302c0-.225-.2-.333-.333-.333a.335.335 0 0 0-.125.025L4.057 5.485a1.12 1.12 0 0 0-.725 1.059v.558a3.113 3.113 0 0 0-1.25 2.5V6.544c0-.992.609-1.875 1.534-2.225l6.616-2.5a1.588 1.588 0 0 1 2.142 1.483ZM17.916 12.088v.834a.416.416 0 0 1-.409.416h-1.216c-.442 0-.842-.324-.875-.758a.832.832 0 0 1 .241-.666.792.792 0 0 1 .584-.242h1.258a.418.418 0 0 1 .417.416Z"
    />
    <path
      fill={props.isActive ? "#1A1A1A" : "#ACACAC"}
      d="M16.232 10.802h.85a.836.836 0 0 0 .834-.833v-.367a3.14 3.14 0 0 0-3.134-3.133H5.216c-.709 0-1.359.233-1.884.633a3.113 3.113 0 0 0-1.25 2.5v5.608a3.14 3.14 0 0 0 3.134 3.134h9.566a3.14 3.14 0 0 0 3.134-3.134v-.158a.836.836 0 0 0-.834-.833h-.725c-.8 0-1.566-.492-1.775-1.267a1.665 1.665 0 0 1 .45-1.65 1.66 1.66 0 0 1 1.2-.5Zm-4.566-.167H5.832a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h5.834a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z"
    />
  </svg>
)
export default WalletIcon
