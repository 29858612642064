import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveSecureData } from '../../utils/handleStorage';
import { get_user_details } from '../../api/auth';


export const updateUserData = createAsyncThunk('userData/update-user-data', async ()  => {
  try {
    var res = await get_user_details()
    
    if (res.ok) {
      var response = await res.json()
      console.log('response', response);
      await saveSecureData('token', response.data.token)
      return response.data.user_data
    } else {
      var response = await res.json()
      throw new Error(response.detail)
    }
  } catch (error) {
    throw error
  }    
})