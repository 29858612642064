import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { useNavigate } from 'react-router';

import './Proposals.css'
import { ProposalStatusType } from './types';
import { fetch_proposals } from '../../api/jobs';
import { CaretLeft, CaretRight } from 'phosphor-react';

import PaperAndPencil from '../,./../../assets/svg/pencil-and-paper.svg'
import { Link } from 'react-router-dom';
import { DefaultStateType } from '../../redux/types';
import { useSelector } from 'react-redux';

type ProposalTabContentProps = {
  dateRange: any,
  loadingModalRef: any,
  status: ProposalStatusType,
}

export default function ProposalTabContent(props: ProposalTabContentProps){
  const [data, setData] = useState<any[]>([])
  const [pageNo, setPageNo] = useState(1);
  const [maxPageNo, setMaxPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const userData = useSelector((state: DefaultStateType) => state.userData);

  const navigate = useNavigate();

  useEffect(() => {
    setUp()
  }, [])

  useEffect(() => {
    setPageNo(1)
    setUp()
  }, [props.dateRange,])

  const setUp = async () => {
    props.loadingModalRef.current.showLoadingModal()
    await fetchProposals()
    props.loadingModalRef.current?. hideLoadingModal()
  }

  const fetchProposals = async () => {    
    try {
      console.log('props.dateRange.endDate', props.dateRange[0].endDate);
      
      const params = {
        status: props.status,
        page: pageNo,
        end_date: moment(props.dateRange[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
        start_date: moment(props.dateRange[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),   
        mode: userData.appMode,      
      }
      const res = await fetch_proposals(params)
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        
        
        if (pageNo === 1) {
          setData(response.results);   
        } else {
          setData((prevState: any) => [...prevState, ...response.results]);   
        }     
        setMaxPageNo(response.count/20)
        setTotalCount(response.count)
        setHasNextPage(response.count > pageNo)      
      } else {

      }
    } catch (error) {
      
    }
  }

  const goToPrev = async () => {
    if (pageNo <= 1) {
      
    } else {
      props.loadingModalRef.current.showLoadingModal()
      setPageNo(prevPage => prevPage - 1);
      await fetchProposals()
      props.loadingModalRef.current?. hideLoadingModal()
    }
  }

  const goToNext = async () => {
    if (maxPageNo > pageNo) {
      setPageNo(prevPage => prevPage + 1);
      props.loadingModalRef.current.showLoadingModal()
      await fetchProposals()
      props.loadingModalRef.current?. hideLoadingModal()
    }    
  };

  const printPaginationPosition = (page: number) => {
    var lowerRange = ((page - 1) * 20)  + 1
    var upperRange =  totalCount < page * 20 ? totalCount : page * 20
    return `${lowerRange} - ${upperRange} of ${totalCount}`
  }

  return (
    <table className='anyjob-proposals-status-table'>
      <thead className='anyjob-proposals-status-table-header'>
        <tr>
          <th className='anyjob-proposals-status-table-item-index'>
            #
          </th>
          <th className='anyjob-proposals-status-table-header-col'>
            Company Name
          </th>
          <th className='anyjob-proposals-status-table-header-col'>
            Roles
          </th>
          <th className='anyjob-proposals-status-table-header-col'>
            Date Applied
          </th>
          <th className='anyjob-proposals-status-table-header-col'>
            Status
          </th>
        </tr>
      </thead>
      {
        Boolean(data.length) ? (
          <tbody className='anyjob-proposals-status-table-body'>
            {data.map((row: any, index: number) => (
              <tr key={index} className='anyjob-proposals-status-table-item' 
                onClick={() =>{
                  navigate(`/job-details/${row.job.unique_id}`)
                  console.log('row data', row)}}
              >
                <td className='anyjob-proposals-status-table-item-index'>{index+1}</td>
                <td className='anyjob-proposals-status-table-body-col'>{row.job.client.business_name}</td>
                <td className='anyjob-proposals-status-table-body-col'>{row.job.title}</td>
                <td className='anyjob-proposals-status-table-body-col'>{moment(row.date_applied).format('DD MMM YY')}</td>
                <td className='anyjob-proposals-status-table-body-col'>{row.status}</td>
              </tr>
            ))}
            <div className='anyjob-pagination-controls-container '>
              <p>
                {printPaginationPosition(pageNo)}
              </p>
    
              <CaretLeft
                className='anyjob-pagination-controls-btn'
                onClick={goToPrev}
              />
              <CaretRight
                className='anyjob-pagination-controls-btn'
                onClick={goToNext}
              />
            </div>
          </tbody>
        ) : (
          <div className='anyjob-empty-state-container'>
            <img 
              alt="No proposal submitted yet"
              src={PaperAndPencil}
            />
            <p>
              No proposal submitted yet
            </p>
            <Link
              to="/jobs"
              className='anyjob-empty-state-container-btn'
            >
              Browse Jobs
            </Link>
          </div>
        )
      }
     
       
    </table>
  )
}