import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_STATE } from '../default_state'
import {
  updateUserData,
} from '../userData/utils'

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: DEFAULT_STATE.wallet,
  reducers: {    
    reset: (state, action) => {
      state = DEFAULT_STATE.wallet
    },
    setActiveWallet: (state, action) => {
      state.activeWallet = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserData.fulfilled, (state, action) => {  
        // console.log('action.payload', action.payload);
              
        // state.user_details = action.payload.user_details;
        // state.user_profile = action.payload.user_profile;
      })
      
      // .addCase(updateAppFeatures.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // });
      // .addCase(updateAppFeatures.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
  },
})

export const { 
  reset,  
  setActiveWallet,
 } = walletSlice.actions

export const walletReducer = walletSlice.reducer