import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import './TransactionsPage.css'

import Header from '../../components/Header/Header'
import LoadingModal from '../../components/LoadingModal'
import getErrorMessage from '../../utils/getErrorMessage';
import { DefaultStateType } from '../../redux/types';
import { formatCurrency } from '../../utils/strings';
import moment from 'moment';
import ErrorComp from '../../components/ErrorComp/ErrorComp';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { fetch_transactions } from '../../api/wallet';

export default function TransactionsPage(props: any){
  const [transactions, setTransactions] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [dataFetchFailed, setDataFetchFailed] = useState(false)
  const [err, setErr] = useState({
    title: '',
    msg: '',
  })
  const [pageNo, setPageNo] = useState(1);
  const [maxPageNo, setMaxPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const wallet = useSelector((state: DefaultStateType) => state.wallet);
  const dispatch = useDispatch()

  const loadingModalRef = useRef<any>()

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    loadingModalRef.current.showLoadingModal()
    await fetchTransactions()
    loadingModalRef.current?. hideLoadingModal()
  }

  const fetchTransactions = async () => {    
    try {
      
      const params = {
        page: pageNo,
      }
      const res = await fetch_transactions(params)
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        
        
        if (pageNo === 1) {
          setTransactions(response.results);   
        } else {
          setTransactions((prevState: any) => [...prevState, ...response.results]);   
        }     
        setMaxPageNo(response.count/20)
        setTotalCount(response.count)
        setHasNextPage(response.count > pageNo)      
      } else {

      }
    } catch (error) {
      
    }
  }

  const printPaginationPosition = (page: number) => {
    var lowerRange = ((page - 1) * 20)  + 1
    var upperRange =  totalCount < page * 20 ? totalCount : page * 20
    return `${lowerRange} - ${upperRange} of ${totalCount}`
  }

  const goToPrev = async () => {
    if (pageNo <= 1) {
      
    } else {
      loadingModalRef.current.showLoadingModal()
      setPageNo(prevPage => prevPage - 1);
      await fetchTransactions()
      loadingModalRef.current?. hideLoadingModal()
    }
  }

  const goToNext = async () => {
    if (maxPageNo > pageNo) {
      setPageNo(prevPage => prevPage + 1);
      loadingModalRef.current.showLoadingModal()
      await fetchTransactions()
      loadingModalRef.current?. hideLoadingModal()
    }    
  };

  return (
    <div className='anyjob-transactions-container'>
      <Header title={"Transactions"} />

      {
        !dataFetchFailed ? (
          <div className='anyjob-transactions-page-container'>
            <div className='anyjob-transactions-page-transactions-container'>                            
              <table className='anyjob-transactions-page-transactions-table'>
                <tr className='anyjob-transactions-page-transactions-table-head'>
                  <th>
                    Transaction ID
                  </th>
                  <th>
                    Amount
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Type
                  </th>
                  {/* <th>
                    Status
                  </th> */}
                </tr>
                
                  {
                    transactions.map((item: any, index: number) => {
                      return (
                        <tr >
                          <td id='anyjob-transactions-page-transactions-table-trans-id'>
                            #{item.transaction_id}
                          </td>
                          <td>
                            {item?.currency?.symbol} {formatCurrency(item?.value)}
                          </td>
                          <td>
                            {moment(item.created_at).format("MMM DD,YYYY . hh:mm a")}
                          </td>
                          <td>
                            {item?.transaction_type}
                          </td>
                          {/* <td>
                            #124556
                          </td> */}
                        </tr>
                      )
                    })
                  }
                  
              </table>
              <div className='anyjob-pagination-controls-container'>
                <p>
                  {printPaginationPosition(pageNo)}
                </p>

                <CaretLeft
                  className='anyjob-pagination-controls-btn'
                  onClick={goToPrev}
                />
                <CaretRight
                  className='anyjob-pagination-controls-btn'
                  onClick={goToNext}
                />
              </div>
            </div>
          </div>
        ) : (
          <ErrorComp 
            title={err.title}
            desc={err.msg}
            // onClick={setUp}
          />
        )
      }
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}