import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { toast } from "react-toastify";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet'; // Import Leaflet library
// @ts-ignore
import moment from 'moment'


import './SubmitTask.css'
import Header from '../../components/Header/Header'
import { fetch_jobs, get_job, get_task, get_task_comments, send_proposal, send_task_comment, submit_task, update_task } from '../../api/jobs'
import LoadingModal from '../../components/LoadingModal'
import { TaskDetailsProps } from './types';
import JobWidget from '../../components/JobWidget/JobWidget';
import getErrorMessage from '../../utils/getErrorMessage';
import CameraComponent, { cameraCallBackParamType } from '../../components/CameraComponent/CameraComponent';
import AttachementButton from './AttachementButton';
import PinIcon from '../../assets/images/Pin.png'
import { log } from 'console';
import { formatCurrency } from '../../utils/strings';

const customMarkerIcon = L.icon({
  iconUrl: PinIcon, // Adjust the path to your marker icon image
  iconSize: [30, 36], // Size of the icon image
  iconAnchor: [12, 41], // Anchor point of the icon image
  popupAnchor: [1, -34] // Popup anchor point
});

export default function TaskDetails(props: TaskDetailsProps) {
  const [jobData, setJobData] = useState<any>(null)
  const [taskData, setTaskData] = useState<any>(null)
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });
  const [isCapturingCoords, setIsCapturingCoords] = useState(false)
  const [dynamicInputValues, setDynamicInputValues ] = useState<any>({})
  const [isEditing, setIsEditing] = useState(false)
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState<any[]>([])

  const [errMsg, setErrMsg] = useState({
    coverLetter: '',
    attachment: '',
  })

  const { task } = useParams();

  const loadingModalRef = useRef<any>()
  const cameraComponentRef = useRef<any>()
  const attachmentButtonRef = useRef<any>()

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    setDynamicInputValues({})
    setCoords({ latitude: 0, longitude: 0 })  
    setIsEditing(false)  
    loadingModalRef.current.showLoadingModal()
    await fetchJob()
    loadingModalRef.current?. hideLoadingModal()
    await getTaskComments()
    // getUserLocation()
  }

  const fetchJob = async () => {    
    try {
      
      const res = await get_task(task || '')
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        setJobData(response.data?.task?.job)    
        setTaskData(response.data?.task)  
        setCoords({ latitude: response.data.task.latitude, longitude: response.data.task.longitude })    
        setDynamicInputValues(response.data.task?.dynamic_form_response)
      } else {

      }
    } catch (error) {
      
    }
  }

  const getTaskComments = async () => {    
    try {
      
      const res = await get_task_comments(task || '')
      const response = await res.json()     
      console.log('response', response);
      if (res.ok) {
        setComments(response.data.task_comments)
      } else {

      }
    } catch (error) {
      
    }
  }

  const handleDyanmicInputChange = async(event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setDynamicInputValues((prevState: any) => ({
      ...prevState,
      [name]: value
    }))

  }

  const handleCommentChange = async(event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setComment(value)

  }


  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        console.log('in valid form');
        
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params = {
        unique_id: taskData?.unique_id,
        job_unique_id: jobData?.unique_id,
        ...(getSubmissionParam())
      }

      console.log('params', params);

      const res = await update_task(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()
        setIsEditing(false)
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleSendComment = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!Boolean(comment.length)) {
        console.log('in valid form');
        
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params = {
        unique_id: taskData?.unique_id,
        comment
      }

      console.log('params', params);

      const res = await send_task_comment(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        setComment('')
        setComments(response.data.task_comments)
        loadingModalRef.current?. hideLoadingModal()
  
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const getSubmissionParam = () => {
    try {
      var params: any = {}
      if (jobData?.submission_type_dynamic_form) {
        params['dynamic_form_values'] = dynamicInputValues
      } else {
        params['dynamic_form_values'] = {}
      }

      if (jobData?.submission_type_geo_location) {
        params['coords'] = coords
      } else {
        params['coords'] = null
      }

      if (jobData?.submission_type_attachments) {
        params['attachments'] = attachmentButtonRef.current.getAttachments()
      } else {
        params['attachments'] = []
      }

      return params
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
 

  const validateForm = () => {
    try {
      var isValid = true
      if (jobData?.submission_type_dynamic_form) {
        console.log('dynamicInputValues.length', Object.keys(dynamicInputValues).length);
        console.log('jobData?.dynamic_form_fields.length', jobData?.dynamic_form_fields.length);
        
        if (Object.keys(dynamicInputValues).length === jobData?.dynamic_form_fields.length) {
          for (let field of jobData?.dynamic_form_fields) {
            if (!Boolean(dynamicInputValues[field?.name])) {
              isValid = false
            }
          }
        } else {
          isValid = false
        }
        
      }

      if (jobData?.submission_type_geo_location) {
        if (coords.latitude === 0 || coords.longitude === 0) {
          isValid = false
          toast.warn("Kindly use the button below to capture your location", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      if (jobData?.submission_type_attachments) {
        if (!Boolean(attachmentButtonRef.current.getAttachments().length)) {
          isValid = false
          toast.warn("Kindly attach/capture the required file(s)", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      return isValid
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false
    }
  }

 
  const getAllowedFileType = () => {
    switch (jobData?.attachment_type) {
      case 'audio-upload':
        return ".mp3, .wav, .ogg, .aac, .m4a"
      case 'doc-upload':
        return ".pdf, .doc, .docx, .txt"
      case 'image-upload':
        return ".jpg, .jpeg, .png, .gif, .bmp"
      case 'video-upload':
        return ".mp4, .avi, .mkv, .mov, .wmv"
    
      default:
        return ''
    }
  }

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setIsCapturingCoords(true)
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('position', position);
          
          setCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setIsCapturingCoords(false)
        },
        (error) => {
          // console.error("Error getting geolocation:", error);
          setIsCapturingCoords(false)
          toast.error("Error getting geolocation:", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
    } else {
      // console.error("Geolocation is not supported by this browser");
      toast.error("Geolocation is not supported by this browser", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  

  return (
    <div className='anyjob-task-page-container'>
      <Header title={"Task Submission Details"} />
      <div className='anyjob-submit-task-page-container'>        
        <div className='anyjob-submit-task-page-contents'>

        <div className='anyjob-submitted-task-stats'>
          <div className='anyjob-submitted-task-stats-item'>
            <p className='anyjob-submitted-task-stats-item-title'>
              Amount
            </p>
            <p className='anyjob-submitted-task-stats-item-value'>
              {jobData?.compensation_currency?.symbol} {formatCurrency(jobData?.compensation_amount)}
            </p>
          </div>
          <div className='anyjob-submitted-task-stats-item'>
            <p className='anyjob-submitted-task-stats-item-title'>
              Status
            </p>
            <p className='anyjob-submitted-task-stats-item-value'>
              {taskData?.status}
            </p>
          </div>
          {
            (!isEditing && (taskData?.status === "Ongoing" || taskData?.status === "In Review")) && (
              <div className='anyjob-submitted-task-stats-item'>
                <button className='anyjob-submitted-task-stats-item-cta' onClick={() => setIsEditing(true)}>
                  Edit
                </button>
              </div>
            )
          }
          
        </div>

        <form onSubmit={handleSubmit}>
          <div className='anyjob-submit-task-form'>
            <div className='anyjob-submit-task-input-group'>
              <p  className='anyjob-submit-task-input-group-label'>
                {
                  isEditing ? "Update Submission" : "Submission Details"
                }
              </p>
              {Boolean(jobData?.submission_type_dynamic_form ) && (
                <>
                  {
                    jobData?.dynamic_form_fields.map((field: any, index: number) => {
                      if (field.type === 'textarea') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            <textarea 
                              name={field.name}
                              // placeholder={field.label}
                              value={dynamicInputValues[field.name] || ''}
                              onChange={handleDyanmicInputChange}
                              required
                              className='anyjob-submit-task-dynamic-input-textare'
                              disabled={!isEditing}
                            /> 
                          </span>
                        )
                      }  else if (field.type === 'radio') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            {
                              field?.options.map((item: string, index: number) => {
                                return (
                                  <span className='anyjob-submit-task-dynamic-input-radio-span'>
                                    <input 
                                      onChange={handleDyanmicInputChange}
                                      name={field.name}
                                      value={item}
                                      checked={dynamicInputValues[field.name] === item}
                                      // placeholder={field.label}
                                      type={field.type}                            
                                      required
                                      className='anyjob-submit-task-dynamic-input-radio'
                                      disabled={!isEditing}
                                    />
                                    <p>
                                    {item}
                                    </p>
                                  </span>
                                )
                              })
                            }
                          </span>
                        )
                      } else if (field.type === 'select') {
                        return (
                          <span 
                            className='anyjob-submit-task-dynamic-input-group'
                            key={index}
                          >
                            <p
                              className='anyjob-submit-task-dynamic-input-label'
                            >
                              {field.label}
                            </p>
                            <select 
                              name={field.name}
                              // placeholder={field.label}
                              value={dynamicInputValues[field.name] || ''}
                              onChange={handleDyanmicInputChange}
                              required
                              className='anyjob-submit-task-dynamic-input-text'
                              disabled={!isEditing}
                            > 
                              <option value="" disabled>-- Select Option -- </option>
                              {
                                field?.options.map((item: string, index: number) => {
                                  return (
                                    <option value={item} key={index}>{item}</option>
                                  )
                                })
                              }
                            </select> 
                          </span>
                        )
                      }
                      return (
                        <span 
                          className='anyjob-submit-task-dynamic-input-group'
                          key={index}
                        >
                          <p
                            className='anyjob-submit-task-dynamic-input-label'
                          >
                            {field.label}
                          </p>
                          <input 
                            onChange={handleDyanmicInputChange}
                            name={field.name}
                            value={dynamicInputValues[field.name] || ''}
                            // placeholder={field.label}
                            type={field.type}                            
                            required
                            className='anyjob-submit-task-dynamic-input-text'
                            disabled={!isEditing}
                          />
                        </span>
                      )
                    })
                  }
                </>
              )}
              {
                Boolean(jobData?.submission_type_geo_location) && (
                  <>
                    <p className='anyjob-submit-task-note'>
                      This job requires you to be at the specified location in the job description/instructions when making this submission, hence your current location needs to be captured and submitted for review. You'd be asked to grant Anyjob access to view and capture your location, ensure to give the required permissions.
                    </p>
                    <p className='anyjob-submit-task-note'>
                      Note: Any submission made at a different location would be disregarded
                    </p>
                    {coords.latitude && coords.longitude ? (
                      <div>
                        <div className='anyjob-submit-task-map-container-box'>
                          <MapContainer 
                            center={[coords.latitude, coords.longitude]}
                            zoom={15} 
                            style={{ height: 185 }}
                            scrollWheelZoom={false}
                            className='anyjob-submit-task-map-container'
                          >
                            <TileLayer
                              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[coords.latitude, coords.longitude]} icon={customMarkerIcon}>
                              <Popup>
                                You are here!
                              </Popup>
                            </Marker>
                          </MapContainer>
                        </div>

                        {
                          isEditing && (
                            <button 
                              className='anyjob-submit-task-btn2' 
                              onClick={getUserLocation}
                            >
                            {isCapturingCoords ? "Capturing..." :  "Re-Capture Location"}
                          </button>
                          )
                        }
                        
                      </div>
                    ) : (
                      <button className='anyjob-submit-task-btn2' onClick={getUserLocation}>
                        {isCapturingCoords ? "Capturing..." :  "Capture Location"}
                      </button>
                    )}
                  </>
                )
              }
              {
                Boolean(jobData?.submission_type_attachments) && (
                  <>
                    <AttachementButton 
                      ref={attachmentButtonRef}
                      attachment_type={jobData?.attachment_type}
                      max_no_of_attachments={jobData?.max_no_of_attachments}
                      showCamera={cameraComponentRef.current.show}
                      attachments={taskData?.attachments_url || []}
                      isEditing={isEditing}
                    />
                    <p className='anyjob-submit-task-file-upload-note'>
                      The Maximum number of attachments is {jobData.max_no_of_attachments}{jobData?.attachment_type !== 'any' && `, and supported formats are ${getAllowedFileType()}`}
                    </p>
                  </>
                )
              }
            </div>
            {
              isEditing && (
                <span className='anyjob-submit-task-submit-btn-container'>
                  <input 
                    className='anyjob-submit-task-submit-btn'
                    type='submit'
                  />
                </span>
              )
            }
            
          </div>
        </form>

        <JobWidget 
          job={jobData}
        />

        {/* Comment section */}

        <div className='anyjob-task-details-comments-container'>
          <p  className='anyjob-submit-task-input-group-label'>
            Comments
          </p>

          <div className='anyjob-task-details-comments-blocks'>
            {
              comments.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className='anyjob-task-details-comment'
                  >
                    <div
                      className='anyjob-task-details-comment-avatar'
                    >
                      <p>
                        {
                          item.commenter.user.is_client ? item.commenter.business_name[0] : item.commenter.user.first_name[0]
                        }
                      </p>
                    </div>
                    <div className='anyjob-task-details-comment-content'>
                      <div className='anyjob-task-details-comment-meta-data'>
                        <p className='anyjob-task-details-comment-meta-data-name'>
                        {
                          item.commenter.user.is_client ? item.commenter.business_name : (`${item.commenter.user.first_name} ${item.commenter.user.last_name}`)
                        }
                        </p>
                        <p className='anyjob-task-details-comment-meta-data-date'>
                          {moment(item.commented_on).format("hh:mm a")}
                        </p>
                      </div>
                      <p className='anyjob-task-details-comment-message'>
                        {item.comment}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          
          {
            taskData?.status != 'Completed' && (
              <form className='anyjob-task-details-comment-form' onSubmit={handleSendComment}>
                <textarea 
                  name={"comment"}
                  placeholder={"Send a comment"}
                  value={comment}
                  onChange={handleCommentChange}
                  required
                  className='anyjob-submit-task-dynamic-input-textare'
                />
                <span className='anyjob-submit-task-submit-btn-container'>
                  <input 
                    className='anyjob-submit-task-submit-btn'
                    type='submit'
                    value='Send'
                  />
                </span>
              </form>
            )
          }
         
        </div>
          
        </div>
      </div>
      <LoadingModal ref={loadingModalRef} />
      <CameraComponent ref={cameraComponentRef} />
    </div>
  )
}