import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import './MessagePage.css'

import MessageContainer from '../../components/MessageContainer/MessageContainer'
import Header from '../../components/Header/Header'
import MessagePageNavPanel from './MessagePageNavPanel'
import { get_all_ongoing_chats } from '../../api/chats'
import LoadingModal from '../../components/LoadingModal'
import { DefaultStateType } from '../../redux/types'
import ChatIllustration from '../../assets/svg/chat-illustration.svg'


export default function MessagePage(props: any) {
  
  const [activeChatMetaData, setActiveChatMetaData] = useState<any>(null)



  const userData = useSelector((state: DefaultStateType) => state.userData);
  const loadingModalRef = useRef<any>()

  const handleOnSelectChat = (data: any) => {
    setActiveChatMetaData(data)
  }
 

  return (
    <div className='anyjob-message-page-container'>
      <Header title={"Message"} />
      <div className='anyjob-message-page-content-container'>
        <MessagePageNavPanel 
          onChange={handleOnSelectChat}
        />
        {
          !Boolean(activeChatMetaData) ? (
            <div className='anyjob-blank-message-container'>
              <img
                src={ChatIllustration}
              />
              <p>
                Meesages you have with other users would be displayed here!
              </p>
            </div>
          ) : (
            <MessageContainer activeChatMetaData={activeChatMetaData} loadingModalRef={loadingModalRef} />
          ) 
        }
        
      </div>
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}