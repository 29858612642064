import numeral from 'numeral';

export function hasLowerCase(str) {
  return (/[a-z]/.test(str));
}
export function hasUpperCase(str) {
  return (/[A-Z]/.test(str));
}
export function containsNumber(str) {
  return /\d/.test(str);
}

export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

export function formatCurrency(amount) {
  // grab current state
  return `${numeral(amount).format('0,0.00')}`
  // return `${currency_symbol}${numeral(amount).format(NUMBERDISPLAYFORMAT)}`
}

export function isValidUrl(url) {
  // Regular expression for a simple URL validation
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  // Test the string against the regular expression
  return urlPattern.test(url);
}