import React, { useEffect, useRef, useState }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "phosphor-react";

import './Onboarding.css'
import { DefaultStateType } from "../../redux/types";
import { update_personal_info } from "../../api/auth";
import LoadingModal from '../../components/LoadingModal'
import { toast } from "react-toastify";
import getErrorMessage from "../../utils/getErrorMessage";
import { logIn } from "../../redux/userData";
import { PersonalInfoProps } from "./types";
import { setActiveWallet } from "../../redux/wallet";


export default function PersonalInfo(props: PersonalInfoProps) {
  const [userProfilePicture, setUserProfilePicture] = useState<any>(null)
  const [userProfilePictureBase64Str, setUserProfilePictureBase64Str] = useState<any>(null)
  const [userProfilePictureFile, setUserProfilePictureFile] = useState<any>(null)
  const [inputValues, setInputValues] = useState({
    shortBio: '',
    longBio: ''
  })
  const [errMsg, setErrMsg] = useState({
    shortBio: '',
    longBio: '',
    general: '',
    userProfilePicture: '',
  })

  const userData = useSelector((state: DefaultStateType) => state.userData);

  const dispatch = useDispatch()

  const fileInputRef = useRef<any>(null);
  const loadingModalRef = useRef<any>()

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    setUserProfilePictureFile(file)

    console.log('file', file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setUserProfilePicture(reader.result);
        // @ts-ignore
        const base64String = reader.result.split(',')[1];
        // console.log('reader.result', reader.result);
        // console.log('base64String', base64String);
        
        setUserProfilePictureBase64Str(base64String)
      };

      reader.readAsDataURL(file);

      // console.log(' reader.readAsDataURL(file);',  reader.readAsDataURL(file););
      
    }
    
    
    // Perform additional checks here if needed

    // Now you can upload the selected image using Axios or any other method
    // if (file) {
    //   const formData = new FormData();
    //   formData.append('image', file);
    // }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });

  }

  const validateForm = () => {
    var isValid = true
    if (!Boolean(userProfilePictureFile)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        userProfilePicture: "Please provide an image of yourself to be used as your profile picture"
      }))
    }
    if (!Boolean(inputValues.longBio.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        longBio: "Please provide a bit more details about your work experience, cool projects you've completed, and your area of expertise."
      }))
    }
    if (!Boolean(inputValues.shortBio.length)) {
      isValid = false
      setErrMsg((prevState: any) => ({
        ...prevState,
        shortBio: "Please share a short description about yourself"
      }))
    }

    return isValid
  }

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      loadingModalRef.current.showLoadingModal()
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        return 
      }

      var params = {
        long_bio: inputValues.longBio,
        short_bio: inputValues.shortBio,
        profile_picture: {
          base64: userProfilePicture,
          fileName: userProfilePictureFile.name,
          size: userProfilePictureFile.size,
          type: userProfilePictureFile.type,
        },
      }

      console.log('params', params);

      const res = await update_personal_info(params)
      const response = await res.json()
      if (res.ok) {
        loadingModalRef.current?. hideLoadingModal()

        dispatch(logIn({
          user_details: response.data.user_data?.user_details,
          user_profile: response.data?.user_data?.user_profile || {},
        }))    
        dispatch(setActiveWallet(response.data.user_data.wallet))     
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.increaseTabProgress()
        props.goToNext("account-verification")
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      console.log('getErrorMessage(error)', getErrorMessage(error));
      
      loadingModalRef.current?. hideLoadingModal()
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  return (
    <div className="anyjob-onboarding-page-tab-content">
      <div className="anyjob-onboarding-page-tab-content-headings">
        <p className="anyjob-onboarding-page-tab-content-headings-title">
          Hello {userData.user_details?.first_name}, Let’s help you stand out better
        </p>
        <p className="anyjob-onboarding-page-tab-content-headings-desc">
          Kindly fill out your profile for potential client to know you, what you do best and understand your services
        </p>
      </div>

      <div 
        className="anyjob-onboarding-page-upload-profile"
        onDrop={handleButtonClick}
        onDragOver={(e) => e.preventDefault()}
      >
        {
          Boolean(userProfilePicture) ? (
            <div
              onClick={handleButtonClick}
            >
              <img
                src={userProfilePicture}
                alt="Selected"
                className="anyjob-onboarding-page-profile-picture"
              />
            </div>
          ) : (
            <div 
              className="anyjob-onboarding-page-profile-icon-container"
              onClick={handleButtonClick}
            >
              <User 
                className="anyjob-onboarding-page-profile-icon"
              />
            </div>
          )
        }
        <div          
        >
          <button 
            onClick={handleButtonClick}
            className="anyjob-onboarding-page-upload-profile-picture-btn"
          >Upload Profile Image</button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            multiple={false}
          />
        </div>
      </div>
      <div className="anyjob-onboarding-page-err-container">
        {
          errMsg.userProfilePicture && (
            <p className="anyjob-onboarding-page-input-err-msg">
              {errMsg.userProfilePicture}
            </p>
          )
        }
      </div>
      
    
      <form className="anyjob-onboarding-page-personal-info-form" onSubmit={handleSubmit}> 

        <div className="anyjob-onboarding-page-input-group">
          <p className="anyjob-onboarding-page-input-group-title">
            What do you do?
          </p>
          <p className="anyjob-onboarding-page-input-group-desc">
            Write a one line description about yourself.
          </p>
          <input 
            onChange={handleInputChange} 
            type="text"
            value={inputValues.shortBio}
            required
            name="shortBio"
            className="anyjob-onboarding-page-input-group-input-field"
            placeholder="e.g. Product Designer"
          />
          {
            errMsg.shortBio && (
              <p className="anyjob-onboarding-page-input-err-msg">
                {errMsg.shortBio}
              </p>
            )
          }
        </div>

        <div className="anyjob-onboarding-page-input-group">
          <p className="anyjob-onboarding-page-input-group-title">
            Describe yourself
          </p>
          <p className="anyjob-onboarding-page-input-group-desc">
            Don't hold back, let's know how great you are
          </p>
          <textarea 
            onChange={handleInputChange} 
            value={inputValues.longBio}
            required
            name="longBio"
            className="anyjob-onboarding-page-input-group-input-textarea"
            placeholder="Share a bit about your work experience, cool projects you've completed, and your area of expertise."
          />
          {
            errMsg.longBio && (
              <p className="anyjob-onboarding-page-input-err-msg">
                {errMsg.longBio}
              </p>
            )
          }
        </div>

        <input 
          type="submit"
          value="Continue"
          className="anyjob-onboarding-page-form-submit-btn"
        />
      </form>
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}